import {
    GET_FORGOT_PASSWORD_REQ,
    GET_FORGOT_PASSWORD_SUCCESS,
    GET_FORGOT_PASSWORD_ERROR,
    RESET_TO_INITIAL_STATE
} from "../../Constants/ForgotPassword";
 
const initialState = {
    forgotPasswordLoading: false,
    forgotPasswordResponse: {} 
}

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_FORGOT_PASSWORD_REQ:
            return {
                ...state,
                forgotPasswordLoading: true,
                forgotPasswordResponse: {}
            }
        case GET_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordResponse: action.payload
            }
        case GET_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordResponse: action.payload
            }
        case RESET_TO_INITIAL_STATE:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordResponse: {}
            } 
        default:
            return state;
    }
}

export default forgotPasswordReducer;
import {
    VERIFY_PAYMENT_REQ,
    VERIFY_PAYMENT_SUCCESS,
    VERIFY_PAYMENT_ERROR
} from "../../Constants/Result";
import axios from "axios";
import * as apis from "../../apis/common";

import { getCartByUserReq } from '../Cart/actions';

export {
    verifyPayment
}

function verifyPayment(orderID, addressId, token) {
    return async (dispatch) => {
        try {
            dispatch({ type: VERIFY_PAYMENT_REQ });
            const result = await axios.post(apis.GRAPHQL_API, {
                query: `mutation {
                    createUserOrder(order_id: "${orderID}", shipping_address_id: ${addressId}){
                        success
                        statusCode
                        message
                        data{
                          delivery_charges
                          products{
                           id
                            user_id
                            order_id
                            product_id
                            quantity
                            product_name
                            price
                            created_at
                            updated_at
                            total_price
                            vat 
                          }
                        }
                            allRecord
                        }
                    }`
            },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }
            )
            if (result?.data?.data?.createUserOrder?.success === "1") {
                dispatch({ type: VERIFY_PAYMENT_SUCCESS, payload: result?.data?.data?.createUserOrder })
                dispatch(getCartByUserReq(token));
            } else {
                dispatch({ type: VERIFY_PAYMENT_ERROR, payload: result?.data?.data?.createUserOrder });
            }

        } catch (e) {
            dispatch({ type: VERIFY_PAYMENT_ERROR, payload: e });
        }
    }
}
export const GET_CATEGORY_BY_ID_REQ = "GET_CATEGORY_BY_ID_REQ";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
export const GET_CATEGORY_BY_ID_ERROR = "GET_CATEGORY_BY_ID_ERROR";

export const GET_ALL_CATEGORIES_REQ = "GET_ALL_CATEGORIES_REQ";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const RESET_CATEGORY_SEARCH = "RESET_CATEGORY_SEARCH";
export const RESET_CATEGORY_SEARCH_SUCCESS = "RESET_CATEGORY_SEARCH_SUCCESS";
export const RESET_CATEGORY_SEARCH_ERROR = "RESET_CATEGORY_SEARCH_ERROR";

export const SET_CURRENT_CATEGORY_REQ = "SET_CURRENT_CATEGORY_REQ";
export const SET_CURRENT_CATEGORY_SUCCESS = "SET_CURRENT_CATEGORY_SUCCESS";
export const SET_CURRENT_CATEGORY_ERROR = "SET_CURRENT_CATEGORY_ERROR";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./AllProduct.css"

import CategorySlider from '../../components/CategorySlider';

// Action
import { categorySliderReq } from "../../Actions/Products/actions"


const AllProducts = () => {
    const products = useSelector(state => state.products);
  
    // react-redux
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(categorySliderReq())
    }, [])

    return (
        <div className='sc-background-dark'>
            <div className="container categorySliderContainer">
                <CategorySlider products={products.allProdsData} loading={products.allProdsLoading} />
            </div>
        </div>
    )
};

export default AllProducts;

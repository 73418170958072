import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Row,
  Col,
  Breadcrumb,
  Typography,
  Checkbox,
  Divider,
  Menu,
  Dropdown,
  Spin,
  BackTop,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./productList.css";

// actions
import {
  getAllProductsReq,
  fetchAllFeaturedProducts,
} from "../../Actions/Products/actions";
// import { getCategoryByIdReq } from "../../Actions/Category/actions";

//components
//import Loading from "../../components/Loading";
import ProductListing from "../../components/ProductListing";
import EmptyList from "../../components/EmptyList";
import DummyProductListBlock from "../../components/DummyProductListBlock";
import { getCategoryByIdReq } from "../../Actions/Category/actions";
import {
  ArrowReturnRight,
  Back,
  Backspace,
  BackspaceReverse,
  X,
  XLg,
} from "react-bootstrap-icons";
import { BsArrow90DegUp, BsArrowReturnRight } from "react-icons/bs";
import { Backdrop } from "@material-ui/core";
const { Title, Paragraph } = Typography;
const { SubMenu } = Menu;

const ProductList = () => {
  // states
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [breadCrumCategory, setBreadCrumCategory] = useState("");
  const [breadCrumCategoryOne, setBreadCrumCategoryOne] = useState("");
  const [breadCrumCategoryTwo, setBreadCrumCategoryTwo] = useState("");
  const [breadcrumId, setBreadcrumId] = useState("");

  // redux-store
  const productsState = useSelector((state) => state.products);
  const { allProducts, isLoading, featuredProducts, featuredProductsLoading } =
    productsState;

  const categoryState = useSelector((state) => state.category);

  const { allCategories, category: currentCategory } = categoryState;

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const categoryIdParam = queryParams.get("category_id");
  const searchParam = queryParams.get("search");
  const pageParam = queryParams.get("page");
  const filtersParam = queryParams.get("filters");

  // useEffect(() => {
  //   dispatch(getCategoryByIdReq(categoryIdParam));
  // }, [dispatch])

  useEffect(() => {
    dispatch(getCategoryByIdReq(categoryIdParam));
  }, [categoryIdParam]);

  useEffect(() => {
    if (categoryIdParam === "featured") {
      dispatch(fetchAllFeaturedProducts);
      return;
    } else {
      dispatch(
        getAllProductsReq(
          searchParam,
          pageParam,
          categoryIdParam,
          filtersParam?.split("|")
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllProductsReq(
        searchParam,
        pageParam,
        categoryIdParam,
        filtersParam?.split("|")
      )
    );
  }, [categoryIdParam, searchParam, pageParam, filtersParam]);

  const handleFilters = (e, filterData) => {
    let filters = [...selectedFilters];
    if (e.target.checked) {
      filters = [...filters, ...filterData.category_filter_id];
    } else {
      let oldAppliedFilters = filtersParam.split("|");

      for (let i = 0; i < filterData.category_filter_id.length; i++) {
        if (
          oldAppliedFilters.includes(
            filterData.category_filter_id[i].toString()
          )
        ) {
          var index = filters.indexOf(filterData.category_filter_id[i]);
          filters.splice(index, 1);
        }
      }
    }

    setSelectedFilters(filters);
    history.push(
      `/product-list?category_id=${categoryIdParam}&page=1&search=${searchParam}&filters=${filters.join(
        "|"
      )}`
    );
  };

  useEffect(() => {
    categoryState?.allCategories?.map((item) => {
      item.child &&
        item.child.map((one) => {
          if (one?.name == currentCategory.name) {
            setBreadCrumCategory(item.name);
            setBreadcrumId(item.id);
          }
          one?.child?.map((two) => {
            if (two?.name == currentCategory.name) {
              setBreadCrumCategory(item?.name);
              setBreadCrumCategoryOne(one?.name);
            }
            two?.child?.map((three) => {
              if (three?.name == currentCategory.name) {
                setBreadCrumCategory(item?.name);
                setBreadCrumCategoryOne(one?.name);
                setBreadCrumCategoryTwo(two?.name);
              }
            });
          });
        });
    });
  }, [categoryState, currentCategory]);

  useEffect(() => {
    setBreadCrumCategory("");
    setBreadcrumId("");
  }, [categoryIdParam]);

  const checkFilterVisibility = (fParam, fData) => {
    if (fParam) {
      const splittedURLFilters = fParam.split("|");
      let result = true;
      (fData.category_filter_id || []).map((filtersKnown) => {
        if (!splittedURLFilters.includes(filtersKnown.toString())) {
          result = false;
        }
      });
      return result;
    }
  };

  const mobileViewFilters = () => {
    return (
      <Menu>
        {allProducts.data?.filters &&
          allProducts.data?.filters.map((filterDetails, index) => {
            return (
              <SubMenu title={filterDetails.filter_name} key={index}>
                {(filterDetails?.filter_data || []).map((filterData, i) => {
                  return (
                    <Menu.Item key={i}>
                      <Checkbox
                        onChange={(e) => handleFilters(e, filterData)}
                        checked={checkFilterVisibility(
                          filtersParam,
                          filterData
                        )}
                      >
                        {filterData.property_value}
                        <span>{filterData.unit ? filterData.unit : ""}</span>
                      </Checkbox>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
      </Menu>
    );
  };

  return (
    <div>
      <section className="pageContent">
        <div className="container productListContainer">
          <div className="pageContent">
            <Row style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <div className="pageHeader">
                <Col span={12} xs={24}>
                  <div className="breadCrumbList">
                    <Breadcrumb separator=">">
                      <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                      </Breadcrumb.Item>
                      {}

                      {categoryIdParam === "featured" ? (
                        <Breadcrumb.Item>Featured Products</Breadcrumb.Item>
                      ) : currentCategory?.name == breadCrumCategory ? (
                        <Breadcrumb.Item>
                          <span style={{ color: "#000" }}>
                            {currentCategory.name}
                          </span>
                        </Breadcrumb.Item>
                      ) : breadCrumCategory ? (
                        <Breadcrumb.Item>
                          <span
                            style={{ color: "#000" }}
                            //  onClick={()=>{
                            //   setBreadCrumDropDown(allCategories)
                            //   // console.log(allCategories)
                            //  }}
                          >
                            {breadCrumCategory}
                          </span>
                        </Breadcrumb.Item>
                      ) : (
                        ""
                      )}
                      {breadCrumCategoryOne ? (
                        <Breadcrumb.Item>
                          <span
                            style={{ color: "#000" }}
                            // onClick={()=>{
                            //  let data =  allCategories.find((item)=>{
                            //       return item.name === breadCrumCategory
                            //  })
                            //  setBreadCrumDropDown(data.child)
                            // }}
                          >
                            {breadCrumCategoryOne}
                          </span>
                        </Breadcrumb.Item>
                      ) : (
                        ""
                      )}
                      {breadCrumCategoryTwo ? (
                        <Breadcrumb.Item>
                          <span
                            style={{ color: "#000" }}
                            // onClick={()=>{
                            //   let categorydata = allCategories.find((item)=>{
                            //        return item.name === breadCrumCategory
                            //   })
                            //   let data = categorydata?.child && categorydata.child.find((item)=>{
                            //     return item.name === breadCrumCategoryOne
                            //   })
                            //   setBreadCrumDropDown(data.child)
                            //   // console.log(data)
                            // }}
                          >
                            {breadCrumCategoryTwo}
                          </span>
                        </Breadcrumb.Item>
                      ) : (
                        ""
                      )}
                      {currentCategory?.name != breadCrumCategory ? (
                        <Breadcrumb.Item>
                          <Link
                            to={`/product-list?category_id=${currentCategory.id}&page=1&search=&filters=`}
                          >
                            {currentCategory.name}
                          </Link>
                        </Breadcrumb.Item>
                      ) : (
                        ""
                      )}
                    </Breadcrumb>
                  </div>
                </Col>

                <Col span={12} xs={24} className="filterMobileView">
                  <Row justify="end">
                    {isLoading ? (
                      <Spin />
                    ) : allProducts?.data?.filters?.length > 0 ? (
                      <Dropdown overlay={mobileViewFilters} trigger={["click"]}>
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Filters <DownOutlined />
                        </a>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </div>
            </Row>

            <Row align="top" justify="space-around">
              <Col lg={4} md={0} xs={0} className="pt-15">
                {currentCategory?.name != "All" && (
                  <>
                    <Title level={4}>{currentCategory?.name}</Title>
                    <Paragraph
                      strong
                      ellipsis={{
                        rows: 5,
                        expandable: false,
                      }}
                    >
                      {currentCategory?.description}
                    </Paragraph>
                  </>
                )}

                {allProducts?.data?.filters?.length &&
                  allProducts.data?.filters.map((filterDetails, index) => {
                    return (
                      <div key={index}>
                        <Title level={5}>
                          {filterDetails?.filter_data[0]?.property_value?.toLowerCase() ===
                          "na"
                            ? ""
                            : filterDetails.filter_name}
                        </Title>
                        {filterDetails?.filter_data[0]?.property_value?.toLowerCase() ===
                        "na" ? (
                          ""
                        ) : (
                          <Divider />
                        )}
                        {(filterDetails?.filter_data || []).map(
                          (filterData, ind) => {
                            return (
                              <>
                                {filterData.property_value.toLowerCase() ===
                                "na" ? (
                                  ""
                                ) : (
                                  <Row key={ind}>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleFilters(e, filterData)
                                      }
                                      checked={checkFilterVisibility(
                                        filtersParam,
                                        filterData
                                      )}
                                    >
                                      {filterData.property_value}
                                      <span>
                                        {filterData.unit ? filterData.unit : ""}
                                      </span>
                                    </Checkbox>
                                  </Row>
                                )}
                              </>
                            );
                          }
                        )}
                        <br />
                      </div>
                    );
                  })}
              </Col>
              <Col lg={18} md={24} xs={24} style={{ zIndex: 0 }}>
                {isLoading ? (
                  [...Array(20)].map((d, index) => {
                    return (
                      <DummyProductListBlock indexKey={index} key={index} />
                    );
                  })
                ) : categoryIdParam !== "featured" &&
                  allProducts?.data?.products ? (
                  <ProductListing products={allProducts.data.products} />
                ) : categoryIdParam === "featured" ? (
                  !featuredProductsLoading && (
                    <ProductListing products={featuredProducts} />
                  )
                ) : (
                  <EmptyList text="This Category has no results yet. Please try after sometime" />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductList;

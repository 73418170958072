import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Row, Col, Typography } from 'antd';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './categorySlider.css';

import { getCategoryByIdReq } from "../../Actions/Category/actions";

import Card from '../Card';

const { Title } = Typography;

const CategorySlider = ({ products, loading }) => {
    const dispatch = useDispatch();
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
                },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const getCategory = (categs) => {
        dispatch(getCategoryByIdReq(categs.categoryId))
    }

    return (
        <Row gutter={[20, 20]} justify='space-around'>
            {
                loading ? (
                    <div className="card" style={{ width: "100%" }} >
                        {
                            [...Array(5)].map((dd, index) => {
                                return (
                                    <Col key={index}>
                                        <Row align='middle'>
                                            <Col>
                                                <Title level={3} style={{ padding: "10px", margin: "0 !important" }}>Loading...</Title>
                                            </Col>
                                        </Row>
                                        <Carousel
                                            responsive={responsive}
                                            infinite={true}
                                            autoPlay={true}
                                        >
                                            {[...Array(6)].map((card, i) => {
                                                return (
                                                    <Card isMoving={true} {...card} key={i} loading={true} />
                                                );
                                            })}
                                        </Carousel>
                                    </Col>
                                )
                            })
                        }
                    </div>
                ) : (
                    (products || []).map(categs => {
                        return (
                            <div className="card" style={{ width: "100%" }} key={categs.categoryId}>
                                <Col>
                                    <Row align='middle'>
                                        <Col>
                                            <Title level={3} className='allCategTitle'>{categs.cateGoryName}</Title>
                                        </Col>
                                        <Col>
                                            <Link
                                                to={`product-list?category_id=${categs.categoryId}&page=1&search=&filters=`}
                                                className='see-all'
                                                onClick={() => getCategory(categs)}
                                            >
                                                See All
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Carousel
                                        responsive={responsive}
                                        infinite={true}
                                        autoPlay={true}
                                    >
                                        {categs.products.map((card, i) => {
                                            return (
                                                <Card isMoving={true} {...card} key={i} loading={false} />
                                            );
                                        })}
                                    </Carousel>
                                </Col>
                            </div>
                        )
                    })
                )
            }
        </Row>
    );
};

CategorySlider.propTypes = {
    products: PropTypes.array,
    loading: PropTypes.bool
};

export default CategorySlider;

import React from 'react'
import "./shipping.css"
import { Tag, Typography, Row, Divider, Card, Col, Table } from 'antd';

const { Title, Text } = Typography;

import { convertToPriceVal } from '../../utils';

const Shipping = () => {
    const tableData = [
        {
            key: '1',
            charges: 6.95,
            region: ['UK Mainland'],
            details: { title: 'Standard Delivery', desc: 'Get your items delivered within 2-3 business days', logo: 'fas fa-shuttle-van' }
        },
        {
            key: '2',
            charges: 7.95,
            region: ['UK Mainland'],
            details: { title: 'Next Day Delivery', desc: 'Get your items delivered to your next business days', logo: 'far fa-calendar-alt' }
        },
        {
            key: '3',
            charges: 10.50,
            region: ['UK Mainland'],
            details: { title: 'Next Day Delivery Before 12:00', desc: 'Get your items delivered by next day before 12:00', logo: 'far fa-calendar-alt' }
        },
        {
            key: '4',
            charges: 15.00,
            region: ['UK Mainland'],
            details: { title: 'Next Day Delivery Before 10:30', desc: 'Get your items delivered by next day before 10:30', logo: 'far fa-calendar-alt' }
        },
        {
            key: '5',
            charges: 20.00,
            region: ['UK Mainland'],
            details: { title: 'Saturday Delivery', desc: 'Get your items delivered on saturday', logo: 'fas fa-map-marker-alt' }
        },
        {
            key: '6',
            charges: 24.50,
            region: ['UK Mainland'],
            details: { title: 'Saturday Delivery Before 12:00', desc: 'Get your items delivered on saturday before 12:00', logo: 'far fa-calendar' }
        },
        {
            key: '7',
            charges: 32,
            region: ['UK Mainland'],
            details: { title: 'Saturday Delivery Before 10:30', desc: 'Get your items delivered on saturday before 10:30', logo: 'far fa-calendar' }
        },
        {
            key: '8',
            charges: 16,
            region: ['UK Mainland'],
            details: { title: 'Sunday Delivery', desc: 'Get your items delivered on sunday', logo: 'far fa-calendar' }
        },
        {
            key: '9',
            charges: 20,
            region: ['UK Mainland'],
            details: { title: 'Sunday Delivery before 12:00', desc: 'Get your items delivered on sunday before 12:00', logo: 'far fa-calendar' }
        },
        {
            key: '10',
            charges: 50,
            region: ['SCOTTISH HIGHLANDS', 'ISLANDS'],
            details: { title: 'Standard Delivery', desc: 'Get your items delivered within 2-5 business days', logo: 'fas fa-shuttle-van' }
        },
        {
            key: '11',
            charges: 24,
            region: ['CHANNEL ISLES', 'ISLE OF MAN'],
            details: { title: 'Standard Delivery', desc: 'Get your items delivered within 2-5 business days', logo: 'fas fa-shuttle-van' }
        }
    ];

    const tableColumns = [
        {
            title: 'Delivery Details',
            dataIndex: 'details',
            key: 'details',
            render: (details) => <>
                <Row justify='start' gutter={[16, 16]} align='middle'>
                    <Col xs={{ span: 2 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 2 }} xxl={{ span: 2 }}>
                        <i className={`${details.logo} fa-2x`}></i>
                    </Col>
                    <Col>
                        <Row justify="start">
                            <h4><b>{details.title}</b></h4>
                        </Row>
                        <Row justify="start">
                            <span>{details.desc}</span>
                        </Row>
                        {
                            details?.subDesc && (
                                <Row justify="start">
                                    <span><b>{details.subDesc.toUpperCase()}</b></span>
                                </Row>
                            )
                        }
                    </Col>
                </Row>
            </>,
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            render: (region) => {
                return (region || []).map((reg, index) => {
                    return <Tag key={index} color={region.length > 1 ? "#f50" : "#87d068"}>{reg}</Tag>
                })
            }
        },
        {
            title: 'Charges',
            dataIndex: 'charges',
            key: 'charges',
            render: (charges) => <span><b>&#163;{convertToPriceVal(charges)}</b></span>
        }
    ];
    return (
        <div className="container">
            <Card className="shippingContainer">
                <Row justify="space-around">
                    <Col span={24}>
                        <Title level={3} className="para-title">DELIVERY INFORMATION</Title>
                        <Divider />
                        <Text className="pageInfo">We offer a multitude of different shipping options globally, See belw delivery column to find out
                            more on costs &amp; transit times.</Text><br /><br />
                        <Text className="pageInfo">All UK and Off Shore deliveries are made Monday to Friday between 9:00 to 17:00, this excludes
                            Bank Holidays, weekends and where a next day or timed option has been selected. We do not deliver on weekends unless the Saturday or Sunday delivery option has been selected.</Text><br /><br />
                        <Text className="pageInfo">A signature is required upon delivery, and most deliveries will be attempted twice, unless there is
                            an address problem. If you are not available to accept delivery the courier will leave a calling
                            card and/or send a notification via SMS or email to advise that delivery has been attempted
                            (please follow the instructions on the calling card, SMS or email). Please note that the courier
                            may also leave your parcel with a neighbour. If the parcel is not successfully delivered to you
                            within 2 weeks the parcel will be returned to us.</Text><br /><br />
                        <Text className="pageInfo">You will be able to track your missed delivery by logging on to shipping company’s
                            website (depending on the courier you have selected) and entering in your tracking reference
                            number, which would have been provided to you on the dispatch notification email/SMS or card
                            left by the courier. If your parcel is returned to us there may be additional postage charges
                            applied for further re-deliveries.</Text><br /><br />
                        <Text className="pageInfo">During busy periods deliveries may take longer. If you have not received your order and more
                            than 5 working days have passed since ordering, please contact our Customer Services Team,
                            or email us at <a href="mailto:info@eagleworksolutions.com">info@eagleworksolutions.com</a></Text><br /><br />
                        <Text className="pageInfo">Please Note : Delivery depends on whether we have product in stock or not. We aim to dispatch
                            stock items within 48 hours. Dispatch time may vary on items not in stock. Please contact us for
                            more information</Text><br /><br />
                    </Col>
                </Row>
                <Divider />
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">DELIVERY OPTIONS</Title>
                        <Text className="pageInfo">Timed delivery options are available on orders placed before 5pm Monday to Thursday. Orders
                            placed on Friday before the 3pm deadline will be delivered on Monday (excluding bank holidays).
                            Anything received after the Friday deadline will be delivered on Tuesday.</Text><br /><br />
                        <Text className="pageInfo">Timed deliveries are only available to certain areas within the UK and can only also be made if all
                            the items ordered are in stock and the address/contact details provided are correct. All deliveries
                            are made from Monday to Friday (excluding bank holidays), unless the Saturday or Sunday
                            delivery option has been selected. Both Saturday and Sunday deliveries are not available to all
                            postcode areas within the UK.</Text><br /><br />
                        <Text className="pageInfo">Internationally, we also offer a range of standard and express delivery options.</Text><br /><br />
                    </Col>
                </Row>
                <Divider />
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">DUTY &amp; VAT</Title>
                        <Text className="pageInfo">All deliveries to the destinations outside the U.K are subject to import duties and taxes, which are
                            levied by the importing country at the time of delivery arrival in your country. All applicable duties,
                            fees and any additional charges for customs clearance will be the responsibility of the customer.</Text><br /><br />
                        <Text className="pageInfo">The price displayed for goods/services remains the same for all customers regardless of the
                            country of delivery.</Text><br /><br />
                    </Col>
                </Row>
                <Divider />
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">POSTAGE</Title>
                        <Text className="pageInfo">Once your order has been dispatched, you will receive various notifications from the courier
                            either by email or SMS, to keep you informed about your forthcoming delivery. If you do not hear
                            anything or still don’t have your order after 10 working days after having placed your order, then
                            please contact us at <a href="mailto:info@eagleworksolutions.com">info@eagleworksolutions.com</a></Text><br /><br />
                        <Text className="pageInfo">Postage promotions may be on offer via our website and call centre, with varying minimum
                            spend values. These offers are only applicable for mainland UK customers. Northern Ireland,
                            Scottish Highlands, Isle of Scilly, Isle of Man and all other Countries are excluded from this in
                            their entirety.</Text><br /><br />
                    </Col>
                </Row>
                <Divider />
                <Text type="danger" strong>We have below shipping services available. If order is placed before 2PM Uk times, Please contact us for expedited delievery charges.</Text>
                <Divider />
                <Table
                    scroll={{ x: 200 }}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={{ defaultPageSize: 11 }}
                />
            </Card>
        </div>
    )
}

export default Shipping
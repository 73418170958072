import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ItemsCarousel from 'react-items-carousel';
import { Button, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import ProductItemBlock from "../ProductItemBlock/ProductItemBlock";

const SliderCarousel = ({ products }) => {
   
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [numberOfCards, setNumberOfCards] = useState(4);
    const chevronWidth = 40;

    useEffect(() => {
        const currentWidth = window.innerWidth;
        if (currentWidth <= 480) {
            setNumberOfCards(1)
        } else if (currentWidth <= 768 && currentWidth > 480) {
            setNumberOfCards(2)
        } else if (currentWidth <= 992 && currentWidth > 768) {
            setNumberOfCards(3)
        } else if (currentWidth <= 1200 && currentWidth > 992) {
            setNumberOfCards(4)
        } else {
            setNumberOfCards(5)
        }
    })

    return (
        <div style={{ padding: `0 ${chevronWidth}px` }} >
            <div className='container' style={{ maxWidth: "90%" }}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={numberOfCards}
                    slidesToScroll={1}
                    gutter={10}
                    leftChevron={<Button
                        shape="circle"
                        icon={<LeftOutlined />}
                    />}
                    rightChevron={<Button
                        shape="circle"
                        icon={<RightOutlined />}
                    />}
                    outsideChevron
                    chevronWidth={chevronWidth}
                    infiniteLoop
                >
                    {(products || []).map((product, index) => {
                        return (
                            <ProductItemBlock
                                product={product}
                                key={index}
                            />
                        );
                    })}
                </ItemsCarousel>
            </div>
            <Row justify='space-around' align='middle'>
                <Col>
                    <Link to={`/product-list?category_id=featured&page=1&search=&filters=`}>
                        <Button
                            className='viewAllBtn'
                            type="primary"
                        >
                            View all
                        </Button>
                    </Link>
                </Col>
            </Row>
        </div>
    );
};

SliderCarousel.propTypes = {
    products: PropTypes.array
};

export default SliderCarousel;
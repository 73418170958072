import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Spin, Typography, Divider, notification } from "antd";

import './changePassword.css'

import { changePasswordReq, resetInitials } from '../../Actions/ChangePassword/actions';

const { Title } = Typography;

const ChangePassword = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();

    const userState = useSelector(state => state.user);
    const changePasswordState = useSelector(state => state.changePassword);
    useEffect(() => {
        if (!userState?.userDetails?.api_token) {
            history.push("/")
        }
    }, [])

    const openNotification = type => {
        notification[type]({
            message: type,
            description: changePasswordState?.changePasswordResp?.message,
        });
        form.resetFields()
        dispatch(resetInitials());
    };

    const handleSubmit = (values) => {
        if (userState?.userDetails?.api_token) {
            dispatch(changePasswordReq(values, userState?.userDetails?.api_token))
        }
    }

    return (
        <div className='changePasswordContainer container'>
            <Form
                layout='vertical'
                form={form}
                onFinish={handleSubmit}
            >
                <Title level={4}>Change password</Title>
                <Divider />
                <Spin spinning={changePasswordState.changePasswordLoading}>
                    <Form.Item
                        name="password"
                        label="Current password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your old password!',
                            },
                        ]}>
                        <Input.Password minLength={8} />
                    </Form.Item>
                    <Form.Item
                        name="new_password"
                        label="New password"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') !== value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('New password should not match with old password!'));
                                },
                            }),
                        ]}>
                        <Input.Password minLength={8} />
                    </Form.Item>
                    <Form.Item
                        name="confirm_password"
                        label="Re-enter new password"
                        dependencies={['new_password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your new password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}>
                        <Input.Password minLength={8} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType='submit'
                            type="primary"
                            className="btn continue-btn"
                        >
                            Save changes
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
            {
                (changePasswordState.changePasswordResp?.success === "1" ?
                    openNotification("success") :
                    changePasswordState.changePasswordResp?.success === "" ? openNotification("error")
                        : <></>)
            }
        </div>
    )
}

export default ChangePassword

import {
  ADD_ITEM_TO_CART_REQ,
  ADD_ITEM_TO_CART_SUCCESS,
  ADD_ITEM_TO_CART_ERROR,
  GET_CART_BY_USER_REQ,
  GET_CART_BY_USER_SUCCESS,
  GET_CART_BY_USER_ERROR,
  RESET_LOCAL_CART_ITEMS,
  UPDATE_LOCAL_CART_ITEMS_REQ,
  UPDATE_LOCAL_CART_ITEMS_SUCCESS,
  UPDATE_LOCAL_CART_ITEMS_ERROR,
  ADD_MULTIPLE_PRODUCTS_REQ,
  ADD_MULTIPLE_PRODUCTS_SUCCESS,
  ADD_MULTIPLE_PRODUCTS_ERROR,
  UPDATE_LOCAL_CART_ITEM_QUANTITY_REQ,
  UPDATE_LOCAL_CART_ITEM_QUANTITY_SUCCESS,
  UPDATE_USER_CART_BY_TOKEN_301_ERROR,
  UPDATE_USER_CART_BY_TOKEN_301_ERROR_RESTORE,
  UPDATE_LOCAL_CART_ITEM_QUANTITY_ERROR,
  DELETE_CART_ITEM_BY_ITEM_ID_REQ,
  DELETE_CART_ITEM_BY_ITEM_ID_SUCCESS,
  DELETE_CART_ITEM_BY_ITEM_ID_ERROR,
  UPDATE_USER_CART_BY_TOKEN_REQ,
  UPDATE_USER_CART_BY_TOKEN_SUCCESS,
  UPDATE_USER_CART_BY_TOKEN_ERROR,
  DELETE_LOCAL_CART_ITEMS_REQ,
  DELETE_LOCAL_CART_ITEMS_SUCCESS,
  DELETE_LOCAL_CART_ITEMS_ERROR,
  RESET_RESPONSE
} from "../../Constants/Cart";
import axios from "axios";
import * as api from "../../apis/common";

export {
  addItemToCartReq,
  getCartByUserReq,
  updateLocalCartItems,
  addMultipleProductReq,
  deleteCartItemByItemId,
  updateUserCartByToken
}

function addItemToCartReq(item, token) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_ITEM_TO_CART_REQ });

      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
                    createUserCart(
                        product_id: ${item.product_id},
                        product_quantity: ${item.product_quantity},
                        product_price: ${item.product_price},
                        product_name: "${item.product_name}",
                        category_name: "${item.category_name}",
                        description: "DESCRIPTION",
                        image_url: "${item.image_url}"
                    ){
                        success
                        statusCode
                        message
                    }
                  }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );
      if (result?.data?.errors) {
        dispatch({
          type: ADD_ITEM_TO_CART_ERROR,
          payload: result.data.errors[0].message
        });
      } else {
        dispatch({
          type: ADD_ITEM_TO_CART_SUCCESS,
          payload: result.data.data.createUserCart
        });
      }
    } catch (error) {
      dispatch({ type: ADD_ITEM_TO_CART_ERROR, payload: error });
    }
  };
}

function getCartByUserReq(token) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CART_BY_USER_REQ });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `{
            cartByUserId {
              success
              statusCode
              message
              data{
                delivery_charges
                cart{
                id
                product_id
                product_price
                product_quantity
                tax
                product_name
                category_name
                description
                short_description
                image_url
                user_id
                productStock
                short_description
                }
              }
          }
        }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );
      if (result?.data?.errors) {
        dispatch({
          type: GET_CART_BY_USER_ERROR,
          payload: result.data.errors[0].message
        });
      } else {
        dispatch({
          type: GET_CART_BY_USER_SUCCESS,
          payload: result.data.data.cartByUserId.data
        });
      }
    } catch (error) {
      dispatch({ type: GET_CART_BY_USER_ERROR, payload: error });
    }
  };
}

export function resetCartItemsReq(dispatch) {
  dispatch({ type: RESET_LOCAL_CART_ITEMS });
}

export function resetResponse(dispatch) {
  dispatch({ type: RESET_RESPONSE });
}

function updateLocalCartItems(cart, id, cb) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_LOCAL_CART_ITEMS_REQ, payload: cart, updatingItemId: id });
      setTimeout(function () {
        dispatch({ type: UPDATE_LOCAL_CART_ITEMS_SUCCESS });
      }, 500);
      cb('success', 'Item added successfully');
    } catch (error) {
      cb('error', 'Item is not added');
      dispatch({ type: UPDATE_LOCAL_CART_ITEMS_ERROR });
    }
  };
}

function addMultipleProductReq(products, token, cb) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_MULTIPLE_PRODUCTS_REQ, payload: products[0] });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
              createUserCart(product:${JSON.stringify(products).replace(
            /"(\w+)"\s*:/g,
            "$1:"
          )})
                {
                success
                statusCode
                message
                data{
                  delivery_charges
                  cart{
                  id
                  product_id
                  product_price
                  product_quantity
                  tax
                  product_name
                  category_name
                  description
                  short_description
                  image_url
                  user_id
                  productStock
                  short_description
                  }
                }
                  dataCount
                  allRecord
                }
            }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );
      if (result?.data?.data?.createUserCart?.statusCode === "200") {
        cb('success', result.data.data.createUserCart.message);
        dispatch({
          type: ADD_MULTIPLE_PRODUCTS_SUCCESS,
          payload: result.data.data.createUserCart
        });
        dispatch(getCartByUserReq(token));
      } else {
        cb('error', result.data.data.createUserCart.message);
        dispatch({
          type: ADD_MULTIPLE_PRODUCTS_ERROR,
          payload: result.data.error
        });
      }
    } catch (error) {
      cb('error');
      dispatch({ type: ADD_MULTIPLE_PRODUCTS_ERROR, payload: error });
    }
  };
}

export function updateLocalCartItemQuantity(payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_LOCAL_CART_ITEM_QUANTITY_REQ, payload });

      setTimeout(
        await function () {
          dispatch({ type: UPDATE_LOCAL_CART_ITEM_QUANTITY_SUCCESS });
        },
        2000
      );
    } catch (error) {
      dispatch({ type: UPDATE_LOCAL_CART_ITEM_QUANTITY_ERROR });
    }
  };
}

function deleteCartItemByItemId(item, token) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_CART_ITEM_BY_ITEM_ID_REQ, payload: item });

      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
            deleteCartById(id:${item.id}){
                success
                statusCode
                message
            }
          }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (result?.data?.data?.deleteCartById?.statusCode == "200") {
        dispatch({
          type: DELETE_CART_ITEM_BY_ITEM_ID_SUCCESS,
          payload: result.data.data.deleteCartById.message
        });
        dispatch(getCartByUserReq(token));
      } else {
        dispatch({
          type: DELETE_CART_ITEM_BY_ITEM_ID_ERROR,
          payload: result.data.data.deleteCartById.message
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_CART_ITEM_BY_ITEM_ID_ERROR, payload: error });
    }
  };
}

function updateUserCartByToken(productId, quantity, token, cb) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_CART_BY_TOKEN_REQ });

      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
            updateCartById(id:${productId}, product_quantity:${quantity}){
               success
                 statusCode
                 message
                 data{
                  delivery_charges
                  cart{
                  id
                  product_id
                  product_price
                  product_quantity
                  tax
                  product_name
                  category_name
                  description
                  short_description
                  image_url
                  user_id
                  productStock
                  short_description
                  }
                }
                dataCount
                allRecord
            }
          }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );
      if (result?.data?.data?.updateCartById?.statusCode == "200") {
        cb('success', result.data.data.updateCartById.message);
        dispatch({
          type: UPDATE_USER_CART_BY_TOKEN_SUCCESS,
          payload: result.data.data
        });
      } else if (result?.data?.data?.updateCartById?.statusCode == "301") {
        cb('error', result.data.data.updateCartById.message);
        dispatch({
          type: UPDATE_USER_CART_BY_TOKEN_301_ERROR,
          payload: result?.data?.data?.updateCartById
        });
        dispatch({
          type: UPDATE_USER_CART_BY_TOKEN_301_ERROR_RESTORE,
          payload: {}
        });
      } else {
        dispatch({ type: UPDATE_USER_CART_BY_TOKEN_ERROR });
      }
    } catch (e) {
      dispatch({ type: UPDATE_USER_CART_BY_TOKEN_ERROR, payload: e });
    }
  };
}

export function deleteLocalCartItem(product, localCart) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_LOCAL_CART_ITEMS_REQ });
      const itemTodelete = localCart.findIndex((localCartItem) => localCartItem.product_id == product.product_id);
      if (itemTodelete > -1) {
        localCart.splice(itemTodelete, 1);
      }
      dispatch({ type: DELETE_LOCAL_CART_ITEMS_SUCCESS, payload: localCart });
    } catch (error) {
      dispatch({ type: DELETE_LOCAL_CART_ITEMS_ERROR });
    }
  };
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  
  Divider,
  Table,
  Tag,
  
  // Form,
  // Input,
  Button,
  // Spin,
  // Select,
  // Menu,
} from "antd";
import "./paymentPage.css";

import { sendProductEmailReq } from "../../Actions/SendProductEmail/action";

//import Paypal from "../../components/Paypal";

//import Loading from "../../components/Loading";

import { getCartByUserReq } from '../../Actions/Cart/actions'

//const { Title } = Typography;

const PaymentPage = () => {

  // states
  const [details, setDetails] = useState({});
  const [cartAmount, setCartAmount] = useState(0);
  const [dataSource, setDataSource] = useState();
  const [outStockError, setOutStockError] = useState(false);

  const cartState = useSelector((state) => state.cart);
  const userState = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const addressId = queryParams.get("addressId");

  const handleSubmit = () => {
    if (userState?.userDetails?.api_token) {
      const addressId = queryParams.get("addressId");
      const shippingAddressId = Number(addressId)
      dispatch(sendProductEmailReq(shippingAddressId, userState?.userDetails?.api_token))
    }
  }

  useEffect(() => {
    let deliveryCharges = 0;

    if (userState.userDetails.api_token) {
      deliveryCharges = cartState?.items?.delivery_charges;
    }
    setDetails({ deliveryCharges });
  }, [])

  useEffect(() => {
    if (userState?.userDetails?.api_token) {
      dispatch(getCartByUserReq(userState.userDetails.api_token));
    }
  }, [dispatch])

  useEffect(() => {
    if (userState?.userDetails?.api_token && cartState?.items.cart.length) {
      setDataSource(cartState?.items);

      const outOfStockItems = cartState.items.cart.filter(userCartItem => userCartItem.productStock < userCartItem.product_quantity);
      if (outOfStockItems.length) {
        setOutStockError(true);
      } else {
        setOutStockError(false);
      }

      let cost = 0;
      for (var i = 0; i < cartState.items.cart.length; i++) {
        let pricePerItem = cartState.items.cart[i].product_price * cartState.items.cart[i].product_quantity;
        cost += Number(pricePerItem) + ((Number(pricePerItem) * cartState.items.cart[i].tax) / 100);
      }
      setCartAmount(cost);
    } else {
      history.push("/cart");
    }
  }, [cartState.getCartLoading]);


  const columns = [
    {
      title: 'Title',
      dataIndex: 'product_name',
      key: 'product_name',
      className: "orderIdColumn",
      width: "40%",
      render: (_, record) => {
        return <Link to={`/product-details/${encodeURI(record.product_name.toLowerCase())}/${record.product_id}`} style={{ color: "#1890FF" }}>{record.product_name}</Link>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
      className: "orderIdColumn",
      width: "10%",
      render: (_, record) => {
        return <p>{record.product_quantity}</p>
      }
    },
    // {
    //   title: 'Product code',
    //   dataIndex: 'product_code',
    //   key: 'product_code',
    //   className: "orderIdColumn",
    //   width: "15%",
    //   render: (_, record) => {
    //     { console.log("record>>>>>>>>", record) }
    //     return <p>{record.product_code}</p>
    //   }
    // },
    // {
    //   title: 'Price',
    //   dataIndex: 'product_price',
    //   key: 'product_price',
    //   className: "orderIdColumn",
    //   width: "15%",
    //   render: (_, record) => {
    //     return <p>{record.product_price}</p>
    //   }
    // },
    // {
    //   title: 'Vat',
    //   dataIndex: 'tax',
    //   key: 'tax',
    //   className: "orderIdColumn",
    //   width: "15%",
    //   render: (_, record) => {
    //     return <p>{record.tax}%</p>
    //   }
    // },
    // {
    //   title: 'Price (Inc. vat)',
    //   dataIndex: 'product_price',
    //   key: 'total_price',
    //   className: "orderIdColumn",
    //   width: "15%",
    //   render: (_, record) => {
    //     return <>&#163;{(parseFloat(record.product_price) + ((parseFloat(record.product_price) * parseFloat(record.tax)) / 100)).toFixed(2)}</>
    //   }
    // },
    {
      title: 'Status',
      dataIndex: 'productStock',
      key: 'productStock',
      className: "orderIdColumn",
      width: "15%",
      render: (_, record) => {
        {
          return (record.productStock < record.product_quantity ? (
            <Tag color="red">Out of stock</Tag>
          ) : (
            <Tag color="green">In stock</Tag>
          ))
        }
      }
    }
  ];

  return (
    <div className="container">
      <div className="selectPaymentMethodContainer">
        {/* Please don't remove this code. This code is for paypal payment method */}
        {/* <Row>
          <Col span={12}>
            <Title level={2} className="paymentTitle">Pay using Paypal</Title>
          </Col>
          <Col span={12}>
            <div style={{ marginBottom: "10px", textAlign: "right" }}>
              {
                parseInt(details?.deliveryCharges) > 0 && (
                  <Popover placement="bottomRight" content={<span>Delivery charges will be applicable only if total is under 200</span>}>
                    <span className="a-size-delivery">Delivery Charges : &#163;{`${parseInt(details?.deliveryCharges).toFixed(2)}`}</span><br />
                  </Popover>
                )
              }
              <span className="a-size-medium">
                Amount to pay: <span className="currencyINR">&nbsp;{(parseInt(dataSource?.delivery_charges) + parseFloat(cartAmount)).toFixed(2)}</span>
              </span>
            </div>
          </Col>
        </Row>*/}
        {/* {
          outStockError ? (
            <Row justify="space-between">
              <Col span={18}>
                <div style={{ textAlign: "center", backgroundColor: "red", padding: "4px" }}>
                  <Title level={5} style={{ color: "#fff" }}>To proceed further, please remove items which are out of stock</Title>
                </div>
              </Col>
              <Col span={4}>
                <Button type="primary" block>Go to cart</Button>
              </Col>
            </Row>
          ) : (
            cartAmount > 0 ? (
              <Row justify="end" align="middle">
                <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}><span className="pay-with">Pay with: </span></Col>
                <Col xs={24} sm={12} md={10} lg={8} xl={6} xxl={5}>
                  <Paypal amount={(parseInt(dataSource?.delivery_charges) + parseFloat(cartAmount)).toFixed(2)} addressId={addressId} />
                </Col>
              </Row>
            ) : (
              <Loading />
            )
          )
        } */}
        <h2>Cart summary</h2>
        <Divider />
        <Table
          dataSource={dataSource?.cart}
          columns={columns}
          size='small'
          loading={cartState.getCartLoading}
          scroll={{ x: 200 }}
        />
        <Button
          onClick={handleSubmit}
          type="primary"
          style={{ float: "right" }}
        // size={"large"}
        // loading={localCartLoading || addMultipleProductsLoading}
        >
          <Link
            to="/thankYou"
          >
            Submmit
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default PaymentPage;

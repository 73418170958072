import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Image } from "antd";

const ImagePreview = (props) => {
  const { defaultImage, images } = props;

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    if (visible) {
      images.map((item, index) => {
        if (defaultImage == item.image_url) {
          setCurrent(index);
        }
      });
    }
  }, [visible]);

  return (
    <>
      <Image
        preview={{ visible: false }}
        src={defaultImage}
        onClick={() => {
          setVisible(true);
        }}
      />
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            visible,

            onVisibleChange: (vis) => {
              setVisible(vis);
            },
            current: current,
          }}
        >
          {images &&
            images?.map((img, index) => {
              return (
                <Image
                  src={img?.original_image ? img?.original_image : img?.image_url}
                  key={index}
                />
              );
            })}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

ImagePreview.propTypes = {
  defaultImage: PropTypes.string,
  images: PropTypes.array,
};

export default ImagePreview;

export const GRAPHQL_API = `${process.env.REACT_APP_REST_API_URL}/public/graphql`;
export const LOGIN_API = `${process.env.REACT_APP_REST_API_URL}/public/api/login`;
export const LOGOUT_API = `${process.env.REACT_APP_REST_API_URL}/public/api/logout`;
export const GET_USER = `query users{
      users{
          id
          name
          email
      }
}`;
export const GET_CATEGORIES = `{
    categories {
          success
          statusCode
          message
          data{
            id
            name
            description
            image
            parent_id
            child{
              id
              name
              description
              parent_id
              child{
                id
                name
                description
                parent_id
                child{
                  id
                  name
                  description
                  parent_id
                }
              }
            }
          }
      }
  }`;

export const GET_PRODUCT = `query products{
    products{
       success
       statusCode
       message
       data{
           id
           name
           product_code
           quantity
           description
           status
           category_id
           is_featured
           price
           getImage{
                 id
                 image_url
                 image_type
               }
           }
     }
}`;

export const REGISTER_USER = `mutation{
  createUser(
    name:"sharanveer",
    phone:"1234567890",
    email:"test222@gmail.com",
    password:"1234567890",
    postal_code:123456){
      success
      statusCode
      message
      data{
        id
        name
        email
      }
  }
}`;

export const LOGIN_USER = `mutation{
  login(
    email:"test1@gmail.com",
    password:"1234567890"){
      success
      statusCode
      message
    data{
      api_token
    }
  }
}`

export const FEATURED_PRODUCTS = `query featuredproduct{
     featuredproduct{
       success
       statusCode
       message
       data{
           id
           name
           slug
           product_code
           short_description
           tax
           quantity
           description
           status
           category_id
           is_featured
           price
           price_status
           price_description
           getImage{
                 id
                 image_url
                 image_type
               }
           }
     }
}`;

export const CAROUSEL_DATA = `{
  getDynamicSlider {
        success
        statusCode
        message
        data{
          id
          title
          description
         image
         url
  
        }
    }
}`;

export const GET_PRODUCT_REVIEWS = `{
  customerReview{
    success
    statusCode
    message
    data{
      id
      review
      rating
      user_id
      product_id
      getUser{
        id
        name
      }
    }
  }
}`;

export const MERCHANT_SESSION_API = "https://pi-test.sagepay.com/api/v1/merchant-session-keys";
export const CARD_IDENTIFIERS = "https://pi-test.sagepay.com/api/v1/card-identifiers";
export const TRANSACTION_REQUEST_API = "https://pi-test.sagepay.com/api/v1/transactions";
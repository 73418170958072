import {
  GET_CAROUSEL_DATA_REQ,
  GET_CAROUSEL_DATA_SUCCESS,
  GET_CAROUSEL_DATA_ERROR
} from "../../Constants/HomeCarousel";

let initialState = {
  isLoading: false,
  data: [],
  error: {}
};

const homeCarouselReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAROUSEL_DATA_REQ:
      return {
        ...state,
        isLoading: true
      };
    case GET_CAROUSEL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case GET_CAROUSEL_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload[0]
      };
    default:
      return state;
  }
};

export default homeCarouselReducer;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import Moment from 'react-moment';
import { Link, useHistory } from "react-router-dom";
import { Result, Button, Divider, Table, Popover, Row } from 'antd';

import Loading from "../../components/Loading";
import TableSkeleton from '../../components/TableSkeleton';

import './results.css';

// actions
import { verifyPayment } from "../../Actions/Result/actions.js";

const Results = (props) => {
  // States
  const [deliveryCharge, setDeliveryCharge] = useState(null);
  const [viewOrder, setViewOrder] = useState(true);
  const [grandTotal, setGrandTotal] = useState(null);

  // Redux-store
  const paymentState = useSelector(state => state.payment);
  const userState = useSelector(state => state.user);
  const resultsState = useSelector(state => state.results);

  const { lastOrderId, addressId } = paymentState;
  const { paymentVerificationLoading, paymentVerificationResponse } = resultsState;

  const history = useHistory();
  useEffect(() => {
    let deliveryCharges = paymentVerificationResponse?.data?.delivery_charges;

    if (paymentVerificationResponse?.data?.products) {
      let total = 0;
      (paymentVerificationResponse?.data.products || []).map(pvResp => {
        total += pvResp.total_price;
      });

      setDeliveryCharge(deliveryCharges);
      setGrandTotal(Number(total) + Number(deliveryCharges));
    }
  }, [paymentVerificationResponse])

  useEffect(() => {
    if (!props.userInfo?.api_token) {
      history.push("/cart")
    }
  }, [])
  const renderContent = (value, row) => {
    return <Link to={`/product-details/${encodeURI(value.toLowerCase())}/${row.product_id}`} style={{ color: "#1890FF" }} >{value}</Link>;
  };

  const renderDate = () => {
    return (
      <Moment format='MM/DD/YYYY'>
        {paymentVerificationResponse?.data?.updated_at}
      </Moment>
    )
  }

  const tabColumn = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      render: renderContent,
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      render: renderDate
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center"
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "center",
      className: "currency-sign"
    },
    {
      title: "Vat",
      dataIndex: "vat",
      align: "center",
      render: (_, record) => {
        return <p>{record.vat}%</p>
      }
    },
    {
      title: 'Price (Inc. vat)',
      dataIndex: 'price',
      key: 'price',
      align: "center",
      className: "orderIdColumn",
      render: (_, record) => {
        return <>&#163;{(parseFloat(record.price) + ((parseFloat(record.price) * parseFloat(record.vat)) / 100)).toFixed(2)}</>
      }
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      render: (_, record) => {
        return Number(record.total_price).toFixed(2)
      },
      align: "center",
      className: "currency-sign"
    }
  ]

  //react-redux
  const dispatch = useDispatch();

  useEffect(() => {
    if (lastOrderId) {
      if (userState?.userDetails.api_token) {
        dispatch(verifyPayment(lastOrderId, addressId, userState?.userDetails.api_token));
      }
    }
  }, [])

  return (
    <div>
      {
        paymentVerificationLoading ? (
          <Loading />
        ) : (
          <Result
            status={paymentVerificationResponse?.success === "1" ? 'success' : 'error'}
            title={paymentVerificationResponse?.success === "1" ? 'Order Placed Successfully!' : "Transaction Failed"}
            extra={[
              <Button
                type='primary'
                key='console'
                onClick={() => setViewOrder(!viewOrder)}
              >
                {viewOrder ? "Hide" : "View"} Order Items
              </Button>,
              <Button key='buy'><Link to="/">Buy Again</Link></Button>
            ]}
          >
            {viewOrder && paymentVerificationResponse?.data?.products?.length &&
              <>
                <Divider />
                <TableSkeleton
                  scroll={{ x: 767 }}
                  tabColumn={tabColumn}
                  data={paymentVerificationResponse?.data?.products}
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell><p><b>Grand Total</b></p></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {
                            Number(deliveryCharge) > 0 && (
                              <Popover placement="bottomRight" content={<span>Delivery charges will be applicable only if total is under 200</span>}>
                                <Row justify="end">
                                  <span>Delivery Charges : &#163;{`${Number(deliveryCharge).toFixed(2)}`}</span>
                                </Row>
                              </Popover>
                            )
                          }
                          <Row justify="end">
                            <span className="currencyINR fs-16 color-red">{Number(grandTotal).toFixed(2)}</span>
                          </Row>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </>}
          </Result>
        )
      }
    </div>
  )
}
Results.propTypes = {
  userInfo: PropTypes.object
};
export default Results

import {
    SEND_PRODUCT_EMAIL_REQ,
    SEND_PRODUCT_EMAIL_SUCCESS,
    SEND_PRODUCT_EMAIL_ERROR
} from '../../Constants/SendProductEmail';

const initialState = {
    sendProductEmailLoading: false,
    sendProductEmailResp: {}
};

const sendProductEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_PRODUCT_EMAIL_REQ:
            return {
                ...state,
                sendProductEmailLoading: true,
                sendProductEmailResp: {}
            }
        case SEND_PRODUCT_EMAIL_SUCCESS:
            return {
                ...state,
                sendProductEmailLoading: false,
                sendProductEmailResp: action.payload
            }
        case SEND_PRODUCT_EMAIL_ERROR:
            return {
                ...state,
                sendProductEmailLoading: false,
                sendProductEmailResp: action.payload
            }
        default:
            return state;
    }

}

export default sendProductEmailReducer;
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Button,
  notification,
  Divider,
  Popconfirm
} from "antd";
import "./existingAddresses.css";

import { deleteAddress } from "../../Actions/Address";
import Loading from "../Loading";

const ExistingAddresses = ({
  edit,
  existingAddressesList
}) => {
  const [availableAddresses, setAvailableAddresses] = useState({});
  const userState = useSelector((state) => state.user);
  const cartState = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let primary = [];
    let secondary = [];
    for (let i = 0; i < existingAddressesList.length; i++) {
      if (existingAddressesList[i].is_primary == 1) {
        primary.push(existingAddressesList[i]);
      } else {
        secondary.push(existingAddressesList[i]);
      }
    }
    setAvailableAddresses({ primary, secondary });
  }, [existingAddressesList]);
  const handleDelete = (addressToBeDeleted) => {
    if (addressToBeDeleted.is_primary !== '1') {
      dispatch(
        deleteAddress(addressToBeDeleted.id, userState?.userDetails?.api_token)
      );
    } else {
      notification.error({
        message: "Error",
        description:
          "This is your primary address. Please make sure to create a new address and mark that address as your primary address. After that you can delete this address."
      });
    }
  };


  const fetchAddresses = () => {
    return (
      <>
        {availableAddresses?.primary?.length ? (
          <>
            <Divider orientation="left" style={{ fontWeight: 700 }}>Primary Address</Divider>
            {availableAddresses?.primary.map((address, i) => {
              if (address.is_primary == 1) {
                return (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                    xxl={{ span: 6 }}
                    key={i}
                  >
                    <Card bordered={true}>
                      <Row justify="space-between">
                        <Col span={24}>
                          <h4 className="addressFullName">{address.full_name}</h4>
                        </Col>
                        <Col span={24}>
                          <p className="address-lines">
                            <Row>
                              <span className="phone-overflow fields-overflow"><b className="absoluteLabel">Phone : </b>{address.phone}</span>
                            </Row>
                            <Row>
                              <span className="address-overflow"><b>Address Line 1: </b>{address.address}</span>
                            </Row>
                            {/* <Row>
                              <span className="address-overflow"><b>Address Line 2: </b>{address.landmark}</span>
                            </Row> */}
                            <Row>
                              <span className="country-overflow fields-overflow"><b className="absoluteLabel">Country: </b>{address.country}</span>
                            </Row>
                            <Row>
                              <span  className="state-overflow fields-overflow"><b className="absoluteLabel">State: </b>{address.state}</span>
                            </Row>
                            <Row>
                              <span className="city-overflow fields-overflow"><b className="absoluteLabel">City: </b>{address.city}</span>
                            </Row>
                            <Row>
                              <span className="postalCode-overflow fields-overflow"><b className="absoluteLabel">Postal Code: </b>{address.postal_code}</span>
                            </Row>
                            <Row>
                              <span className="locationType-overflow fields-overflow"><b className="absoluteLabel">Location type : </b>{address.location_type.toUpperCase()}</span>
                            </Row>
                          </p>
                        </Col>
                        {
                          (cartState.items.cart.length || cartState.localCart.length) ?
                            <Col span={24} style={{ paddingBottom: "12px" }}>
                              <Button type="primary" onClick={() => history.push(`/payment?addressId=${address.id}`)} block>
                                Deliver here
                              </Button>
                            </Col> : <></>}
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={11}>
                              <Button
                                className="ant-btn-secondary"
                                onClick={() => {
                                  window.scrollTo(0, 0)
                                  edit(address)
                                }
                                }
                                block
                              >
                                Edit
                              </Button>
                            </Col>
                            <Col span={11}>
                              <Popconfirm
                                title="Are you sure to delete this address?"
                                onConfirm={() => handleDelete(address)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button className="ant-btn-secondary" block>
                                  Delete
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              }
            })}
          </>
        ) : (
          <></>
        )}

        {availableAddresses?.secondary?.length ? (
          <>
            <Divider orientation="left" style={{ fontWeight: 700 }}>Secondary Address</Divider>
            <Row gutter={[8, 8]}>
              {availableAddresses?.secondary.map((address, i) => {
                if (address.is_primary == 0) {
                  return (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                      key={i}
                    >
                      <Card bordered={true}>
                        <Row justify="space-between">
                          <Col span={24}>
                            <h4 className="addressFullName">{address.full_name}</h4>
                          </Col>
                          <Col span={24}>
                            <p className="address-lines">
                              <Row>
                                <span><b>Phone : </b>{address.phone}</span>
                              </Row>
                              <Row>
                              <span className="address-overflow"><b>Address Line 1: </b>{address.address}</span>
                              </Row>
                              <Row>
                                <span><b>Country: </b>{address.country}</span>
                              </Row>
                              <Row>
                                <span><b>State: </b>{address.state}</span>
                              </Row>
                              <Row>
                                <span><b>City: </b>{address.city}</span>
                              </Row>
                              <Row>
                                <span><b>Postal Code: </b>{address.postal_code}</span>
                              </Row>
                              <Row>
                                <span><b>Location type : </b>{address.location_type.toUpperCase()}</span>
                              </Row>
                            </p>
                          </Col>
                          {
                            (cartState.items.cart.length || cartState.localCart.length) ?
                              <Col span={24} style={{ paddingBottom: "12px" }}>
                                <Button type="primary" onClick={() => history.push(`/payment?addressId=${address.id}`)} block>
                                  Deliver here
                                </Button>
                              </Col>
                              : <></>
                          }
                          <Col span={24}>
                            <Row justify="space-between">
                              <Col span={11}>
                                <Button
                                  className="ant-btn-secondary"
                                  onClick={() => edit(address)}
                                  block
                                >
                                  Edit
                                </Button>
                              </Col>
                              <Col span={11}>
                                <Popconfirm
                                  title="Are you sure to delete this address?"
                                  onConfirm={() => handleDelete(address)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button className="ant-btn-secondary" block>
                                    Delete
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                }
              })}
            </Row>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className="site-card-wrapper">
      {!availableAddresses ? <Loading size="large" /> : fetchAddresses()}
    </div>
  );
};

ExistingAddresses.propTypes = {
  edit: PropTypes.func,
  existingAddressesList: PropTypes.array
};

export default ExistingAddresses;
import {
    GET_USER_HISTORY_REQ,
    GET_USER_HISTORY_SUCCESS,
    GET_USER_HISTORY_ERROR
} from '../../Constants/OrderHistory'

let intialState = {
    orderHistoryLoading: false,
    orderHistorySuccess: [],
    orderHistoryError: ""
}

const orderReducer = (state = intialState, action) => {
    switch (action.type) {
        case GET_USER_HISTORY_REQ:
            return {
                ...state,
                orderHistoryLoading: true,
                orderHistorySuccess: []
            }
        case GET_USER_HISTORY_SUCCESS:
            return {
                ...state,
                orderHistoryLoading: false,
                orderHistorySuccess: action.payload
            }
        case GET_USER_HISTORY_ERROR:
            return {
                ...state,
                orderHistoryLoading: false,
                orderHistorySuccess: [],
                orderHistoryError: action.payload
            }
        default:
            return state;
    }
}

export default orderReducer;
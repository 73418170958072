import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Menu,
  // Dropdown,
  Button,
  Row,
  Col,
  Drawer,
  Divider,
  Popover,
  message,
  Spin,
} from "antd";
import "./header.css";

// containers
import PopupModal from "../../container/PopupModal";

// actions
import { logout } from "../../Actions/User/actions";
import {
  getAllCategoriesReq,
  resetCategorySearch,
  setCurrentCategory,
} from "../../Actions/Category/actions";

const { SubMenu } = Menu;

const Header = () => {
  // States
  const [searchText, setSearchText] = useState("");
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [pageInUrl, setPageInUrl] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [categoryDrawerOpen, setCategoryDrawerOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [activePage, setActivePage] = useState({
    name: "",
    activeStatus: false,
  });

  // redux-store
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const categoryState = useSelector((state) => state.category);
  const { allCategories, category, isLoading } = categoryState;
  const [categorySelected, setCategorySelected] = useState(category);
  const localCart = useSelector((state) => state.cart.localCart);

  // Router props
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    if (
      window.scrollY === 0 &&
      location.pathname === "/" &&
      !activePage.activeStatus
    ) {
      searchRef.current.focus();
    }
  });

  useEffect(() => {
    if (!location.pathname.includes("product-list")) {
      if (!location.pathname.includes("product-details")) {
        searchRef.current.value = "";
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(getAllCategoriesReq);
  }, [dispatch]);

  useEffect(() => {
    setCategorySelected(category);
  }, [isLoading]);

  useEffect(() => {
    if (location.pathname === "/") {
      setCategorySelected({
        name: "All",
        id: 0,
        description: "",
      });
    }
    const pages = ["", "about", "contact"];
    let pageName = location.pathname.replace("/", "");
    if (pages.includes(pageName)) {
      setPageInUrl(pageName);
    } else {
      setPageInUrl(null);
    }
  }, [location.pathname]);

  const parseTotalItemsCount = (items) => {
    let count = 0;
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        count += items[i].product_quantity;
      }
    }
    return count;
  };

  useEffect(() => {
    if (user?.userDetails?.api_token) {
      if (cart.items.length || (cart.items?.cart && cart.items?.cart?.length)) {
        setCartItemsCount(parseTotalItemsCount(cart?.items?.cart));
      } else {
        setCartItemsCount(0);
      }
    } else {
      setCartItemsCount(parseTotalItemsCount(localCart));
    }
  }, [
    user.userDetails,
    cart.items,
    cart.items?.cart,
    cart.getCartLoading,
    parseTotalItemsCount(localCart),
  ]);

  useEffect(() => {
    if (toggleMenu) {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
    }
  });

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      toggleMenu
    ) {
      setToggleMenu(false);
    }
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    if (inputValue.length >= 3) {
      setSearchText(inputValue);
    } else {
      setSearchText("");
    }
  };

  function logoutResp(resp) {
    if (resp.success) {
      message.success(resp.message, 3);
    }
  }

  const handleLogout = (e) => {
    e.preventDefault();
    // dispatch(setUserDetailsReq({}));
    dispatch(logout(user?.userDetails?.api_token, logoutResp));
  };

  const handleUpdate = (name, activeStatus) => {
    setActivePage({ name, activeStatus });
  };

  const changeCategory = (category) => {
    setCategorySelected(category);
    if (category.parent_id === "0" || !category.parent_id) {
      setToggleMenu(!toggleMenu);
    } else {
      handleSearchProds(category);
    }
  };

  const handleSearchProds = (categ) => {
    if (categ.name === "All" && categ.id === 0 && searchText === "") {
      history.push("/all-products");
    } else {
      dispatch(
        setCurrentCategory({
          name: categ.name,
          id: categ.id,
          description: categ.description,
        })
      );
      history.push(
        `/product-list?category_id=${categ.id}&page=1&search=${encodeURI(
          searchText
            .replace(/[^A-Z0-9]+/gi, "_")
            .trim()
            .toLowerCase()
        )}&filters=`
      );
    }
  };

  const handleDrawerMenuClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCategoryMenuClick = () => {

    setCategoryDrawerOpen(!categoryDrawerOpen);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (allCategories.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchProds(categorySelected);
    }
  };

  const hoverContents = () => {
    if (user.userDetails?.api_token) {
      return (
        <Spin spinning={user.logoutLoading}>
          <Row style={{ textAlign: "center" }}>
            <Col span={24}>
              <Link to="/your-account">
                <span className="menu-text">Your Account</span>
              </Link>
              <Link to="/privacy-policy">
                <span className="menu-text">Privacy policy</span>
              </Link>
              <Link to="/terms">
                <span className="menu-text">Terms & Condition</span>
              </Link>
              <Button
                type="primary"
                className="sgnin_btn"
                onClick={(e) => {
                  handleLogout(e);
                  history.push("/");
                }}
              >
                sign out
              </Button>
            </Col>
          </Row>
        </Spin>
      );
    } else {
      return (
        <div>
          <div>
            <p className="signInWelcome">Welcome</p>
          </div>
          <div className="row signRow">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signin-dropdown-form">
              <p className="signInAccess">
                To access account and manage orders
              </p>
              <div className="sgnin_blk_sec">
                <button
                  className="sgnin_btn"
                  id="hdr_signin"
                  onClick={() => handleUpdate("login", true)}
                >
                  Sign In
                </button>

                <button
                  className="sgnin_btn"
                  id="hdr_signin"
                  onClick={() => handleUpdate("register", true)}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  // const categoryMenu = () => {
  //   return (
  //     <Menu>
  //       {allCategories?.length &&
  //         allCategories.map((category, index) => {
  //           if (category.child) {
  //             return (
  //               <SubMenu
  //                 className="shop-dropdown"
  //                 title={category.name}
  //                 key={category.id}
  //               >
  //                 {category.child.map((subCateg) => {
  //                   return (
  //                     <Menu.Item
  //                       className="shop-dropdown"
  //                       key={subCateg.id}
  //                       onClick={() => changeCategory(subCateg)}
  //                     >
  //                       <Link
  //                         to={`/product-list?category_id=${
  //                           subCateg.id
  //                         }&page=1&search=${encodeURI(
  //                           searchText.trim().toLowerCase()
  //                         )}`}
  //                       >
  //                         {subCateg.name}
  //                       </Link>
  //                     </Menu.Item>
  //                   );
  //                 })}
  //               </SubMenu>
  //             );
  //           } else {
  //             return (
  //               <Menu.Item key={index}>
  //                 <Link
  //                   to={`/product-list?category_id=${
  //                     category.id
  //                   }&page=1&search=${encodeURI(
  //                     searchText.trim().toLowerCase()
  //                   )}`}
  //                 >
  //                   {category.name}
  //                 </Link>
  //               </Menu.Item>
  //             );
  //           }
  //         })}
  //     </Menu>
  //   );
  // };
  // const [current, setCurrent] = useState("");
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setOpenKeys([]);
  };

  // const handleSelected = (e) => {

  //   setCurrent(e.key);
  // };

  // useEffect(() => {
  //   if (location.pathname == "/product-list") {
  //     setCurrent(current);
  //   } else {
  //     setCurrent("");
  //     setOpenKeys([]);
  //   }
  // }, [location]);
  return (
    <div className="header">
      <div className="header-top-bar">
        <div className="container headerContainer">
          <ul className="headerLinks">
            <li>
              <Link
                className="f-icon"
                to="#"
                onClick={() =>
                  setActivePage({ name: "shipping", activeStatus: true })
                }
              >
                <i className="fas fa-truck fa-2x"></i>
                <span>Free Shipping</span>
              </Link>
            </li>
            <li>
              <span className="f-icon phone-css">
                <i className="fas fa-phone-alt fa-2x"></i>
                <span className="phone-inline">
                  {" "}
                  <a
                    href={`tel:${process?.env?.REACT_APP_UK_PHONE?.replace(
                      /\s/g,
                      ""
                    )}`}
                  >
                    UK: {process.env.REACT_APP_UK_PHONE}
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href={`tel:${process?.env?.REACT_APP_US_PHONE?.replace(
                      /^()- /g,
                      ""
                    )}`}
                  >
                    USA: {process?.env?.REACT_APP_US_PHONE}
                  </a>{" "}
                </span>
              </span>
            </li>
            <li>
              <div className="f-icon">
                <i className="fas fa-envelope fa-2x"></i>
                <span>
                  <a
                    className="headerEmai"
                    href="mailto:info@eagleworksolutions.com"
                  >
                    info@eagleworksolutions.com
                  </a>
                </span>
              </div>
            </li>
            <li>
              <Link
                className="f-icon"
                to="#"
                onClick={() =>
                  setActivePage({ name: "gifts", activeStatus: true })
                }
              >
                <i className="fas fa-gift fa-2x"></i>
                <span> Gift Cards</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="header-bar">
        <div className="container headerContainer">
          <Drawer
            width="200"
            maskClosable={true}
            title="Eagle Work Solutions"
            placement={"left"}
            // closable={true}
            onClose={handleDrawerMenuClick}
            visible={drawerOpen}
          >
            <div
              className="drawer-item"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <i className="fas fa-home drawer-icon-flex"></i>
              <Link to="/" className="drawer-item-text-flex">
                Home
              </Link>
            </div>
            <div
              className="drawer-item"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
                setCategoryDrawerOpen(!categoryDrawerOpen);
              }}
            >
              <i className="fas fa-shopping-bag drawer-icon-flex"></i>
              <p className="drawer-item-text-flex">Categories</p>
            </div>
            <div
              className="drawer-item"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <i className="fas fa-address-card drawer-icon-flex"></i>
              <Link to="/about" className="drawer-item-text-flex">
                About
              </Link>
            </div>
            <div
              className="drawer-item"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <i className="fas fa-id-card-alt drawer-icon-flex"></i>
              <Link to="/contact" className="drawer-item-text-flex">
                Contact Us
              </Link>
            </div>
            {user?.userDetails?.api_token && (
              <div
                className="drawer-item"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                <i className="fas fa-user-alt drawer-icon-flex"></i>
                <Link to="/your-account" className="drawer-item-text-flex">
                  Your Account
                </Link>
              </div>
            )}

            <Divider />

            {user.userDetails?.api_token ? (
              <div
                onClick={(e) => {
                  handleLogout(e);
                  setDrawerOpen(!drawerOpen);
                }}
                className="drawer-item"
              >
                <i className="fas fa-sign-out-alt drawer-icon-flex"></i>
                <span className="drawer-item-text-flex">Sign Out</span>
              </div>
            ) : (
              <>
                <div
                  onClick={() => {
                    handleUpdate("login", true);
                    setDrawerOpen(!drawerOpen);
                  }}
                  className="drawer-item"
                >
                  <i className="fas fa-sign-in-alt drawer-icon-flex"></i>
                  <span className="drawer-item-text-flex">Sign In</span>
                </div>
                <div
                  onClick={() => {
                    handleUpdate("register", true);
                    setDrawerOpen(!drawerOpen);
                  }}
                  className="drawer-item"
                >
                  <i className="fas fa-user-plus drawer-icon-flex"></i>
                  <span className="drawer-item-text-flex">Register</span>
                </div>
              </>
            )}
          </Drawer>
          <Drawer
            width="250"
            maskClosable={true}
            title="Categories"
            placement={"left"}
            closable={true}
            onClose={() => {
              handleCategoryMenuClick();
              setOpenKeys([]);
            }}
            visible={categoryDrawerOpen}
          >
            <Menu
              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              style={{ width: "100%" }}
            >
              {allCategories?.length &&
                allCategories.map((category, index) => {
                  return (
                    <SubMenu key={index} title={category.name.trim()}>
                      {category.child &&
                        category.child.map((subCateg) => {
                          {
                            if (subCateg?.child?.length > 0) {
                              return (
                                <SubMenu key={subCateg.id} title={subCateg.name.trim()}>
                                  {subCateg.child &&
                                    subCateg.child.map((subChildCateg) => {
                                      if (subChildCateg?.child?.length > 0) {
                                        return (
                                            <SubMenu style={{margin:"10px"}} Key={subChildCateg.id} title={subChildCateg.name.trim()}>
                                              {subChildCateg.child &&
                                                subChildCateg.child.map((subChildChildCateg) => {
                                                  return (
                                                    <Menu.Item
                                                    style={{margin:"10px"}}
                                                      key={subChildChildCateg.id}
                                                      onClick={() => {
                                                        changeCategory(subChildChildCateg);
                                                        setCategoryDrawerOpen(false)
                                                        onClose();
                                                      }}
                                                    >
                                                      {subChildChildCateg.name}
                                                    </Menu.Item>
                                                  )
                                                })
                                              }
                                            </SubMenu>
                                        )
                                      } else {
                                        return (
                                          <Menu.Item
                                            key={subChildCateg.id}
                                            onClick={() => {
                                              changeCategory(subChildCateg);
                                              onClose();
                                            }}
                                          >
                                            {subChildCateg.name}
                                          </Menu.Item>
                                        )
                                      }
                                    })
                                  }
                                </SubMenu>

                              );
                            } else {
                              return (
                                <Menu.Item
                                  key={subCateg.id}
                                // onClick={() => {
                                //   changeCategory(subCateg);
                                //   onClose();
                                // }}
                                >
                                  {subCateg.name}
                                </Menu.Item>
                              )
                            }

                          }
                        })}
                    </SubMenu>
                  );
                })}
            </Menu>
          </Drawer>
          <Row>
            <Col
              md={4}
              className="header-left"
              style={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <i
                className="fas fa-bars user-logo"
                onClick={() => handleDrawerMenuClick()}
              ></i>
              <Link
                to="/"
                title=""
                onClick={() => dispatch(resetCategorySearch)}
              >
                <img
                  src="/images/new-eagle-logo-white-font.svg"
                  alt="logo-eagle"
                  className="alignleft"
                />
              </Link>

              <Badge
                count={cartItemsCount}
                className="badge-right mobile-screen"
              >
                <img
                  src="/images/cart.svg"
                  alt=""
                  onClick={() => {
                    history.push("/cart");
                  }}
                />
              </Badge>
            </Col>

            <Col md={16} className="middle-search-area">
              <div className="midRow">
                <div className="input-group">
                  <div className="input-group-btn search-panel">
                    <button
                      ref={dropdownRef}
                      type="button"
                      className={`btn searchListBtn dropdown-toggle`}
                      onClick={() => {
                        setToggleMenu(!toggleMenu);
                      }}
                    >
                      <span id="search_concept">
                        {!categorySelected.name ? (
                          <Spin />
                        ) : (
                          categorySelected.name
                        )}
                      </span>
                    </button>
                    <ul
                      className={`dropdown-menu scrollable-dropdown ${toggleMenu ? "show" : ""
                        }`}
                      role="menu"
                    >
                      {categorySelected.id !== 0 && (
                        <li
                          onClick={() =>
                            changeCategory({
                              name: "All",
                              id: 0,
                              description: "",
                            })
                          }
                          value={categorySelected.id}
                        >
                          <a>All Categories</a>
                        </li>
                      )}
                      {allCategories &&
                        allCategories.map((category, i) => {
                          return (
                            <li
                              onClick={() => changeCategory(category)}
                              value={category.id}
                              key={i}
                            >
                              <a>{category.name}</a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    ref={searchRef}
                    onChange={(e) => handleInputChange(e)}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                  <span className="input-group-btn">
                    <input
                      type="submit"
                      className="btn searchBtn"
                      value=""
                      onClick={() => {
                        handleSearchProds(categorySelected);
                      }}
                    />
                  </span>
                </div>

                <div className="navbar-expand-lg navbar-custom">
                  <div id="navbarSupportedContent">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${pageInUrl === "" && "active"}`}
                          to="/"
                          onClick={() => dispatch(resetCategorySearch)}
                        >
                          Home
                        </Link>
                      </li>
                      {/* <Dropdown
                        overlay={categoryMenu}
                        trigger={["click"]}
                        className="nav-item"
                      >
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            onClick={showDrawer}
                          >
                            Shop
                          </Link>
                        </li>
                      </Dropdown> */}
                      <li className="nav-item dropdown">
                        <span
                          className="nav-link dropdown-toggle cursor-pointer"
                          //to="#"
                          onClick={showDrawer}
                        >
                          Categories
                        </span>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${pageInUrl === "about" && "active"
                            }`}
                          to="/about"
                        >
                          About
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${pageInUrl === "contact" && "active"
                            }`}
                          to="/contact"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>

            <Drawer
              title="Categories"
              placement="left"
              onClose={onClose}
              visible={open}
              className="sidebarMenu"
            >
              <Menu
                //selectedKeys={[current]}
                selectable={false}
                // onClick={handleSelected}
                mode="vertical"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                style={{ width: "100%" }}
                triggerSubMenuAction="click"
              >
                {allCategories?.length > 0 &&
                  allCategories.map((category) => {
                    return (
                      <SubMenu key={category.id} title={category.name.trim()}>
                        {category.child &&
                          category.child.map((subCateg) => {
                            if (subCateg?.child?.length > 0) {
                              return (
                                <SubMenu key={subCateg.id} title={subCateg.name.trim()}>
                                  {subCateg.child &&
                                    subCateg.child.map((subChildCateg) => {
                                      if (subChildCateg?.child?.length > 0) {
                                        return (
                                          <SubMenu Key={subChildCateg.id} title={subChildCateg.name.trim()}>
                                            {subChildCateg.child &&
                                              subChildCateg.child.map((subChildChildCateg) => {
                                                return (
                                                  <Menu.Item
                                                    key={subChildChildCateg.id}
                                                    onClick={() => {
                                                      changeCategory(subChildChildCateg);
                                                      onClose();
                                                    }}
                                                  >
                                                    {subChildChildCateg.name}
                                                  </Menu.Item>
                                                )
                                              })
                                            }
                                          </SubMenu>
                                        )
                                      } else {
                                        return (
                                          <Menu.Item
                                            key={subChildCateg.id}
                                            onClick={() => {
                                              changeCategory(subChildCateg);
                                              onClose();
                                            }}
                                          >
                                            {subChildCateg.name}
                                          </Menu.Item>
                                        )
                                      }
                                    })
                                  }
                                </SubMenu>
                              );
                            } else {
                              return (
                                <Menu.Item
                                  key={subCateg.id}
                                  onClick={() => {
                                    changeCategory(subCateg);
                                    onClose();
                                  }}
                                >
                                  {subCateg.name}
                                </Menu.Item>
                              )
                            }
                          })}
                      </SubMenu>
                    );
                  })}
              </Menu>
            </Drawer>

            <Col md={4} className="right-items">
              <Row justify="space-between">
                <Col>
                  {user?.userDetails?.api_token ? (
                    <Link to="/your-account">
                      <i className="far fa-user-circle user-logo"></i>
                    </Link>
                  ) : (
                    <i className="far fa-user-circle user-logo"></i>
                  )}
                </Col>
                <Col>
                  <Popover content={hoverContents} placement="bottom">
                    {user.userDetails?.api_token ? (
                      <div className="userTxt">
                        <div>
                          <span>
                            <b>Welcome</b>
                          </span>
                        </div>
                        <Row>
                          <span className="userName">
                            {user.userDetails.name.split(" ")[0]}
                          </span>
                          <img
                            src="/images/drop_down.png"
                            alt=""
                            className="headerDropDownImg"
                          />
                        </Row>
                      </div>
                    ) : (
                      <div className="userTxt">
                        <span>Sign In</span>
                        <img
                          src="/images/drop_down.png"
                          alt=""
                          className="pl-1"
                        />
                      </div>
                    )}
                  </Popover>
                </Col>
                <Col>
                  <Badge count={cartItemsCount} className="badge-right">
                    <Link to="/cart">
                      <img src="/images/cart.svg" alt="" />
                    </Link>
                  </Badge>
                </Col>
              </Row>
            </Col>
          </Row>
          {activePage.activeStatus && (
            <PopupModal pageInfo={activePage} func={handleUpdate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

import axios from "axios";
import {
    CHANGE_PASSWORD_REQ,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    RESET_INITIAL
} from "../../Constants/ChangePassword";
import * as api from "../../apis/common";

export {
    changePasswordReq
}

function changePasswordReq(cpData, token) {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_PASSWORD_REQ });
            const result = await axios.post(
                api.GRAPHQL_API, {
                query:
                    `mutation{
                        changeUserPassword(password: "${cpData.password}", new_password: "${cpData.new_password}"){
                           success
                           statusCode
                           message
                        }
                      }`
            },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }
            )

            if (result?.data?.data?.changeUserPassword?.success === "1") {
                dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: result?.data?.data?.changeUserPassword });
            } else {
                dispatch({ type: CHANGE_PASSWORD_ERROR, payload: result?.data?.data?.changeUserPassword })
            }
        } catch (e) {
            dispatch({ type: CHANGE_PASSWORD_ERROR, payload: e.errors[0] })
        }
    }
}

export function resetInitials() {
    return async (dispatch) => {
        dispatch({ type: RESET_INITIAL })
    }
}
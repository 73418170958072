import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination, Divider, Row, Col } from "antd";
import ProductListBlock from "../ProductListBlock";
import "./productListing.css";

// actions
import { getAllProductsReq } from "../../Actions/Products/actions";

const ProductListing = ({ products }) => {
  const allProds = useSelector((state) => state.products.allProducts);

  // Router props
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("category_id");
  const search = queryParams.get("search");
  const filters = queryParams.get("filters");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [products]);

  const onChange = (page) => {
    history.push(
      `/product-list?category_id=${categoryId}&page=${page}&search=${encodeURI(
        search
          .replace(/[^A-Z0-9]+/gi, "_")
          .trim()
          .toLowerCase()
      )}&filters=${filters}`
    );

    dispatch(
      getAllProductsReq(
        queryParams.get("search"),
        page,
        queryParams.get("category_id"),
        queryParams.get("filters")
      )
    );
  };

  return (
    <>
      {products && (
        <>
          <ul className="itemList" style={{ margin: 0 }}>
            {products.map((product, index) => {
              return (
                <ProductListBlock
                  product={product}
                  indexKey={index}
                  key={index}
                />
              );
            })}
          </ul>
          <Divider />
          {allProds && (
            <Row justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Pagination
                  defaultCurrent={allProds.pageNo}
                  pageSize={20}
                  total={categoryId === "featured" ? 1 : allProds.allRecord}
                  showSizeChanger={false}
                  onChange={onChange}
                  disabled={products.length < 20 && allProds.allRecord === 0}
                  responsive
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

ProductListing.propTypes = {
  products: PropTypes.array,
};

export default ProductListing;

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

// Reducers
import userReducer from './User/reducer';
import categoryReducer from './Category/reducer';
import productsReducer from './Products/reducer';
import cartReducer from './Cart/reducer';
import homeCarouselReducer from './HomeCarousel/reducer';
import addressReducer from './Address/reducer';
import contactReducer from './Contact/reducer';
import paymentReducer from './Payments/reducer';
import resultReducer from './Result/reducer';
import forgotPasswordReducer from "./ForgotPassword/reducer";
import manageProfileReducer from './ManageProfile/reducer'
import orderReducer from "./OrderHistory/reducer";
import changePasswordReducer from "./ChangePassword/reducer";
import DealOfTheWeekReducer from "./DealOfTheWeek/reducer";
import sendProductEmailReducer from "./SendProductEmail/reducer";
import feedbackReducer from "./Feedback/reducer";
import allReviewReducer from "./AllReviews/reducer";

const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
    user: userReducer,
    category: categoryReducer,
    products: productsReducer,
    cart: cartReducer,
    homeCarousel: homeCarouselReducer,
    address: addressReducer,
    contactUs: contactReducer,
    payment: paymentReducer,
    results: resultReducer,
    forgotPassword: forgotPasswordReducer,
    manageProfile: manageProfileReducer,
    order: orderReducer,
    changePassword: changePasswordReducer,
    dealProduct: DealOfTheWeekReducer,
    sendProductEmailReducer: sendProductEmailReducer,
    feedback: feedbackReducer,
    allReviewReducer: allReviewReducer
});

export default persistReducer(persistConfig, rootReducer);
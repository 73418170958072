import {
  GET_CAROUSEL_DATA_REQ,
  GET_CAROUSEL_DATA_SUCCESS,
  GET_CAROUSEL_DATA_ERROR
} from "../../Constants/HomeCarousel";
import axios from "axios";
import * as api from "../../apis/common";

export {
  getCarouselData
}

const getCarouselData = async (dispatch) => {
  try {
    dispatch({ type: GET_CAROUSEL_DATA_REQ });

    const results = await axios.post(api.GRAPHQL_API, {
      query: api.CAROUSEL_DATA
    });

    if (results?.data?.data?.getDynamicSlider?.data) {
      dispatch({ type: GET_CAROUSEL_DATA_SUCCESS, payload: results.data.data.getDynamicSlider.data });
    } else {
      dispatch({ type: GET_CAROUSEL_DATA_ERROR, payload: results.data.errors });
    }
  } catch (e) {
    dispatch({ type: GET_CAROUSEL_DATA_ERROR, payload: e });
  }
};

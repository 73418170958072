import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Card, Typography } from 'antd';
import { convertToPriceVal } from '../../utils';

import "./productItemBlock.css";

const { Paragraph } = Typography;

const ProductItemBlock = (props) => {
  const { id, getImage, name, price } = props.product;
  const masterImage = getImage.filter((fImage) => fImage.image_type === '1');

  const featuredPriceDescription = () => {
    if (props.product.price_status === "1") {
      return props.product.price_description
    } else {
      return (
        <div className="currencyINR item-price featuredProductPrice">
          {convertToPriceVal(price)}
        </div>
      )
    }
  }

  return (
    <div style={{ padding: "10px" }}>
      <Link to={`/product-details/${encodeURI(name.toLowerCase())}/${id}`}>
        <Card
          hoverable
          style={{ width: "100%", borderRadius: "3%", paddingBottom: 0 }}
          cover={<img alt="f-product" className="f-product" src={masterImage[0].image_url} />}
        >
          <Paragraph strong ellipsis={{ rows: 2 }} className="item-name">{name}</Paragraph>
          <Paragraph strong className="item-price">{featuredPriceDescription()}</Paragraph>
        </Card>
      </Link>
    </div>
  );
};

ProductItemBlock.propTypes = {
  id: PropTypes.string,
  getImage: PropTypes.array,
  name: PropTypes.string,
  price: PropTypes.string,
  product: PropTypes.object
};

export default ProductItemBlock;
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Skeleton } from 'antd';

const DummyProductListBlock = (props) => {
    return (
        <div style={{ width: "100%" }} key={props.indexKey}>
            <li className="itemListBlock">
                <div className="itemListContainer">
                    <Row justify="space-around">
                        <Col
                            xs={24}
                            md={24}
                            lg={8}
                            xl={8}
                            xxl={6}
                            style={{ textAlign: "center" }}
                        >
                            <img
                                src='images/dummy-image-square.jpg'
                                alt="dummy-img"
                                className="itemListImg"
                            />
                        </Col>
                        <Col
                            xs={24}
                            md={24}
                            lg={15}
                            xl={15}
                            xxl={17}
                            className="prod-block-info-section"
                        >
                            <Skeleton loading={true} active paragraph={{ rows: 4 }}></Skeleton>
                        </Col>
                    </Row>
                </div>
            </li>
        </div>
    )
}

DummyProductListBlock.propTypes = {
    indexKey: PropTypes.number
};

export default DummyProductListBlock
import {
  SET_USER_DETAILS_REQ,
  SET_USER_DETAILS_SUCCESS,
  SET_USER_DETAILS_ERROR,
  CREATE_USER_REQ,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SET_UPDATED_USER_DETAILS,
  RESET_TO_INTIAL_STATE,
  LOGOUT_REQ,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  VERIFY_TOKEN_ERROR
} from "../../Constants/User";

const initialState = {
  userDetails: {},
  isLoading: false,
  error: {},
  createUserLoading: false,
  createUserResponse: {},

  logoutLoading: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS_REQ:
      return {
        ...state,
        isLoading: true,
        error: {}
      };
    case SET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        isLoading: false,
        error: {}
      };
    case SET_USER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case CREATE_USER_REQ:
      return {
        ...state,
        createUserLoading: true,
        error: {}
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserResponse: action.payload.createUser,
        createUserLoading: false,
        error: {}
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        createUserLoading: false,
      };
    case RESET_TO_INTIAL_STATE:
      return {
        ...state,
        createUserLoading: false,
        createUserResponse: {},
        error: {}
      };
    case SET_UPDATED_USER_DETAILS:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload }
      }
    case LOGOUT_REQ:
      return {
        ...state,
        logoutLoading: true
      }
    case LOGOUT_SUCCESS:
    case LOGOUT_ERROR:
      return {
        ...state,
        logoutLoading: false
      }
    case VERIFY_TOKEN_ERROR:
      return {
        ...state,
        userDetails: action.payload
      }
    default:
      return state;
  }
};

export default userReducer;

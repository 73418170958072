import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { Skeleton } from "antd";

const styles = {
  card: {
    margin: "0 20px"
  },
  media: {
    height: 140
  }
};

function MediaCard(props) {
  const { classes, name, price, getImage, id, loading = false } = props;

  function getFeaturedImage(images) {
    let fImage = images.filter(img => img.image_type === '1');
    return fImage[0].image_url;
  }

  return (
    <>
      {
        loading ? (
          <div style={{ padding: "0 10px" }}>
            <CardActionArea style={{ border: "1px solid #ededed", padding: "10px" }}>
              <Skeleton active paragraph={{ rows: 5 }} />
            </CardActionArea>
          </div>
        ) : (
          <Link to={`/product-details/${encodeURI(name.toLowerCase())}/${id}`}>
            <div style={{ padding: "0 10px" }}>
              <CardActionArea style={{ border: "1px solid #ededed", padding: "10px" }}>
                <CardMedia className={classes.media} image={getFeaturedImage(getImage)} title={name} />
                <CardContent>
                  <Typography component="p" align='center' className="prod-title">{name}</Typography>
                <Typography component="p" align='center' className="currencyINR">{price}</Typography>
              </CardContent>
            </CardActionArea>
          </div>
          </Link>
  )
}
    </>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string,
  price: PropTypes.string,
  getImage: PropTypes.array,
  id: PropTypes.number,
  loading: PropTypes.bool
};

export default withStyles(styles)(MediaCard);
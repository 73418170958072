import {
    DEAL_DATA_REQ,
    DEAL_DATA_SUCCESS,
    DEAL_DATA_ERROR
} from '../../Constants/DealOfTheWeek';

const initialState = {
    dealLoading: false,
    dealResp: []
}

const DealOfTheWeekReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEAL_DATA_REQ:
            return {
                ...state,
                dealLoading: true,
                dealResp: []
            }
        case DEAL_DATA_SUCCESS:
            return {
                ...state,
                dealLoading: false,
                dealResp: action.payload
            }
        case DEAL_DATA_ERROR:
            return {
                ...state,
                dealLoading: false,
                dealResp: action.payload
            }
        default:
            return state;
    }
}

export default DealOfTheWeekReducer;
const Routes = {
  //pages
  Home: { path: "/" },
  AboutUs: { path: "/about" },
  Contact: { path: "/contact" },
  Login: { path: "/login" },
  Password: { path: "/password" },
  Register: { path: "/register" },
  Error: { path: "/404" },
  ProductDetails: { path: "/product-details/:categoryName/:id" },
  CategoryWiseProductList: { path: "/product-list" },
  Cart: { path: "/cart" },
  Address: { path: "/address" },
  Payment: { path: "/payment" },
  Terms: { path: "/terms" },
  Result: { path: "/result" },
  Policy: { path: "/privacy-policy" },
  YourAccount: { path: "/your-account" },
  OrderHistory: { path: "/your-account/order-history" },
  ChangePassword: { path: "/change-password" },
  ManageProfile: { path: "/manage-profile" },
  AllProducts: { path: "/all-products" },
  Shipping: { path: "/shipping" },
  ThankYou: { path: "/thankYou" },
  Feedback: { path: "/feedback" },
  AllReviews: { path: "/all-reviews" },

  // components
  Header: { path: "./components/Header/Header" },
  Footer: { path: "./components/Footer/Footer" }
};

export default Routes;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import lookup from "./footer.json";
import "./footer.css";

import parse from "html-react-parser";

const Footer = () => {
  const [activeIndex, setActiveIndex] = useState({ i: 0, openNav: false });

  const handleNavigation = (activeI, activeStatus) => {
    if (activeI === activeIndex.i) {
      setActiveIndex({ i: 0, openNav: false });
    } else {
      setActiveIndex({ i: activeI, openNav: activeStatus });
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footerContent">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <a href="/" className="footerLogo" title="">
                <img
                  src="/images/new-eagle-logo-black-font.svg"
                  alt="logo-eagle-gooter"
                  className="alignleft"
                />
              </a>
              <div className="socialBlock">
                <div className="socialSites">
                  <h4>Follow us</h4>
                  {lookup.socials.map((social, socialIndex) => {
                    return (
                      <a
                        href={social.link}
                        title={social.name}
                        target="_blank"
                        rel="noreferrer"
                        key={socialIndex}
                      >
                        <i className={social.class}></i>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-12">
              <h4
                className="footerHeading"
                onClick={() => handleNavigation(1, true)}
              >
                Navigate
                <span>
                  <i className="fa fa-chevron-down"></i>
                </span>
              </h4>
              <ul className={`footer-menu ${activeIndex.i === 1 && "active"}`}>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">Search</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-sm-12">
              <h4
                className="footerHeading"
                onClick={() => handleNavigation(2, true)}
              >
                Support
                <span>
                  <i className="fa fa-chevron-down"></i>
                </span>
              </h4>
              <ul className={`footer-menu ${activeIndex.i === 2 && "active"}`}>
                {lookup.navigations.support.map((supp, supportIndex) => {
                  return (
                    <li key={supportIndex}>
                      <a href={supp.link} title={supp.pageName}>
                        {supp.pageName}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-3 col-sm-12">
              <h4
                className="footerHeading"
                onClick={() => handleNavigation(3, true)}
              >
                Contact us
                <span>
                  <i className="fa fa-chevron-down"></i>
                </span>
              </h4>
              <ul className={`footer-menu ${activeIndex.i === 3 && "active"}`}>
                <li className="addressList">
                  <i className="fas fa-phone-alt pr-5"></i>
                  {parse(
                    `UK Tel: ${process.env.REACT_APP_UK_PHONE}<br/>USA Tel: ${process.env.REACT_APP_US_PHONE}`
                  )}
                </li>
                <li className="addressList">
                  <i className="fas fa-envelope pr-5"></i>
                  <a
                    className="headerEmai"
                    href="mailto:info@eagleworksolutions.com"
                  >
                    info@eagleworksolutions.com
                  </a>
                </li>
                <li className="addressList">
                  <i className="fas fa-map-marker-alt pr-5"></i>
                  {`Suite 4a, 2nd Floor, Regency House, George Street, Luton, England, LU1 2RT`}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className=" text-center">
        <div className="copyRightTxt">{lookup.copyRightText}</div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  Spin,
  Modal,
  Card,
  Divider,
} from "antd";

import "./manageProfile.css";

// actions
import {
  manageProfileReq,
  updateManageProfileReq,
  resetUpdateProfile,
} from "../../Actions/ManageProfile/action";

const { Title } = Typography;

const ManageProfile = () => {
  const [fields, setFields] = useState({});

  const userToken = useSelector((state) => state.user?.userDetails?.api_token);
  const manageProfileState = useSelector((state) => state.manageProfile);
  const { manageProfileData, manageProfileLoading, updateProfileResp } =
    manageProfileState;

  const [form] = Form.useForm();
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userToken) {
      history.push("/");
      return;
    }
    dispatch(manageProfileReq(userToken));
  }, []);

  useEffect(() => {
    if (manageProfileState?.manageProfileData?.data?.name) {
      const newData = {
        name: manageProfileData?.data?.name,
        last_name: manageProfileData?.data?.last_name,
        phone: manageProfileData?.data?.phone,
        postal_code: manageProfileData?.data?.postal_code,
      };
      setFields(newData);
    }
  }, [manageProfileData, dispatch]);

  const handleSubmit = (values) => {
    dispatch(updateManageProfileReq(values, userToken));
  };

  const onModalOk = () => {
    dispatch(resetUpdateProfile());
  };

  function success() {
    Modal.success({
      title: "Success",
      content: updateProfileResp?.message,
      onOk() {
        onModalOk();
      },
    });
  }

  function error() {
    Modal.error({
      title: "Error",
      content: updateProfileResp?.message,
      onOk() {
        onModalOk();
      },
    });
  }

  return (
    <div className="container mw-700">
      <Card>
        <Title level={3}>Manage or update your Profile</Title>
        <Divider />
        {Object.keys(fields).length === 0 ? (
          <Spin style={{ textAlign: "center", display: "block" }} />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={(e) => {
              handleSubmit(e);
            }}
          >
            <Spin spinning={manageProfileLoading}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  { required: true, message: "Please enter your First Name" },
                ]}
                initialValue={fields.name}
              >
                <Input size="middle" placeholder="First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Please enter your Last Name" },
                ]}
                initialValue={fields.last_name}
              >
                <Input size="middle" placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  { required: true, message: "Please enter your phone number" },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Value should contain just number",
                  },
                  {
                    max: 13,
                    message: "Value should be less than 13 character",
                  },
                  {
                    min: 10,
                    message: "Value should be more than 10 character",
                  },
                ]}
                initialValue={fields.phone}
                onKeyPress={(e) => {
                  if (!/[0-9-+()/]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              >
                <Input
                  size="middle"
                  placeholder="Phone"
                  onKeyPress={(e) => {
                    if (!/[0-9()-/+]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Postal Code"
                name="postal_code"
                rules={[
                  { required: true, message: "Please enter a postal code" },
                  {
                    max: 10,
                    message: "Value should be less than 10 character",
                  },
                ]}
                initialValue={fields.postal_code}
              >
                <Input size="middle" placeholder="Postal Code" maxLength={6} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" block htmlType="submit">
                  continue
                </Button>
              </Form.Item>
            </Spin>
          </Form>
        )}
      </Card>
      {updateProfileResp?.success === "1"
        ? success()
        : updateProfileResp?.success === "" && error()}
    </div>
  );
};

export default ManageProfile;

export const GET_ALL_PRODUCTS_REQ = "GET_ALL_PRODUCTS_REQ";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_ERROR = "GET_ALL_PRODUCTS_ERROR";

export const GET_PRODUCT_BY_ID_REQ = "GET_PRODUCT_BY_ID_REQ";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_ERROR = "GET_PRODUCT_BY_ID_ERROR";

export const FETCH_ALL_FEATURED_PRODUCTS_REQ = "FETCH_ALL_FEATURED_PRODUCTS_REQ";
export const FETCH_ALL_FEATURED_PRODUCTS_SUCCESS = "FETCH_ALL_FEATURED_PRODUCTS_SUCCESS";
export const FETCH_ALL_FEATURED_PRODUCTS_ERROR = "FETCH_ALL_FEATURED_PRODUCTS_ERROR";

export const BUY_BULK_REQ = "BUY_BULK_REQ";
export const BUY_BULK_SUCCESS = "BUY_BULK_SUCCESS";
export const BUY_BULK_ERROR = "BUY_BULK_ERROR";

export const ALL_PRODS_REQ = "ALL_PRODS_REQ";
export const ALL_PRODS_SUCCESS = "ALL_PRODS_SUCCESS";
export const ALL_PRODS_ERROR = "ALL_PRODS_ERROR";
import { each } from "lodash";

export const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);

export const validPhone = new RegExp("^[0-9]{10}$");

export const validName = new RegExp("^[a-zA-Z ]+$");

export const createActionTypes = (prefix, actionTypeList) => {
  const actionTypesObject = {};

  each(actionTypeList, (item) => {
    actionTypesObject[item] = `${prefix}/${item}`;
  });

  return actionTypesObject;
};

export const shuffleArr = (arr, arrSize) => {
  let newArr = [];
  if (arr.length) {
    const shuffled = arr.sort(() => Math.random() - Math.random()).slice(0, arrSize);
    newArr = shuffled;
  }
  return newArr;
};

export const convertToPriceVal = (val) => {
  return (Number(val)).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

export const calculateItemsData = (items) => {
  let totalQuantity = 0;
  let priceBeforeTax = 0;
  let priceAfterTax = 0;

  for (let i = 0; i < items.length; i++) {
    totalQuantity += Number(items[i].product_quantity);
    const priceWithTax = (Number(items[i].product_price) * Number(items[i].product_quantity));
    priceBeforeTax += priceWithTax;
    priceAfterTax += priceWithTax + (priceWithTax * Number(items[i].tax) / 100);
  }

  return { qty: totalQuantity, priceBeforeTax, priceAfterTax }
}

import React from "react";
import PropTypes from 'prop-types';
import { Spin } from "antd";

const Loading = ({ size = "middle", loadingText = "" }) => {
  return <Spin size={size} tip={loadingText} style={{ width: "100%" }} />;
};

Loading.propTypes = {
  size: PropTypes.string,
  loadingText: PropTypes.string,
};

export default Loading;

import {
  GET_MERCHANT_SESSION_KEYS_REQ,
  GET_MERCHANT_SESSION_KEYS_SUCCESS,
  GET_MERCHANT_SESSION_KEYS_ERROR,
  CARD_IDENTIFIER_REQ,
  CARD_IDENTIFIER_SUCCESS,
  CARD_IDENTIFIER_ERROR,
  SAVE_PAYMENT_DETAILS
} from "../../Constants/Payments";

let initialState = {
  getSessionKeysLoading: false,
  sessionData: {},
  sessionError: {},

  cardIdentifierLoading: false,
  cardIdentifierResponse: {},
  cardIdentifierError: {},

  lastOrderId: "",
  addressId: ""
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MERCHANT_SESSION_KEYS_REQ:
      return {
        ...state,
        getSessionKeysLoading: true,
        cardIdentifierError: {},
        cardIdentifierLoading: false,
        cardIdentifierResponse: {}
      };
    case GET_MERCHANT_SESSION_KEYS_SUCCESS:
      return {
        ...state,
        getSessionKeysLoading: false,
        sessionData: action.payload,
        sessionError: {}
      };
    case GET_MERCHANT_SESSION_KEYS_ERROR:
      return {
        ...state,
        sessionError: action.payload
      };
    case CARD_IDENTIFIER_REQ:
      return {
        ...state,
        cardIdentifierLoading: true,
        cardIdentifierResponse: {},
        cardIdentifierError: {}
      };
    case CARD_IDENTIFIER_SUCCESS:
      return {
        ...state,
        cardIdentifierLoading: false,
        cardIdentifierResponse: action.payload,
        cardIdentifierError: {}
      };
    case CARD_IDENTIFIER_ERROR:
      return {
        ...state,
        cardIdentifierLoading: false,
        cardIdentifierError: action.payload
      };
      case SAVE_PAYMENT_DETAILS:
        return {
          ...state,
          lastOrderId: action.payload.orderID,
          addressId: action.payload.addressId
        }
    default:
      return state;
  }
};

export default paymentReducer;

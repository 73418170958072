import axios from "axios";
import * as api from "../../apis/common";
import {
  GET_CATEGORY_BY_ID_REQ,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_ERROR,
  GET_ALL_CATEGORIES_REQ,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
  RESET_CATEGORY_SEARCH,
  RESET_CATEGORY_SEARCH_SUCCESS,
  RESET_CATEGORY_SEARCH_ERROR,
  SET_CURRENT_CATEGORY_REQ,
  SET_CURRENT_CATEGORY_SUCCESS,
  SET_CURRENT_CATEGORY_ERROR
} from "../../Constants/Category";

export {
  getCategoryByIdReq,
  getAllCategoriesReq,
  resetCategorySearch,
  setCurrentCategory
}

function getCategoryByIdReq(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CATEGORY_BY_ID_REQ });
      const response = await axios.post(api.GRAPHQL_API, {
        query: `{
            category(id:${id}) {
                  success
                  statusCode
                  message
                  data{
                    name
                    description
                    id
                  }
                }
          }`
      });
      dispatch({
        type: GET_CATEGORY_BY_ID_SUCCESS,
        payload: response.data.data.category.data
      });
    } catch (error) {
      dispatch({ type: GET_CATEGORY_BY_ID_ERROR, payload: error });
    }
  };
}

const getAllCategoriesReq = async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CATEGORIES_REQ });

    const response = await axios.post(api.GRAPHQL_API, {
      query: api.GET_CATEGORIES
    });

    dispatch({
      type: GET_ALL_CATEGORIES_SUCCESS,
      payload: response?.data?.data?.categories?.data
    });
  } catch (error) {
    dispatch({ type: GET_ALL_CATEGORIES_ERROR, payload: error });
  }
};

const resetCategorySearch = async (dispatch) => {
  try {
    dispatch({ type: RESET_CATEGORY_SEARCH });
    dispatch({ type: RESET_CATEGORY_SEARCH_SUCCESS, payload: true });
  } catch (e) {
    dispatch({ type: RESET_CATEGORY_SEARCH_ERROR });
  }
};

const setCurrentCategory = (category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CURRENT_CATEGORY_REQ, payload: category });
      await dispatch({ type: SET_CURRENT_CATEGORY_SUCCESS });
    } catch (e) {
      dispatch({ type: SET_CURRENT_CATEGORY_ERROR });
    }
  }
}

import React, { useState } from "react";
import { Typography, Row, Spin, Card, Col, Form, Input, Button, Divider, Modal } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import { useHistory } from 'react-router-dom';

import "./feedback.css"

import { feedback, resetInitialState } from "../../Actions/Feedback";

const { Title } = Typography;
const { TextArea } = Input;

const Feedback = () => {

    const [form] = Form.useForm();
    const userState = useSelector((state) => state.user);
    const feedBackState = useSelector((state) => state.feedback);
    const { feedBackResponse, feedBackLoading } = feedBackState;
    const initialCount = 0;
    var [starRating, setStarRating] = useState(initialCount)

    const dispatch = useDispatch();
    const history = useHistory();

    const onModalOk = () => {
        dispatch(resetInitialState()); onReset();
    }

    var ratingChanged = (newRating) => {
        setStarRating(newRating)
    };

    function onFinish(values) {
        if (userState?.userDetails?.api_token) {
            dispatch(feedback(starRating, values, userState?.userDetails?.api_token));
        } else {
            Modal.error({
                title: "Currently you are not loged in. Please first loged in",
                onOk() { onModalOk() }
            })
        }
    }

    function refreshPage() {
        history.push('/all-reviews');
    }

    function success() {
        Modal.success({
            title: "Success",
            content: "Your feedback send successfully!",
            onOk() { onModalOk(refreshPage()) }
        });
    }

    function error() {
        Modal.error({
            title: "Error",
            content: feedBackResponse?.message,
            onOk() { onModalOk() }
        });
    }

    const onReset = () => {
        form.resetFields();
    };

    return (
        <div className="container feedbackContainer">
            <Card className="feedbackCard">
                <Row justify="space-around">
                    <Title level={3}>Feedback Form</Title>
                </Row>
                <Divider />
                <Row >
                    <Col span={12} xs={24} md={20} xl={20} xxl={20} >
                        <Spin spinning={feedBackLoading} tip="Kindly Wait, Your Request Is Under Process">
                            <Form onFinish={onFinish} form={form} layout='vertical' className="feedback-form">
                                <Form.Item>
                                    <div>
                                        <h4>Overall heading</h4>
                                        <ReactStars
                                            count={5}
                                            onChange={ratingChanged}
                                            size={24}
                                            activeColor="#ffd700"
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="product_code"
                                    className="contact-form-label"
                                >
                                    <div>
                                        <h4>Add product code</h4>
                                        <Input type="text" placeholder="product_code" />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="comment"
                                    className="contact-form-label"
                                    rules={[
                                        { required: true, message: "Please add a review*" }
                                    ]}>
                                    <div>
                                        <h4>Add a written review</h4>
                                        <TextArea type="text " rows={4} placeholder="Review*" />
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <Button className="btn brandBtn" type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Card>
            {
                !feedBackLoading && feedBackResponse?.success === "1" && success()
            }
            {
                !feedBackLoading && feedBackResponse?.success === "" && error()
            }
        </div>
    );
};

export default Feedback;

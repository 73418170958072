import {
  GET_ALL_PRODUCTS_REQ,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_ERROR,
  FETCH_ALL_FEATURED_PRODUCTS_REQ,
  FETCH_ALL_FEATURED_PRODUCTS_SUCCESS,
  FETCH_ALL_FEATURED_PRODUCTS_ERROR,
  GET_PRODUCT_BY_ID_REQ,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_ERROR,
  BUY_BULK_REQ,
  BUY_BULK_SUCCESS,
  BUY_BULK_ERROR,
  ALL_PRODS_REQ,
  ALL_PRODS_SUCCESS,
  ALL_PRODS_ERROR
} from "../../Constants/Products";

let initialState = {
  isLoading: false,
  allProducts: [],

  isProductByIdLoading: false,
  productDetail: {},

  featuredProductsLoading: false,
  featuredProducts: [],
  featuredProductsError: {},

  buyBulkLoading: false,

  allProdsLoading: false,
  allProdsData: []
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQ:
      return {
        ...state,
        isLoading: true,
        allProducts: []
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allProducts: action.payload
      };
    case GET_ALL_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
        allProducts: []
      };
    case GET_PRODUCT_BY_ID_REQ:
      return {
        ...state,
        isProductByIdLoading: true,
        productDetail: {}
      };
    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isProductByIdLoading: false,
        productDetail: action.payload
      };
    case GET_PRODUCT_BY_ID_ERROR:
      return {
        ...state,
        isProductByIdLoading: false,
        productDetail: action.payload
      };
    case FETCH_ALL_FEATURED_PRODUCTS_REQ:
      return {
        ...state,
        featuredProductsLoading: true,
        featuredProductsError: "",
        featuredProducts: []
      };
    case FETCH_ALL_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        featuredProductsLoading: false,
        featuredProducts: action.payload,
        featuredProductsError: ""
      };
    case FETCH_ALL_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        featuredProductsLoading: false,
        featuredProductsError: action.payload
      };
    case BUY_BULK_REQ:
      return {
        ...state,
        buyBulkLoading: true
      };
    case BUY_BULK_SUCCESS:
    case BUY_BULK_ERROR:
      return {
        ...state,
        buyBulkLoading: false
      }
    case ALL_PRODS_REQ:
      return {
        ...state,
        allProdsLoading: true
      }
    case ALL_PRODS_SUCCESS:
    case ALL_PRODS_ERROR:
      return {
        ...state,
        allProdsLoading: false,
        allProdsData: action.payload
      }
    default:
      return state;
  }
};

export default productsReducer;

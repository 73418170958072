import axios from "axios";
import {
  GET_ADDRESS_LIST_REQ,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_ERROR,
  CREATE_ADDRESS_REQ,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERROR,
  UPDATE_ADDRESS_REQ,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERROR,
  DELETE_ADDRESS_REQ,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR
} from "../../Constants/Address";
import * as api from "../../apis/common";

export  {
  getAddressList,
  createAddress,
  updateAddress,
  deleteAddress
};

const getAddressList = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ADDRESS_LIST_REQ });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `{
            userAddressByAuth{
                success
                statusCode
                message
                data{
                  id
                  full_name
                  phone
                  address
                  landmark
                  city
                  state
                  postal_code
                  country
                  location_type
                  address_type
                  is_primary       
                }
              allRecord
             }
          }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (result?.data?.data?.userAddressByAuth?.statusCode == 200) {
        dispatch({
          type: GET_ADDRESS_LIST_SUCCESS,
          payload: result.data.data.userAddressByAuth.data
        });
      } else {
        dispatch({
          type: GET_ADDRESS_LIST_ERROR,
          payload: result.data
        });
      }
    } catch (e) {
      dispatch({
        type: GET_ADDRESS_LIST_ERROR,
        payload: e
      });
    }
  };
};

const createAddress = (address, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_ADDRESS_REQ });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
            createUserAddress(address: ${JSON.stringify(address).replace(
            /"(\w+)"\s*:/g,
            "$1:"
            )}){
                success
                statusCode
                message
                data{
                  id
                  user_id
                  full_name
                  phone
                  address
                  landmark
                  city
                  state
                  postal_code
                  country
                  location_type
                  address_type
                  is_primary
                }
              allRecord
            }
          }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (result?.data?.data?.createUserAddress?.statusCode == "200") {
        dispatch({
          type: CREATE_ADDRESS_SUCCESS,
          payload: result?.data?.data?.createUserAddress
        });
      } else {
        dispatch({
          type: CREATE_ADDRESS_ERROR,
          payload: result?.data?.data?.createUserAddress
        });
      }
    } catch (e) {
      dispatch({ type: CREATE_ADDRESS_ERROR, payload: e });
    }
  };
};

const updateAddress = (address, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ADDRESS_REQ });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
            createUserAddress(address: ${JSON.stringify(address).replace(
            /"(\w+)"\s*:/g,
            "$1:"
          )}){
                success
                statusCode
                message
                data{
                  id
                  user_id
                  full_name
                  phone
                  address
                  landmark
                  city
                  state
                  postal_code
                  country
                  location_type
                  address_type
                  is_primary
                }
              allRecord
            }
          }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (result?.data?.data?.createUserAddress?.statusCode == "200") {
        dispatch({
          type: UPDATE_ADDRESS_SUCCESS,
          payload: result.data.data.createUserAddress.data
        });
      } else {
        dispatch({
          type: UPDATE_ADDRESS_ERROR,
          payload: result?.data?.data?.createUserAddress
        });
      }
    } catch (e) {
      dispatch({ type: UPDATE_ADDRESS_ERROR, payload: e });
    }
  };
};

const deleteAddress = (addressId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ADDRESS_REQ, payload: addressId });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
            deleteUserAddress(id:${addressId}){
                success
                statusCode
                message
            }
          }`
        },
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );
      if (result?.data?.data?.deleteUserAddress) {
        dispatch({
          type: DELETE_ADDRESS_SUCCESS,
          payload: { data: result.data.data.deleteUserAddress, addressId }
        });
      } else {
        dispatch({ type: DELETE_ADDRESS_ERROR, payload: result });
      }
    } catch (e) {
      dispatch({ type: DELETE_ADDRESS_ERROR, payload: e });
    }
  };
};

import {
    DEAL_DATA_REQ,
    DEAL_DATA_SUCCESS,
    DEAL_DATA_ERROR
} from '../../Constants/DealOfTheWeek';
import axios from "axios";
import * as api from "../../apis/common";

export {
    dealData
}

const dealData = async (dispatch) => {
    try {
        dispatch({ type: DEAL_DATA_REQ });

        const response = await axios.post(api.GRAPHQL_API, {
            query: `{
                dealOfTheDayproduct{
                    success
                    statusCode
                    data{
                    id
                    title
                    actual_price
                    discounted_price
                    description
                    product_page_url
                    product_image_url
                    from_date
                    to_date
                    product_id
                    }   
                }
            }`
        });

        if (response?.data?.data?.dealOfTheDayproduct) {
            dispatch({ type: DEAL_DATA_SUCCESS, payload: response.data.data.dealOfTheDayproduct.data });
        } else {
            dispatch({ type: DEAL_DATA_ERROR, payload: {} });
        }
    } catch (error) {
        dispatch({ type: DEAL_DATA_ERROR, payload: error });
    }
}
import React from 'react';

// components

const ShippingModal = () => {
    return (
        <div>
            <b><p style={{ textDecoration: "underline" }}>Free Shipping over &#163;200 in UK Mains</p></b>
        </div>
    )
}

export default ShippingModal

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form, Input, Typography, Divider, Button, Spin, Modal, Row, Col, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import "./buyBulk.css";

// actions
import { buyBulkReq, searchProducts } from "../../Actions/Products/actions";

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

const BuyBulk = (props) => {
  const [form] = Form.useForm();
  // states
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [withSignup, setWithSignup] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);

  const dispatch = useDispatch();

  const userr = useSelector((state) => state.user);
  const productState = useSelector((state) => state.products);
  const { buyBulkLoading } = productState;

  useEffect(() => {
    if (userr?.userDetails?.api_token) {
      setUserLoggedIn(true);
    }
  }, []);

  const cbFunction = (resp) => {
    props.func("", false);
    if (resp.success === "1") {
      return Modal.success({
        content: resp.message,
      });
    } else {
      return Modal.error({
        content: resp.message,
      });
    }
  }

  const handleSubmitRequest = (fields) => {
    fields.bulkProducts = fields.bulkProducts ? fields.bulkProducts : [];
    let firstProd = {};
    firstProd.product_id = props.prodId;
    firstProd.product_quantity = fields.product_quantity;
    delete fields.product_id;
    delete fields.product_quantity;

    fields.bulkProducts.unshift(firstProd);

    if (userLoggedIn) {
      dispatch(buyBulkReq(
        {
          ...fields,
          name: (userr?.userDetails.name + " " + userr?.userDetails.last_name).trim(),
          phone: userr?.userDetails.phone,
          signup: false
        },
        userr?.userDetails?.api_token,
        cbFunction
      ));
    } else {
      dispatch(buyBulkReq({ ...fields }, userr?.userDetails?.api_token, cbFunction));
    }
  };

  const onChangeCheckbox = (e) => {
    setWithSignup(e.target.checked)
  }

  const searchCallbackFunc = (opsObj) => {
    setSearchLoading(opsObj.loading);
    setSearchOptions(opsObj.data);
  }

  const debounceHandler = (cb, d) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, d)
    }
  };

  const handleSearchProds = debounceHandler((inp) => {
    if (inp === "") {
      setSearchOptions([]);
    } else {
      dispatch(searchProducts(inp, searchCallbackFunc));
    }
  }, 500);

  return (
    <div>
      <Title level={3}>Buy Bulk</Title>
      <Divider />
      <Spin spinning={buyBulkLoading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmitRequest}
        >
          {
            userLoggedIn && (
              <Form.Item
                name="email"
                label="Email"
                initialValue={userr?.userDetails?.email}
                rules={[{ required: true, message: 'Please input email address' }]}
              >
                <Input />
              </Form.Item>
            )
          }
          {
            !userLoggedIn &&
            <>
              <Form.Item
                name="name"
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your full name',
                  },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email',
                  },
                  {
                    type: "email",
                    message: 'Please input a valid email',
                  }
                ]}>
                <Input />
              </Form.Item>

              {
                withSignup &&
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              }

              <Form.Item
                name="phone"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number',
                  }
                ]}>
                <Input maxLength={15} type="text" onKeyPress={(e) => {
                  if (!/[0-9-+()/]/.test(e.key)) { e.preventDefault() }
                }} />
              </Form.Item>
            </>
          }
          <Row gutter={[4, 0]}>
            <Col span={24}>
            </Col>
            <Col span={12}>
              <Form.Item
                name="product_id"
                label="Product Name"
                initialValue={props.productName}
                rules={[{ required: true, message: 'Please input product ID' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="product_quantity"
                label="Product Quantity"
                rules={[{ required: true, message: 'Please input product quantity' }]}
              >
                <Input type="text" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="bulkProducts">
            {(fields, { add, remove }) => (
              <div className="scrollable">
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={[4, 0]} key={key}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'product_id']}
                        label="Product Name"
                        rules={[{ required: true, message: 'Please input product name' }]}
                      >
                        <Select
                          showSearch
                          placeholder="Search product"
                          optionFilterProp="children"
                          onSearch={(inputValue) => { handleSearchProds(inputValue) }}
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          loading={searchLoading}
                        >
                          {
                            searchOptions.map(opt => {
                              return <Option value={(opt.id).toString()} key={opt.id}>{opt.name}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Row gutter={[2, 0]} align="middle">
                        <Col span={22}>
                          <Form.Item
                            {...restField}
                            name={[name, 'product_quantity']}
                            label="Product Quantity"
                            rules={[{ required: true, message: 'Please input product quantity' }]}
                          >
                            <Input type="text" style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add more products
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
          <Form.Item
            name="message"
            label="Comment"
            initialValue=""
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="no_notification" valuePropName="checked" initialValue={false} style={{ textAlign: "left", marginBottom: 0 }}>
            <Checkbox>Tick here if you don&apos;t want to receive any promotional emails</Checkbox>
          </Form.Item>
          {
            !userLoggedIn &&
            <Form.Item name="signup" valuePropName="checked" initialValue={false} style={{ textAlign: "left" }}>
              <Checkbox onChange={onChangeCheckbox}>Tick here if you want to sign up</Checkbox>
            </Form.Item>
          }
          <Form.Item>
            <Button htmlType="submit" type="primary" block>Submit</Button>
          </Form.Item>
        </Form>
      </Spin>
    </div >
  );
};

BuyBulk.propTypes = {
  props: PropTypes.object,
  func: PropTypes.func,
  prodId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  productName: PropTypes.string
};

export default BuyBulk;

import {
    GET_FORGOT_PASSWORD_REQ,
    GET_FORGOT_PASSWORD_SUCCESS,
    GET_FORGOT_PASSWORD_ERROR,
    RESET_TO_INITIAL_STATE
} from "../../Constants/ForgotPassword";
import axios from "axios";
import * as api from "../../apis/common";

export {
    getForgotPasswordReq,
}

function getForgotPasswordReq(formData) {
    const { email } = formData;
    return async (dispatch) => {
        try {
            dispatch({ type: GET_FORGOT_PASSWORD_REQ });
            const result = await axios.post(
                api.GRAPHQL_API,
                {
                    query: `mutation{
                        forgotPassword(email:"${email}"){
                           success
                             statusCode
                             message
                        }
                      }`

                }
            );
            if (result?.data?.data?.forgotPassword?.success === 1) {
                dispatch({ type: GET_FORGOT_PASSWORD_SUCCESS, payload: result?.data?.data?.forgotPassword });
            } else {
                dispatch({ type: GET_FORGOT_PASSWORD_ERROR, payload: result?.data?.data?.forgotPassword })
            }
        }
        catch (error) {
            dispatch({ type: GET_FORGOT_PASSWORD_ERROR, payload: error })

        }

    }
}

export function resetInitialState() {
    return async (dispatch) => {
        dispatch({ type: RESET_TO_INITIAL_STATE })
    }
}

import React from "react";
import PropTypes from 'prop-types';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const HomeCarousel = ({ data }) => {

  return (
    <div className="carousel-wrapper">
      <Carousel
        infiniteLoop
        autoPlay
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
      >
        {data.map((data) => {
          return (
            <a href={data.url} key={data.id}>
              <div className="pos-rel">
                <img src={data.image} alt="carousel-data" />
              </div>
            </a>
          );
        })}
      </Carousel>
    </div>
  );
};

HomeCarousel.propTypes = {
  data: PropTypes.array
};

export default HomeCarousel;

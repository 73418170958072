import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Typography,
  Form,
  Input,
  Button,
  Card,
  Col,
  Modal,
  Spin,
} from "antd";
import "./Contact.css";

import { contactus, resetInitialState } from "../../Actions/Contact";

const { Text, Title } = Typography;
const { TextArea } = Input;

const Contact = () => {
  const [form] = Form.useForm();
  const contactUsState = useSelector((state) => state.contactUs);
  const { contactUsResponse, contactUsLoading } = contactUsState;

  const dispatch = useDispatch();

  const onModalOk = () => {
    dispatch(resetInitialState());
    onReset();
  };

  const onFinish = (values) => {
    dispatch(contactus(values));
  };

  function success() {
    Modal.success({
      title: "Success",
      content: "Email send successfully. We will reply to you shortly",
      onOk() {
        onModalOk();
      },
    });
  }

  function error() {
    Modal.error({
      title: "Error",
      content: contactUsResponse?.message,
      onOk() {
        onModalOk();
      },
    });
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="container contactUsContainer">
      <Card className="contact-us-card">
        <Row className="contact-us-title" justify="space-around">
          <Col span={12} xs={24} lg={12}>
            <Title level={3}>CONTACT US</Title>
          </Col>
        </Row>
        <Row justify="space-around" style={{ textAlign: "center" }}>
          <Col span={22}>
            <Text className="pageInfo">
              Email us with any questions or inquiries or call (UK: +44 (0) 1582
              292301|USA: +1(209) 236 4462). We would be happy to answer your
              questions and set up a meeting with you. Eagle Work Solutions can
              help set you apart from flock!
            </Text>
          </Col>
        </Row>
        <br />
        <Row justify="space-around">
          <Col span={12} xs={24} md={20} xl={20} xxl={20}>
            <Spin
              spinning={contactUsLoading}
              tip="Kindly Wait, Your Request Is Under Process"
            >
              <Form onFinish={onFinish} form={form} layout="vertical">
                <Form.Item
                  name="name"
                  // label="Name"
                  className="contact-form-label"
                  rules={[
                    { required: true, message: "Please input your name" },
                  ]}
                >
                  <Input
                    onKeyPress={(e) => {
                      if (!/[A-Za-z\s]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    className="contact-form-input"
                    type="text"
                    placeholder="Name*"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  // label="Email"
                  className="contact-form-label"
                  rules={[
                    { required: true, message: "Please input your email" },
                    {
                      type: "email",
                      message: "Please input a valid email address",
                    },
                  ]}
                >
                  <Input type="email" placeholder="Email*" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  // label="Phone"
                  className="contact-form-label"
                  onKeyPress={(e) => {
                    if (!/[0-9()+-/]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Phone*" />
                </Form.Item>
                <Form.Item
                  name="message"
                  // label="Message"
                  className="contact-form-label"
                  rules={[
                    { required: true, message: "Please input the message" },
                  ]}
                >
                  <TextArea type="text " rows={4} placeholder="Message*" />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="btn brandBtn"
                    type="primary"
                    htmlType="submit"
                  >
                    Send Email
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>
      </Card>
      {!contactUsLoading && contactUsResponse?.success === "1" && success()}
      {!contactUsLoading && contactUsResponse?.success === "" && error()}
    </div>
  );
};

export default Contact;

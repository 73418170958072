import {
  SET_USER_DETAILS_REQ,
  SET_USER_DETAILS_SUCCESS,
  SET_USER_DETAILS_ERROR,
  CREATE_USER_REQ,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  RESET_TO_INTIAL_STATE,
  LOGOUT_REQ,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  VERIFY_TOKEN_REQ,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR
} from "../../Constants/User";

import axios from "axios";
import * as api from "../../apis/common";

import { addMultipleProductReq, resetCartItemsReq } from "../Cart/actions";
import { resetUpdateProfile } from '../ManageProfile/action';

export {
  setUserDetailsReq,
  createUserReq,
  resetInitialState,
  logout,
  verifyToken
}

function setUserDetailsReq(obj, localCart, cb) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_USER_DETAILS_REQ });
      if (obj?.username && obj?.password) {
        const resultQuery = await axios.post(api.LOGIN_API, {
          "email": obj.username,
          "password": obj.password
        }
        );

        if (resultQuery?.data?.user_details) {
          cb(resultQuery.data)
          dispatch({
            type: SET_USER_DETAILS_SUCCESS,
            payload: resultQuery.data.user_details
          });

          if (localCart?.length) {
            const newCart = [];
            for (let i = 0; i < localCart.length; i++) {
              delete localCart[i].product_price;
              delete localCart[i].productStock;
              delete localCart[i].tax;
              newCart.push(localCart[i]);
            }
            dispatch(resetCartItemsReq);
            dispatch(
              addMultipleProductReq(
                newCart,
                resultQuery.data.token,
                cb
              )
            );
          }
        } else {
          dispatch({ type: SET_USER_DETAILS_ERROR, payload: resultQuery.data });
        }
      } else {
        dispatch({ type: SET_USER_DETAILS_SUCCESS, payload: {} });
        dispatch(resetUpdateProfile());
        dispatch(resetCartItemsReq);
      }
    } catch (error) {
      dispatch({ type: SET_USER_DETAILS_ERROR, payload: error });
    }
  };
}

function createUserReq(obj) {
  const { firstName, lastName, phone, email, password, postal } = obj;
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_USER_REQ });

      const result = await axios.post(api.GRAPHQL_API, {
        query: `mutation{
          createUser(
            first_name:"${firstName}",
            last_name:"${lastName}"
            phone:"${phone}",
            email:"${email}",
            password:"${password}",
            postal_code:"${postal}"){
              success
              statusCode
              message
              data{
                id
                name
                email
              }
          }
        }`
      });

      if (result.data) {
        dispatch({ type: CREATE_USER_SUCCESS, payload: result.data.data });
      } else {
        dispatch({ type: CREATE_USER_ERROR, payload: result.data.data });
      }
    } catch (error) {
      dispatch({ type: CREATE_USER_ERROR, payload: error });
    }
  };
}

function resetInitialState() {
  return async (dispatch) => {
    dispatch({ type: RESET_TO_INTIAL_STATE })
  }
}

function logout(token, cb) {
  return async (dispatch) => {
    dispatch({ type: LOGOUT_REQ })
    const resultQuery = await axios.get(api.LOGOUT_API, {
      headers: {
        "Authorization": "Bearer " + token
      }
    }
    );
    if (resultQuery?.data?.success) {
      cb(resultQuery.data);
      dispatch({ type: LOGOUT_SUCCESS, payload: resultQuery.data });
      dispatch(setUserDetailsReq({}));
    } else {
      dispatch({ type: LOGOUT_ERROR, payload: {} });
    }
  }
}

function verifyToken(token) {
  return async (dispatch) => {
    dispatch({ type: VERIFY_TOKEN_REQ })
    const resultQuery = await axios.post(api.GRAPHQL_API, {
      query: `mutation{
          validateToken(token : "${token}"){
            success
            message
            statusCode
          }
        }`
    });
    if (resultQuery?.data?.data?.validateToken?.success) {
      dispatch({ type: VERIFY_TOKEN_SUCCESS });
    } else {
      dispatch({ type: VERIFY_TOKEN_ERROR, payload: {} });
    }
  }
}

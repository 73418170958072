import {
  GET_ADDRESS_LIST_REQ,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_ERROR,
  CREATE_ADDRESS_REQ,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERROR,
  UPDATE_ADDRESS_REQ,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERROR,
  DELETE_ADDRESS_REQ,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR
} from "../../Constants/Address";

let initialState = {
  getListLoading: false,
  existingAddressesList: [],
  getAddressListError: {},

  createAddressLoading: false,

  updateAddressLoading: false,
  updateSuccess: false,

  deleteAddressLoading: false,
  deleteAddressResponse: []
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDRESS_LIST_REQ:
      return {
        ...state,
        getListLoading: true,
        getAddressListError: {},
        updateSuccess: false,
        deleteAddressLoading: false,
        addressCreatedMessage: "",
        deleteAddressResponse: [],
        updateAddressLoading: false
      };
    case GET_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        getListLoading: false,
        existingAddressesList: action.payload,
        getAddressListError: {},
        deleteAddressLoading: false
      };
    case GET_ADDRESS_LIST_ERROR:
      return {
        ...state,
        getListLoading: false,
        getAddressListError: action.payload,
        existingAddressesList: action.payload,
        deleteAddressLoading: false
      };
    case CREATE_ADDRESS_REQ:
      return {
        ...state,
        createAddressLoading: true,
        updateSuccess: false,
        deleteAddressLoading: false,
        addressCreatedMessage: ""
      };
    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        createAddressLoading: false,
        existingAddressesList: action.payload?.data,
        addressCreatedMessage: action.payload?.message,
        deleteAddressLoading: false
      };
    case CREATE_ADDRESS_ERROR:
      return {
        ...state,
        createAddressLoading: false,
        deleteAddressLoading: false,
        addressCreatedMessage: ""
      };
    case UPDATE_ADDRESS_REQ:
      return {
        ...state,
        updateAddressLoading: true,
        deleteAddressLoading: false
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updateAddressLoading: false,
        existingAddressesList: action.payload,
        updateSuccess: true,
        deleteAddressLoading: false
      };
    case UPDATE_ADDRESS_ERROR:
      return {
        ...state,
        deleteAddressLoading: false
      };
    case DELETE_ADDRESS_REQ:
      return {
        ...state,
        deleteAddressLoading: true
      };
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteAddressLoading: false,
        existingAddressesList: state.existingAddressesList.filter(
          (address) => address.id !== action.payload.addressId
        ),
        deleteAddressResponse: action.payload.data
      };
    case DELETE_ADDRESS_ERROR:
      return {
        ...state,
        deleteAddressLoading: false
      };
    default:
      return state;
  }
};

export default addressReducer;

import React from "react";
import PropTypes from 'prop-types';
import "./table.css"
import { Card, Table } from 'antd'

const TableSkeleton = ({ tabColumn, data, summary, scroll={} }) => {  
    return (
        <div className="container">
            <Card className="table-container-card">
                <Table dataSource={data} columns={tabColumn} summary={summary} scroll={scroll} />
            </Card>
        </div>
    )
}

TableSkeleton.propTypes = {
    tabColumn: PropTypes.array,
    data: PropTypes.array,
    summary: PropTypes.func,
    scroll: PropTypes.object
};

export default TableSkeleton;
import React from "react";
import PropTypes from 'prop-types';
import { Empty } from "antd";

const EmptyList = ({ text }) => {
  return (
    <Empty
      image="/images/no-search-found.png"
      imageStyle={{
        height: 200
      }}
      description={text &&
        <span style={{ fontSize: "16px", fontFamily: "Cabin" }}>
          {text}
        </span>
      }
    />
  );
};

EmptyList.propTypes = {
  text: PropTypes.string
};

export default EmptyList;

import React from 'react';
import { Provider } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
import "./customCss.css";
import 'antd/dist/antd.css';

// pages
import {
  Home,
  AboutUs,
  Contact,
  Password,
  Error,
  ProductList,
  ProductDetails,
  Cart,
  AddressPage,
  PaymentPage,
  Terms,
  Results,
  Policy,
  YourAccount,
  OrderHistory,
  ChangePassword,
  ManageProfile,
  AllProducts,
  Shipping,
  ThankYou,
  Feedback,
  AllReviews
} from './pages';

// Routes
import Routes from "./Routes";

// Templates
import Main from "./Templates/Main/Main";
import LogOnly from "./Templates/LogOnly/LogOnly";

import { store, persistor } from "./store";

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Switch>
        <Main exact path={Routes.Home.path} component={Home} />
        <Main exact path={Routes.AboutUs.path} component={AboutUs} />
        <Main exact path={Routes.Contact.path} component={Contact} />
        <Main exact path={Routes.ProductDetails.path} component={ProductDetails} />
        <Main exact path={Routes.CategoryWiseProductList.path} component={ProductList} />
        <Main exact path={Routes.Cart.path} component={Cart} />
        <Main exact path={Routes.Address.path} component={AddressPage} />
        <Main exact path={Routes.Payment.path} component={PaymentPage} />
        <Main exact path={Routes.YourAccount.path} component={YourAccount} />
        <Main exact path={Routes.Terms.path} component={Terms} />
        <Main exact path={Routes.Result.path} component={Results} />
        <Main exact path={Routes.Policy.path} component={Policy} />
        <Main exact path={Routes.Shipping.path} component={Shipping} />
        <Main exact path={Routes.OrderHistory.path} component={OrderHistory} />
        <Main exact path={Routes.ChangePassword.path} component={ChangePassword} />
        <Main exact path={Routes.ManageProfile.path} component={ManageProfile} />
        <Main exact path={Routes.AllProducts.path} component={AllProducts} />
        <Main exact path={Routes.ThankYou.path} component={ThankYou} />
        <Main exact path={Routes.Feedback.path} component={Feedback} />
        <Main exact path={Routes.AllReviews.path} component={AllReviews} />
        <LogOnly excat path={Routes.Password.path} component={Password} />
        <LogOnly exact path={Routes.Error.path} component={Error} />
        <Redirect to={Routes.Error.path} />
      </Switch>
    </PersistGate>
  </Provider>
);

export default App;

import axios from "axios";
import {
    ALL_REVIEWS_REQ,
    ALL_REVIEWS_SUCCESS,
    ALL_REVIEWS_ERROR
} from "../../Constants/AllReviews"
import * as api from "../../apis/common";

export { allReviews }

function allReviews(page) {
    const pages = (page == undefined) ? 1 : page
    return async (dispatch) => {
        try {
            dispatch({ type: ALL_REVIEWS_REQ });
            const result = await axios.post(api.GRAPHQL_API, {
                query: `{
                    allFeedback(page:${pages}){
                          success
                          statusCode
                          message
                          data{
                              id
                              name
                              email
                              rate
                              comment
                            }
                         dataCount
                         allRecord
                         pageNo
                      }
                   }
                   `
            }
            )
            if (result?.data?.data?.allFeedback?.success === "1") {
                dispatch({ type: ALL_REVIEWS_SUCCESS, payload: result.data.data.allFeedback });
            } else {
                dispatch({ type: ALL_REVIEWS_ERROR, payload: result?.data?.data.allFeedback })
            }
        } catch (e) {
            dispatch({ type: ALL_REVIEWS_ERROR, payload: e.error?.[0] })
        }
    }
} 

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Form, Input, Button, Modal, Spin } from 'antd';

import { getForgotPasswordReq, resetInitialState } from '../../Actions/ForgotPassword/actions';

import PopupModal from "../../container/PopupModal";

const { Text, Title } = Typography;

const ForgetPassword = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const forgotPassword = useSelector(state => state.forgotPassword)
    const { forgotPasswordLoading, forgotPasswordResponse } = forgotPassword

    const [activePage, setActivePage] = useState({
        name: "",
        activeStatus: false
    });

    const onModalOk = () => {
        dispatch(resetInitialState()); onReset();
    }

    useEffect(() => {
        if (Object.keys(forgotPasswordResponse).length) {
            props.func("", false);
        }
    }, [forgotPasswordResponse])

    const onFinish = (values) => {
        dispatch(getForgotPasswordReq(values));
    };

    function success() {
        Modal.success({
            title: "Success",
            content: forgotPasswordResponse?.message,
            onOk() { onModalOk() }
        });
    }

    function error() {
        Modal.error({
            title: "Error",
            content: forgotPasswordResponse?.message,
            onOk() { onModalOk() }
        });
    }

    const onReset = () => {
        form.resetFields();
    };

    const handleUpdate = (name, activeStatus) => {
        setActivePage({ name, activeStatus });
    };

    return (
        <div>
            <Title level={4}>Password assistance</Title>
            <Text strong>Enter your email address associated with your Eagle Works account</Text>
            <br />
            <br />
            <Form layout='vertical' form={form} onFinish={onFinish}>
                <Spin spinning={forgotPasswordLoading}>
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email" }, { type: "email", message: "Please input a valid email address" }]}>
                        <Input type="email" placeholder='Please input your email address'></Input>
                    </Form.Item>
                    <Form.Item>
                        <Button className='btn brandBtn' type='primary' htmlType='submit' block>Continue</Button>
                    </Form.Item>
                </Spin>
            </Form>
            {activePage.activeStatus && (
                <PopupModal pageInfo={activePage} func={handleUpdate} />
            )}
            {
                !forgotPasswordLoading && forgotPasswordResponse?.success === "1" && success()
            }
            {
                !forgotPasswordLoading && forgotPasswordResponse?.success === "" && error()
            }
        </div>

    )
}

ForgetPassword.propTypes = {
    func: PropTypes.func
};

export default ForgetPassword
import {
  GET_ALL_PRODUCTS_REQ,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_ERROR,
  GET_PRODUCT_BY_ID_REQ,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_ERROR,
  FETCH_ALL_FEATURED_PRODUCTS_REQ,
  FETCH_ALL_FEATURED_PRODUCTS_SUCCESS,
  FETCH_ALL_FEATURED_PRODUCTS_ERROR,
  BUY_BULK_REQ,
  BUY_BULK_SUCCESS,
  BUY_BULK_ERROR,
  ALL_PRODS_REQ,
  ALL_PRODS_SUCCESS,
  ALL_PRODS_ERROR,
} from "../../Constants/Products";
import axios from "axios";
import * as api from "../../apis/common";

export {
  getAllProductsReq,
  getProductByIdReq,
  fetchAllFeaturedProducts,
  buyBulkReq,
  comparePrice,
  searchProducts,
  categorySliderReq,
};

const getAllProductsReq = (searchText, pageNo, categoryId, filters = []) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_PRODUCTS_REQ });
      const result = await axios.post(api.GRAPHQL_API, {
        query: `{
              products(search:"${searchText}", page:${pageNo}, category_id:[${categoryId}], filter: [${filters}]){
                    success
                    statusCode
                    message
                    data{
                        products{
                          id
                          name
                          slug
                          product_code
                          quantity
                          description
                          status
                          category_id
                          short_description
                          tax
                          is_featured
                          price
                          price_status
                          price_description              
                          getImage{
                                id
                                image_url
                                image_type
                              }
                       }
                      filters{
                       filter_name
                       filter_data {
                         id
                         product_id
                         property_name
                         property_value
                         unit
                         category_filter_id
                       }
                     }
                     }
                   dataCount
                   allRecord
                   pageNo
                }
             }
             `,
      });
      if (result?.data?.data?.products) {
        dispatch({
          type: GET_ALL_PRODUCTS_SUCCESS,
          payload: result.data.data.products,
        });
      } else {
        dispatch({
          type: GET_ALL_PRODUCTS_ERROR,
          payload: result.data.data.products,
        });
      }
    } catch (error) {
      dispatch({ type: GET_ALL_PRODUCTS_ERROR, payload: error });
    }
  };
};

function getProductByIdReq(id) {

  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCT_BY_ID_REQ });
      const result = await axios.post(api.GRAPHQL_API, {
        query: `{
                product(id:${id}){
                  success
                  statusCode
                  message
                  data{
                  product{
                    id
                    name
                    product_code
                    quantity
                    description
                    short_description
                    tax
                    status
                    category_id
                    is_featured
                    price
                    price_status
                    price_description        
                    getImage{
                      id
                      image_url
                      image_type
                      original_image
                    }
                    getProductCategoryName{
                      id
                      name
                    }
                  }
                  filters{
                    filter_name
                    filter_data {
                      id
                      property_name
                      property_value
                          unit
                          product_id
                    }
                  }
                  
                }
              }
          }`,
      });

      dispatch({
        type: GET_PRODUCT_BY_ID_SUCCESS,
        payload: result.data.data.product.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_BY_ID_ERROR,
        payload: error,
      });
    }
  };
}

const fetchAllFeaturedProducts = async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_FEATURED_PRODUCTS_REQ });

    const resultProducts = await axios.post(api.GRAPHQL_API, {
      query: api.FEATURED_PRODUCTS,
    });

    dispatch({
      type: FETCH_ALL_FEATURED_PRODUCTS_SUCCESS,
      payload: resultProducts?.data?.data?.featuredproduct?.data,
    });
  } catch (e) {
    dispatch({ type: FETCH_ALL_FEATURED_PRODUCTS_ERROR, payload: e });
  }
};

function buyBulkReq(obj, token, cb) {
  const {
    name,
    email,
    phone,
    message,
    no_notification,
    signup,
    password = "",
    bulkProducts,
  } = obj;
  return async (dispatch) => {
    try {
      dispatch({ type: BUY_BULK_REQ });
      const result = await axios.post(
        api.GRAPHQL_API,
        {
          query: `mutation{
                  createBuyBulk(
                  name: "${name}",
                  email: "${email}",
                  phone: "${phone}",
                  bulkproducts: ${JSON.stringify(bulkProducts).replaceAll(
            '"',
            ""
          )}
                  message: "${message}",
                  no_notification: ${no_notification ? 1 : 0},
                  signup: ${signup ? 1 : 0},
                  password: "${password}"){
                  success
                  statusCode
                  message
                  }
                }`,
        },
        token && {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data) {
        dispatch({ type: BUY_BULK_SUCCESS });
        cb(result.data.data.createBuyBulk);
      } else {
        dispatch({ type: BUY_BULK_ERROR });
      }
    } catch (error) {
      dispatch({ type: BUY_BULK_ERROR });
    }
  };
}

function comparePrice(data, cb) {
  return async () => {
    try {
      const result = await axios.post(api.GRAPHQL_API, {
        query: `mutation{
          copyContent(name:"${data.name}",
            email:"${data.email}",
            phone:"${data.phone}", 
            site_link:"${data.site_link}"){
               success
               statusCode
               message
          }
        }`,
      });
      if (result.data.data.copyContent.success === "1") {
        cb({ message: "Inquiry has been sent successfully", title: "Success" });
      } else {
        cb({ message: "Sent inquiry error", title: "Error" });
      }
    } catch (e) {
      cb({ message: "Sent inquiry error", title: "Error" });
    }
  };
}

function searchProducts(searchText, cb) {
  return async () => {
    try {
      cb({ data: [], loading: true });
      const result = await axios.post(api.GRAPHQL_API, {
        query: `{Searchbuybulkproduct(search:"${searchText}"){
              success
            statusCode
            message
            data{
              id
              name
              product_code
            }
          } 
        }`,
      });

      if (result.data.data.Searchbuybulkproduct.success === "1") {
        cb({
          data: result.data.data.Searchbuybulkproduct.data,
          loading: false,
        });
      } else {
        cb({ data: result.data.data.Searchbuybulkproduct, loading: false });
      }
    } catch (e) {
      cb({ message: "Sent inquiry error", title: "Error" });
    }
  };
}

function categorySliderReq() {
  return async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODS_REQ });
      const result = await axios.post(api.GRAPHQL_API, {
        query: `{
          productsByCategoryWise{
             success
             statusCode
             message
             data{
              cateGoryName
              categoryId
              products{
                id
                product_code
                slug
                quantity
                description
                short_description
                tax
                status
                price
                price_status
                price_description
                category_id
                is_featured
                getImage{
                  id
                  image_url
                  image_type
                }
                name
                price
              }
            }
          }
        }`,
      });

      if (result?.data?.data?.productsByCategoryWise?.success === "1") {
        dispatch({
          type: ALL_PRODS_SUCCESS,
          payload: result.data.data.productsByCategoryWise.data,
        });
      } else {
        dispatch({ type: ALL_PRODS_ERROR, payload: [] });
      }
    } catch (e) {
      dispatch({ type: ALL_PRODS_ERROR, payload: [] });
    }
  };
}

import React from 'react'
import "./policy.css"
import { Typography, Row, Divider, Card, Col } from 'antd';


const { Title, Paragraph } = Typography;

const Policy = () => {
  return (
    <div className="container">
      <Card className="policyContainer">
        <Row justify="space-around">
          <Col span={24}>
            <Title level={3} className="para-title">Privacy statement</Title>
            <Paragraph className='para-info'>In this section, we will further inform you about how and why we use your personal data.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">How we use your personal data</Title>
            <Paragraph className='para-info'>We will store and use the personal data you provide within our organization and never share with third party without your permission. We will
              strive to keep your information accurate and up to date, and not keep it for longer than is necessary. We are required to retain information in
              accordance with the law, such as information needed for income tax and audit purposes. How long certain kinds of personal data should be kept
              may also be governed by specific business-sector requirements and agreed practices. Personal data may be held in addition to these periods
              depending on individual business needs.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Why do we need to collect and store your data?</Title>
            <Paragraph className='para-info'>In order to provide you with our products and services we need to collect personal data for correspondence, audit and accounting purposes. We
              are always committed to ensuring that the information we collect is appropriate for this purpose and does not constitute an invasion of your
              privacy.</Paragraph>
            <Paragraph className='para-info'>We will reach out to you for additional consent, should we plan to contact you for marketing purposes.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Are we sharing your personal data with third-parties?</Title>
            <Paragraph className='para-info'>Any third-party that we may share your data with is obliged to keep your personal data securely, and to use it only to fulfil the service they
              provide on our behalf (such as online store transactions, finance applications and service repairs). When they no longer need your data to fulfil
              this service, they will dispose of the details in line with our retention procedures. Eagle Work Solutions make certain personal information
              available to third party partners that work with us to provide products, services and marketing communications. For example, if a customer
              places an order through our online store, a name, address and email address will be shared with our distributor. If you follow a link from our
              website to an external site or service, this policy will no longer apply. We are not responsible for the information handling practices of third-
              party sites and we encourage you to read the privacy policies appearing on those sites or services before entering in personal data.</Paragraph>
            <Paragraph className='para-info'>Eagle Work Solutions will not share your data with any third-party, Unless we are legally obliged to do otherwise, we will always contact you
              for your consent, regarding sharing your personal data to third-parties. Can you find out what personal data we hold about you? You are entitled
              to know what information we hold about you, therefore, we will at your request confirm what personal data we hold and how it is processed.</Paragraph>
            <Paragraph className='para-info'>What are you entitled to request if we hold your personal data?</Paragraph>
            <Paragraph className='para-info'>Eagle Work Solutions will not share your data with any third-party, Unless we are legally obliged to do otherwise, we will always contact you
              for your consent, regarding sharing your personal data to third-parties. Can you find out what personal data we hold about you? You are entitled
              to know what information we hold about you, therefore, we will at your request confirm what personal data we hold and how it is processed.</Paragraph>
            <Paragraph className='para-info'>What are you entitled to request if we hold your personal data?</Paragraph>
            <Paragraph className='para-info'>
              <ul className="para-title">If we hold any personal data about you, you can request following:
                <li className='para-info'>• Information about the purpose of the processing as well as the legal basis for processing;</li>
                <li className='para-info'>• Information about categories of personal data collected, stored and processed;</li>
                <li className='para-info'>• Information about any third-person service providers we disclosed/planning to disclose your personal data to;</li>
                <li className='para-info'>• Information about safeguards we have provided for transferring your personal data to a third country;</li>
                <li className='para-info'>• Information about how long we plan to keep your data;</li>
                <li className='para-info'>• Information about the source of the data (if you are not sure data was collected directly from you).</li>
              </ul>
            </Paragraph>
            <Paragraph className='para-info'>Any details and information of automated decision making, such as profiling, and any meaningful information about the logic involved, as well
              as the significance and expected consequences of such processing.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Online Store and Payment Gateway Services</Title>
            <Paragraph className='para-info'>Payment information entered our online store will be provided directly to SagePay or Paypal. Any information being entered when transacting at
              our online store will be encrypted using SSL technology. All online store accounts will also require a password.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Data retention</Title>
            <Paragraph className='para-info'>We will not store your data for any longer than is necessary. If you have purchased goods or services from us, we will retain your data in
              accordance with HMRC accounting regulations. If you request a quotation from us, your information will be deleted after 90 days. Service
              information will be retained for 6 years in order to provide the service history of your product if required.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Children</Title>
            <Paragraph className='para-info'>In using our services online and in store, you confirm that you have read and consented to this Policy and verify that you are over the age of 13.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Email marketing information</Title>
            <Paragraph className='para-info'>We will only send you email marketing communications if you have given us your consent to do so. If you no longer wish to receive email
              marketing communications, simply email us to remove you from mailing list. Please note that if you have provided us with your email address to
              book in a device at one of our service centres or if you have requested a digital receipt, your email address will remain on our internal systems to
              provide you with updates on your repair or to send you a digital receipt in the future.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Additional information and how to contact us</Title>
            <Paragraph className='para-info'>If you would like to update your records, see a copy of the information that we hold about you or if you have any questions that we have
              not covered in our policy, please contact us at Data Protection Officer, Suite 4a, 2nd Floor, Regency House, George Street, Luton,
              England, LU1 2AT or email <a className="headerEmai" href="mailto:info@eagleworksolutions.com">info@eagleworksolutions.com</a>. You will need to provide a copy of proof of identification before we can
              share any information (this must be a passport or identity card). Please do not send us any original documentation as we will be unable to
              return them. Requests can take up to 28 days to receive a response from the date it is received.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Row span={24}>
          <Col span={24}>
            <Title level={4} className="para-title">Complaints</Title>
            <Paragraph className='para-info'>If you are dissatisfied with the way your personal data is being processed, you can lodge a complaint to complaints team at 02039838977.</Paragraph>
          </Col>
        </Row>
        <Divider />
        <Paragraph className='para-info'><a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> is registered under company number 12377307. VAT No: 341502834.</Paragraph>
        <Paragraph className='para-info'>Any changes we make to our Privacy Policy will be published here. Please check back frequently to see any updates or changes.</Paragraph>

      </Card>
    </div>
  )
}

export default Policy

export const GET_ADDRESS_LIST_REQ = "GET_ADDRESS_LIST_REQ";
export const GET_ADDRESS_LIST_SUCCESS = "GET_ADDRESS_LIST_SUCCESS";
export const GET_ADDRESS_LIST_ERROR = "GET_ADDRESS_LIST_ERROR";

export const CREATE_ADDRESS_REQ = "CREATE_ADDRESS_REQ";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_ERROR = "CREATE_ADDRESS_ERROR";

export const UPDATE_ADDRESS_REQ = "UPDATE_ADDRESS_REQ";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_ERROR = "UPDATE_ADDRESS_ERROR";

export const DELETE_ADDRESS_REQ = "DELETE_ADDRESS_REQ";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_ERROR = "DELETE_ADDRESS_ERROR";
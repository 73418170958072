import {
    CONTACT_US_REQ,
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR,
    RESET_TO_INITIAL_STATE
} from "../../Constants/Contact";

const initialState = {
    contactUsLoading: false,
    contactUsResponse: {},
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_US_REQ:
            return {
                ...state,
                contactUsLoading: true,
                contactUsResponse: {}
            }
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                contactUsLoading: false,
                contactUsResponse: action.payload
            }
        case CONTACT_US_ERROR:
            return {
                ...state,
                contactUsLoading: false,
                contactUsResponse: action.payload
            }
        case RESET_TO_INITIAL_STATE:
            return {
                ...state,
                contactUsLoading: false,
                contactUsResponse: {}
            }
        default:
            return state;
    }

}

export default contactReducer;



import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import "./password.css";

// actions
import { getCartByUserReq } from "../../Actions/Cart/actions";
import { setUserDetailsReq } from "../../Actions/User/actions";

const { Title, Text } = Typography;

const Password = (props) => {
  const [form] = Form.useForm();
  const [username, setUsername] = useState();
  const [keepSigned, setKeepSigned] = useState(false);
  const [userCookie, setUserCookie, removeUserCookie] = useCookies();

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const localCart = useSelector((state) => state.cart.localCart);
  const userState = useSelector((state) => state.user);
  const { userDetails, isLoading, error } = userState;

  useEffect(() => {
    if (userCookie?.username) {
      setKeepSigned(true);
    }

    let userData = JSON.parse(localStorage.getItem("userInfo"));
    form.setFieldsValue({
      keepSigned: true,
    });
    if (userData && userData?.emailOrMobile) {
      setUsername(userData?.emailOrMobile);
    }
    inputRef?.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (userDetails?.api_token) {
      dispatch(getCartByUserReq(userDetails.api_token));
    }

    if (error?.message) {
      removeUserCookie("username");
    }
  }, [userDetails, error]);

  useEffect(() => {
    if (!isLoading && userDetails?.api_token) {
      props.func("", false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (error?.message) {
      form.setFields([
        {
          name: "password",
          errors: [error.message],
        },
      ]);
    }
  }, [error]);

  function loginResp(resp) {
    if (resp.success) {
      message.success(
        `Welcome ${resp?.user_details?.name}! you are logged in successfully`,
        3
      );
    }
  }

  const loginUser = (values) => {
    if (keepSigned) {
      setUserCookie(
        "username",
        JSON.parse(localStorage.getItem("userInfo")).emailOrMobile,
        { path: "/" }
      );
    } else {
      removeUserCookie("username");
    }
    dispatch(
      setUserDetailsReq(
        { username, password: values.password },
        localCart,
        loginResp
      )
    );
  };
  return (
    <Form layout="vertical" form={form} onFinish={!isLoading && loginUser}>
      <Spin spinning={isLoading}>
        <Title level={3} className="card-title sign-in-title">
          Welcome
        </Title>
        <Text>
          {username}{" "}
          <span
            className="create-acc-link"
            onClick={() => props.func("login", true)}
          >
            Change
          </span>
        </Text>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
          style={{ paddingTop: "30px" }}
        >
          <Input.Password placeholder="Password" ref={inputRef} />
        </Form.Item>
        <Row className="password-row" justify="space-between">
          <Col>
            <Form.Item
              name="keepSigned"
              valuePropName={keepSigned ? "checked" : ""}
            >
              <Checkbox onChange={(e) => setKeepSigned(e.target.checked)}>
                Keep me signed in
              </Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="link"
              onClick={() => props.func("forgetPassword", true)}
              style={{ paddingRight: 0 }}
            >
              Forgot password?
            </Button>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn continue-btn continueBtn"
          >
            Continue
          </Button>
        </Form.Item>
      </Spin>
    </Form>
  );
};

Password.propTypes = {
  func: PropTypes.func,
};

export default Password;

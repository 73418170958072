import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Rate, Input } from "antd";
import "./writeReview.css";
import { useDispatch } from "react-redux";

// actions
import { createReview } from "../../Actions/Reviews/actions";

const { TextArea } = Input;

const WriteReview = ({ productId }) => {
  const [review, setReview] = useState();
  const [rating, setRating] = useState(0);
  const [error, setError] = useState();

  const dispatch = useDispatch();

  const desc = ["Terrible", "Bad", "Normal", "Good", "Wonderful"];

  const submitRequest = () => {
    if (!rating && !rating) {
      setError("Please submit details...");
      return;
    }

    if (!review) {
      setError("Please write a review...");
      return;
    }

    if (!rating) {
      setError("Please rate this product...");
      return;
    }

    dispatch(createReview(rating, review, productId));
  };

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <span className="ratings-block mb-20">
          <Rate
            tooltips={desc}
            onChange={(val) => {
              setRating(val);
              setError("");
            }}
            value={rating}
          />
          {rating ? (
            <span className="ant-rate-text input-label">
              {desc[rating - 1]}
            </span>
          ) : (
            ""
          )}
        </span>
        <label className="input-label">
          Write review <span className="required-field">*</span>
        </label>
        <TextArea
          name="review"
          rows={4}
          value={review}
          onChange={(e) => {
            setReview(e.target.value);
            setError("");
          }}
        />
        {error && <p className="error">{error}</p>}
        <button
          className="btn continue-btn"
          onClick={() => {
            submitRequest();
          }}
        >
          Submit Review
        </button>
      </div>
    </div>
  );
};

WriteReview.propTypes = {
  productId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default WriteReview;
import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

// CSS
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./home.css";

// components
import HomeAds from "../../components/HomeAds";
import HomeCarousel from "../../components/Carousel";
import FeaturedProductsSlider from "../../components/FeaturedProductsSlider";

// actions
import { getAllCategoriesReq } from "../../Actions/Category/actions";
import { fetchAllFeaturedProducts } from "../../Actions/Products/actions";
import { getCartByUserReq } from "../../Actions/Cart/actions";
import { getCarouselData } from "../../Actions/HomeCarousel";


const Home = (props) => {
  const dispatch = useDispatch();

  const homeCarouselState = useSelector((state) => state.homeCarousel);

  useEffect(() => {
    dispatch(getAllCategoriesReq);
    dispatch(fetchAllFeaturedProducts);
    dispatch(getCarouselData);
  }, []);

  useEffect(() => {
    if (props.userInfo?.api_token) {
      dispatch(getCartByUserReq(props.userInfo.api_token));
    }
  }, [props.userInfo]);

  return (
    <React.Fragment>
      <HomeCarousel data={homeCarouselState.data} />
      <HomeAds />
      <FeaturedProductsSlider />
    </React.Fragment>
  );
};

Home.propTypes = {
  userInfo: PropTypes.object
};

export default Home;
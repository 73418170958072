import {
  ADD_ITEM_TO_CART_REQ,
  ADD_ITEM_TO_CART_SUCCESS,
  ADD_ITEM_TO_CART_ERROR,
  GET_CART_BY_USER_REQ,
  GET_CART_BY_USER_SUCCESS,
  GET_CART_BY_USER_ERROR,
  RESET_LOCAL_CART_ITEMS,
  UPDATE_LOCAL_CART_ITEMS_REQ,
  UPDATE_LOCAL_CART_ITEMS_SUCCESS,
  UPDATE_LOCAL_CART_ITEMS_ERROR,
  UPDATE_LOCAL_CART_ITEM_QUANTITY_REQ,
  UPDATE_LOCAL_CART_ITEM_QUANTITY_SUCCESS,
  UPDATE_LOCAL_CART_ITEM_QUANTITY_ERROR,
  DELETE_CART_ITEM_BY_ITEM_ID_REQ,
  DELETE_CART_ITEM_BY_ITEM_ID_SUCCESS,
  DELETE_CART_ITEM_BY_ITEM_ID_ERROR,
  ADD_MULTIPLE_PRODUCTS_REQ,
  ADD_MULTIPLE_PRODUCTS_SUCCESS,
  ADD_MULTIPLE_PRODUCTS_ERROR,
  UPDATE_USER_CART_BY_TOKEN_REQ,
  UPDATE_USER_CART_BY_TOKEN_SUCCESS,
  UPDATE_USER_CART_BY_TOKEN_ERROR,
  UPDATE_USER_CART_BY_TOKEN_301_ERROR,
  UPDATE_USER_CART_BY_TOKEN_301_ERROR_RESTORE,
  DELETE_LOCAL_CART_ITEMS_REQ,
  DELETE_LOCAL_CART_ITEMS_SUCCESS,
  DELETE_LOCAL_CART_ITEMS_ERROR,
  RESET_RESPONSE
} from "../../Constants/Cart";

let initialState = {
  addItemLoading: false,
  addItemSuccess: {},
  addToCartError: "",
  getCartLoading: false,
  items: [],
  itemsError: "",
  localCart: [],
  localCartLoading: false,
  updateLocalCartItemQuantityLoading: false,
  deleteUserCartItemLoading: false,
  deleteUserItemMessage: "",
  itemToBeDeleting: null,
  addMultipleProductsLoading: false,
  addMultipleProductsSuccess: [],
  addMultipleProductsError: {},
  updateUserCartItemLoading: false,
  updateUserCartItemPayload: [],
  productsError: {},
  itemToBeAddedToCart: {},
  updatingItemId: null
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART_REQ:
      return {
        ...state,
        addItemLoading: true,
        addMultipleProductsSuccess: []
      };
    case ADD_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        addItemLoading: false,
        addItemSuccess: action.payload
      };
    case ADD_ITEM_TO_CART_ERROR:
      return {
        ...state,
        addItemLoading: false,
        addToCartError: action.payload
      };
    case GET_CART_BY_USER_REQ:
      return {
        ...state,
        getCartLoading: true,
        addMultipleProductsSuccess: []
      };
    case GET_CART_BY_USER_SUCCESS:
      return {
        ...state,
        getCartLoading: false,
        items: action.payload
      };
    case GET_CART_BY_USER_ERROR:
      return {
        ...state,
        getCartLoading: false,
        itemsError: action.payload
      };
    case RESET_LOCAL_CART_ITEMS:
      return {
        ...state,
        localCart: [],
        addMultipleProductsSuccess: []
      };
    case UPDATE_LOCAL_CART_ITEMS_REQ:
      return {
        ...state,
        localCart: action.payload,
        localCartLoading: true,
        addMultipleProductsSuccess: [],
        updatingItemId: action.updatingItemId
      };
    case UPDATE_LOCAL_CART_ITEMS_SUCCESS:
    case UPDATE_LOCAL_CART_ITEMS_ERROR:
      return {
        ...state,
        localCartLoading: false,
        updatingItemId: null
      };
    case UPDATE_LOCAL_CART_ITEM_QUANTITY_REQ:
      return {
        ...state,
        localCart: action.payload,
        updateLocalCartItemQuantityLoading: true,
        addMultipleProductsSuccess: []
      };
    case UPDATE_LOCAL_CART_ITEM_QUANTITY_SUCCESS:
      return {
        ...state,
        updateLocalCartItemQuantityLoading: false
      };
    case UPDATE_LOCAL_CART_ITEM_QUANTITY_ERROR:
      return {
        ...state,
        updateLocalCartItemQuantityLoading: false
      };
    case DELETE_CART_ITEM_BY_ITEM_ID_REQ:
      return {
        ...state,
        deleteUserCartItemLoading: true,
        deleteUserItemMessage: "",
        addMultipleProductsSuccess: [],
        itemToBeDeleting: action.payload.id
      };
    case DELETE_CART_ITEM_BY_ITEM_ID_SUCCESS:
      return {
        ...state,
        deleteUserCartItemLoading: false,
        deleteUserItemMessage: action.payload,
        itemToBeDeleting: null
      };
    case DELETE_CART_ITEM_BY_ITEM_ID_ERROR:
      return {
        ...state,
        deleteUserCartItemLoading: false,
        deleteUserItemMessage: action.payload,
        itemToBeDeleting: null
      };
    case ADD_MULTIPLE_PRODUCTS_REQ:
      return {
        ...state,
        addMultipleProductsLoading: true,
        addMultipleProductsSuccess: [],
        itemToBeAddedToCart: action.payload
      };
    case ADD_MULTIPLE_PRODUCTS_SUCCESS:
      return {
        ...state,
        addMultipleProductsLoading: false,
        addMultipleProductsSuccess: [action.payload],
        items: action.payload.data
      };
    case ADD_MULTIPLE_PRODUCTS_ERROR:
      return {
        ...state,
        addMultipleProductsLoading: false,
        addMultipleProductsError: action.payload,
        addMultipleProductsSuccess: []
      };
    case UPDATE_USER_CART_BY_TOKEN_REQ:
      return {
        ...state,
        updateUserCartItemLoading: true,
        productsError: {},
        addMultipleProductsSuccess: []
      };
    case UPDATE_USER_CART_BY_TOKEN_SUCCESS:
      return {
        ...state,
        updateUserCartItemLoading: false,
        updateUserCartItemPayload: action.payload,
        items: action.payload.updateCartById.data,
        productsError: {}
      };
    case UPDATE_USER_CART_BY_TOKEN_301_ERROR:
      return {
        ...state,
        productsError: action.payload,
        updateUserCartItemLoading: false,
        addMultipleProductsSuccess: []
      };
    case UPDATE_USER_CART_BY_TOKEN_301_ERROR_RESTORE:
      return {
        ...state,
        productsError: action.payload,
        updateUserCartItemLoading: false,
        addMultipleProductsSuccess: []
      };
    case UPDATE_USER_CART_BY_TOKEN_ERROR:
      return {
        ...state,
        updateUserCartItemLoading: false,
        productsError: {}
      };
    case DELETE_LOCAL_CART_ITEMS_REQ:
      return {
        ...state,
        localCartLoading: true,
        addMultipleProductsSuccess: []
      };
    case DELETE_LOCAL_CART_ITEMS_SUCCESS:
      return {
        ...state,
        localCartLoading: false,
        localCart: action.payload
      };
    case DELETE_LOCAL_CART_ITEMS_ERROR:
      return {
        ...state,
        localCartLoading: false,
      };
    case RESET_RESPONSE:
      return {
        ...state,
      }
    default:
      return state;
  }
};

export default cartReducer;

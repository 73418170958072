import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from 'react-cookie';

import { Form, Input, Button, Typography, Row, Divider } from 'antd';

import "./login.css";

import { validEmail, validPhone } from "../../utils";

import { resetInitialState } from '../../Actions/User/actions';

const { Title, Text } = Typography;

const Login = (props) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const [user] = useCookies();

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    inputRef.current.focus();
    form.setFieldsValue({
      emailOrMobile: user?.username,
    });
  }, []);

  const handleSubmit = (values) => {
    dispatch(resetInitialState())
    const username = values.emailOrMobile;

    if (validEmail.test(username) || validPhone.test(username)) {
      localStorage.setItem("userInfo", JSON.stringify(values));
      props.func("password", true);
    } else {
      setError(true);
      return;
    }
  };

  const handleClosePopup = () => {
    props.func("", false);
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <Title className="card-title sign-in-title" level={4}>Sign-in</Title>
      <Text
        className="create-acc-link"
        onClick={() => props.func("register", true)}
      >
        (create an account)
      </Text>
      <Divider />
      <Form.Item
        className="mb-0"
        label="Email"
        name="emailOrMobile"
        rules={[
          { required: true, message: 'Email is required' },
          { type: "email", message: 'Please Enter a valid Email' }
        ]}>
        <Input
          className="form-control"
          ref={inputRef} />
      </Form.Item>
      {error && (
        <div className="ant-form-item-explain-error">
          Please enter valid Email ID / Mobile number
        </div>
      )}
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          className="btn continue-btn"
        >
          Continue
        </Button>
      </Form.Item>
      <Row>
        <Text style={{ fontSize: "14px" }}>
          By continuing, i agree to the
          <Link to="/terms" onClick={() => handleClosePopup()} >
            {" "}Terms of Use
          </Link>
          {" "}and{" "}
          <Link to="/privacy-policy" onClick={() => handleClosePopup()} >
            Privacy Policy.
          </Link>
        </Text>
      </Row>
    </Form>
  );
};

Login.propTypes = {
  func: PropTypes.func
};

export default Login;
export const ADD_ITEM_TO_CART_REQ = "ADD_ITEM_TO_CART_REQ";
export const ADD_ITEM_TO_CART_SUCCESS = "ADD_ITEM_TO_CART_SUCCESS";
export const ADD_ITEM_TO_CART_ERROR = "ADD_ITEM_TO_CART_ERROR";

export const GET_CART_BY_USER_REQ = "GET_CART_BY_USER_REQ";
export const GET_CART_BY_USER_SUCCESS = "GET_CART_BY_USER_SUCCESS";
export const GET_CART_BY_USER_ERROR = "GET_CART_BY_USER_ERROR";

export const RESET_LOCAL_CART_ITEMS = "RESET_LOCAL_CART_ITEMS";
export const RESET_RESPONSE = "RESET_RESPONSE";

export const UPDATE_LOCAL_CART_ITEMS_REQ = "UPDATE_LOCAL_CART_ITEMS_REQ";
export const UPDATE_LOCAL_CART_ITEMS_SUCCESS = "UPDATE_LOCAL_CART_ITEMS_SUCCESS";
export const UPDATE_LOCAL_CART_ITEMS_ERROR = "UPDATE_LOCAL_CART_ITEMS_ERROR";

export const ADD_MULTIPLE_PRODUCTS_REQ = "ADD_MULTIPLE_PRODUCTS_REQ";
export const ADD_MULTIPLE_PRODUCTS_SUCCESS = "ADD_MULTIPLE_PRODUCTS_SUCCESS";
export const ADD_MULTIPLE_PRODUCTS_ERROR = "ADD_MULTIPLE_PRODUCTS_ERROR";

export const UPDATE_LOCAL_CART_ITEM_QUANTITY_REQ = "UPDATE_LOCAL_CART_ITEM_QUANTITY_REQ";
export const UPDATE_LOCAL_CART_ITEM_QUANTITY_SUCCESS = "UPDATE_LOCAL_CART_ITEM_QUANTITY_SUCCESS";
export const UPDATE_LOCAL_CART_ITEM_QUANTITY_ERROR = "UPDATE_LOCAL_CART_ITEM_QUANTITY_ERROR";

export const DELETE_CART_ITEM_BY_ITEM_ID_REQ = "DELETE_CART_ITEM_BY_ITEM_ID_REQ";
export const DELETE_CART_ITEM_BY_ITEM_ID_SUCCESS = "DELETE_CART_ITEM_BY_ITEM_ID_SUCCESS";
export const DELETE_CART_ITEM_BY_ITEM_ID_ERROR = "DELETE_CART_ITEM_BY_ITEM_ID_ERROR";

export const UPDATE_USER_CART_BY_TOKEN_REQ = "UPDATE_USER_CART_BY_TOKEN_REQ";
export const UPDATE_USER_CART_BY_TOKEN_SUCCESS = "UPDATE_USER_CART_BY_TOKEN_SUCCESS";
export const UPDATE_USER_CART_BY_TOKEN_ERROR = "UPDATE_USER_CART_BY_TOKEN_ERROR";

export const UPDATE_USER_CART_BY_TOKEN_301_ERROR = "UPDATE_USER_CART_BY_TOKEN_301_ERROR";
export const UPDATE_USER_CART_BY_TOKEN_301_ERROR_RESTORE = "UPDATE_USER_CART_BY_TOKEN_301_ERROR_RESTORE";

export const DELETE_LOCAL_CART_ITEMS_REQ = "DELETE_LOCAL_CART_ITEMS_REQ";
export const DELETE_LOCAL_CART_ITEMS_SUCCESS = "DELETE_LOCAL_CART_ITEMS_SUCCESS";
export const DELETE_LOCAL_CART_ITEMS_ERROR = "DELETE_LOCAL_CART_ITEMS_ERROR";
import {
    CHANGE_PASSWORD_REQ,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    RESET_INITIAL
} from '../../Constants/ChangePassword';

const initialState = {
    changePasswordLoading: false,
    changePasswordResp: {}
};

const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQ:
            return {
                ...state,
                changePasswordLoading: true,
                changePasswordResp: {}
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordResp: action.payload
            }
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordResp: action.payload
            }
        case RESET_INITIAL:
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordResp: {}
            }
        default:
            return state;
    }

}

export default changePasswordReducer;



import React from "react";
import { Col, Row } from 'antd';
import Lenovo from "../../images/media images/lenovo.jpg"
import ThreeM from "../../images/media images/3m.png"
import ABB from "../../images/media images/ABB.png"
import Apple from "../../images/media images/apple logo.png"
import Belkin from "../../images/media images/BELKIN-LOGO.png"
import Bosch from "../../images/media images/bosch.png"
import Catterpiller from "../../images/media images/catterpiller.png"
import Dell from "../../images/media images/dell.png"
import Dewalt from "../../images/media images/DeWalt_logo.png"
import Eaton from "../../images/media images/eaton.png"
import Hilti from "../../images/media images/hilti.png"
import Hp from "../../images/media images/hp.png"
import Legrand from "../../images/media images/LEGRAND.png"
import Makita from "../../images/media images/makita.png"
import Milkwankee from "../../images/media images/milwankee.png"
import Numatic from "../../images/media images/numatic logo.png"
import Philips from "../../images/media images/philips.png"
import Samsung from "../../images/media images/samsung.png"
import Scame from "../../images/media images/SCAME.png"
import Schneider from "../../images/media images/SCHNEIDER.png"
import Siemens from "../../images/media images/SIEMENS LOGO.jpg"
import Snapon from "../../images/media images/snap-on.jpg"
import Stanlet from "../../images/media images/stanlet b&d.png"
import Stihl from "../../images/media images/stihl.png"
import Supertouch from "../../images/media images/supertouch.jpg"
import "./mediablock.css";

const MediaBlock = () => {

    const mediaData = [{
        name: "lenovo",
        src: Lenovo
    }, {
        name: "ThreeM",
        src: ThreeM
    }, {
        name: "ABB",
        src: ABB
    }, {
        name: "Apple",
        src: Apple
    }, {
        name: "Belkin",
        src: Belkin
    }, {
        name: "Bosch",
        src: Bosch
    }, {
        name: "Catterpiller",
        src: Catterpiller
    }, {
        name: "Dell",
        src: Dell
    }, {
        name: "Dewalt",
        src: Dewalt
    }, {
        name: "Eaton",
        src: Eaton
    }, {
        name: "Hilti",
        src: Hilti
    }, {
        name: "Hp",
        src: Hp
    }, {
        name: "Legrand",
        src: Legrand
    }, {
        name: "Makita",
        src: Makita
    }, {
        name: "Milkwankee",
        src: Milkwankee
    }, {
        name: "Numatic",
        src: Numatic
    }, {
        name: "Philips",
        src: Philips
    }, {
        name: "Samsung",
        src: Samsung
    }, {
        name: "Scame",
        src: Scame
    }, {
        name: "Schneider",
        src: Schneider
    }, {
        name: "Siemens",
        src: Siemens
    }, {
        name: "Snapon",
        src: Snapon
    }, {
        name: "Stanlet",
        src: Stanlet
    }, {
        name: "Stihl",
        src: Stihl
    }, {
        name: "Supertouch",
        src: Supertouch
    },]
    return (
        <div>
            <Row style={{justifyContent:"center"}} className="logo-sec">
                {
                    mediaData.map((item,index)=>{
                        return(
                            <Col key={index} xs={{span:6}} xl={{span:5}} lg={{span:5}} md={{span:5}} sm={{span:6}}>
                                <img className="logoimages" src={item.src} alt={item.name} width="100%"/>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default MediaBlock

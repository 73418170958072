import React from 'react';
import PropTypes from 'prop-types';
import { Route } from "react-router-dom";

const LogOnly = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

LogOnly.propTypes = {
  component: PropTypes.func,
};

export default LogOnly;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewCart from "../../components/Cart";
import "./cart.css";

import { getCartByUserReq } from "../../Actions/Cart/actions";

const Cart = () => {
  // states
  const [currentCartItems, setCurrentCartItems] = useState([]);

  const dispatch = useDispatch();

  // redux-store
  const user = useSelector((state) => state.user);
  const cartState = useSelector((state) => state.cart);
  const { localCart, items } = cartState;

  useEffect(() => {
    if (user?.userDetails?.api_token) {
      dispatch(getCartByUserReq(user.userDetails.api_token));
    }
  }, []);

  useEffect(() => {
    if (user?.userDetails?.api_token) {
      setCurrentCartItems(items?.cart || []);
    } else if (!user?.userDetails?.api_token) {
      setCurrentCartItems(localCart);
    }
  }, [cartState]);

  return <NewCart cartItems={currentCartItems} />;
};

export default Cart;

import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import rootReducer from './Reducers'

const persistConfig = {
  key: 'root',
  storage,
}
const mWare = process.env.NODE_ENV === "development" ? composeWithDevTools(applyMiddleware(ReduxThunk)) : applyMiddleware(ReduxThunk)

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, mWare);
export const persistor = persistStore(store);

export default { store, persistor }
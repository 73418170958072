import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { convertToPriceVal } from "../../utils";
import {
  Space,
  Divider,
  Row,
  Col,
  Popconfirm,
  message,
  Spin,
  InputNumber,
  Button,
  Typography,
  notification
} from "antd";

import "./cart.css";

// Actions
import {
  deleteCartItemByItemId,
  updateUserCartByToken,
  updateLocalCartItems,
  deleteLocalCartItem,
  // getCartByUserReq
} from "../../Actions/Cart/actions";

// Components
import EmptyCart from "../../components/EmptyCart";
import PopupModal from "../../container/PopupModal";

import { calculateItemsData } from "../../utils"

const { Paragraph } = Typography;

const NewCart = ({ cartItems }) => {
  // states
  const [details, setDetails] = useState({});
  const [toDeleteId, setToDeleteId] = useState();
  const [selection, setSelection] = useState();
  const [activePage, setActivePage] = useState({
    name: "",
    activeStatus: false
  });

  // Redux store
  const userDetails = useSelector((state) => state.user.userDetails);
  const cartState = useSelector((state) => state.cart);
  const currentProductDetails = useSelector(
    (state) => state?.products?.productDetail?.product
  );
  const detailPriceDescription = () => {
    if (currentProductDetails?.price_status === "1") {
      return currentProductDetails?.price_description;
    } else {
      return (
        <span className="newPrice detail-newPrice currencyINR">
          {convertToPriceVal(currentProductDetails?.price)}{" "}
          <span style={{ fontSize: "15px", color: "rgb(157, 144, 144)" }}>
            (ex. VAT)
          </span>
        </span>
      );
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    deleteUserCartItemLoading,
    updateUserCartItemLoading,
    localCart,
    localCartLoading,
    items,
    productsError,
    updatingItemId,
    itemToBeDeleting
  } = cartState;

  const [prodErrorDisplay, setProdErrorDisplay] = useState(false);

  useEffect(() => {
    if (productsError && Object.keys(productsError).length) {
      setProdErrorDisplay(true);
    } else {
      setProdErrorDisplay(false);
    }
  }, [productsError])

  useEffect(() => {
    if (cartItems.length) {
      const itemData = calculateItemsData(cartItems);
      setDetails(itemData);
    }
  }, [cartItems, localCart.length, localCartLoading, updateUserCartItemLoading, items.cart]);

  const callbackFunc = (type) => {
    notification[type]({
      message: type.toUpperCase(),
      description: `Quantity updated ${type}.`,
      placement: "bottomRight",
    });
  }


  const onQuantityChange = (cartProduct, value, availableQuantity) => {
    const val = Number(value?.target?.value) ? Number(value.target.value) : Number(value);
    if (val <= 0 || val > Number(availableQuantity)) { return }

    // numeric value validation
    const reg = /^\d+$/;
    if (isNaN(val) || !reg.test(val) || val === '' || val === '-') {
      return;
    }

    let cartId = null;

    if (userDetails?.api_token) {
      cartId = cartProduct.id;
    } else {
      cartId = cartProduct.product_id;
    }
    setSelection(cartId);
    if (userDetails?.api_token) {
      dispatch(updateUserCartByToken(cartId, val, userDetails.api_token, callbackFunc));
    } else if (!userDetails?.api_token) {
      const existingProduct = cartItems.find((cItem) => {
        return cItem.product_id === cartId;
      });
      const existingProductIndex = cartItems.findIndex((cItem) => {
        return cItem.product_id === cartId;
      });
      const newData = localCart;
      newData[existingProductIndex] = {
        ...existingProduct,
        product_quantity: parseInt(val)
      };
      dispatch(updateLocalCartItems(newData, cartId, callbackFunc));
    }
  };

  const handleUpdate = (name, activeStatus) => {
    setActivePage({ name, activeStatus });
  };

  const handleDeleteCartItem = (currentItem) => {
    setToDeleteId(currentItem);
    if (userDetails?.api_token) {
      dispatch(deleteCartItemByItemId(currentItem, userDetails?.api_token));
    } else {
      dispatch(deleteLocalCartItem(currentItem, localCart));
    }
  };

  const handleCheckout = () => {
    if (userDetails?.api_token) {
      const outOfStockItems = items.cart.filter((itemOutStock) => itemOutStock.productStock < itemOutStock.product_quantity);
      if (outOfStockItems.length) {
        return notification['error']({
          message: "Error",
          description: `Please remove items which are out of stock.`
        });
      } else {
        history.push(`/address`);
      }
    } else {
      handleUpdate('login', true);
    }
  }

  return (
    <div>
      <div className="sc-background-dark">
        {userDetails?.api_token && !items?.cart?.length ? (
          <EmptyCart userLoggedIn={true} func={handleUpdate} />
        ) : !userDetails?.api_token && !localCart.length ? (
          <EmptyCart userLoggedIn={false} func={handleUpdate} />
        ) : (
          <Row justify="space-around">
            <Col xs={22} sm={22} md={17} lg={17} xl={17}>
              <div className="col1 a-cardui">
                <div className="a-cardui-body">
                  <div className="a-row sc-cart-header sc-compact-bottom">
                    <div className="a-row">
                      <span className="shopping-cart-text">Shopping Cart</span>
                    </div>
                  </div>
                  <div>
                    <div className="cart-price-header">
                      <div className="cart-price-header-free-space">
                        <div className="a-column a-span10"></div>
                      </div>
                      {/* <span className="cart-price-header-text">Price</span> */}
                    </div>
                    <div>
                      {cartItems.map((data, productIndex) => {
                        return (
                          <Spin
                            spinning={
                              (data?.id == toDeleteId?.id || data?.product_id == toDeleteId?.product_id) &&
                              (localCartLoading && updatingItemId == data.product_id) ||
                              (updateUserCartItemLoading && data.id == selection) ||
                              (deleteUserCartItemLoading && data.id == itemToBeDeleting)
                            }
                            key={productIndex}
                          >
                            <div className="a-row sc-list-item sc-list-item-border sc-java-remote-feature">
                              <div className="sc-list-item-content">
                                <div>
                                  <div className="ptb-10">
                                    <div className="cart-item-container">
                                      <div className="cart-image-area">
                                        <Link
                                          to={`/product-details/${encodeURI(data?.product_name.toLowerCase())}/${data?.product_id}`}
                                        >
                                          <img
                                            className="sc-product-image"
                                            src={data.image_url}
                                            alt="prod-img"
                                          />
                                        </Link>
                                      </div>
                                      <div className="cart-desc-area">
                                        <div className="desc-title">
                                          <span
                                            className="a-list-item"
                                            style={{ gridArea: "1/1/3/-1" }}
                                          >
                                            <span className="a-size-medium a-color-base sc-product-title">
                                              <Link
                                                to={`/product-details/${encodeURI(data?.product_name.toLowerCase())}/${data?.product_id}`}
                                              >
                                                <span
                                                  className="a-truncate lh-settings"
                                                  data-a-max-rows="2"
                                                  style={{
                                                    lineHeight:
                                                      "1.3em !important",
                                                    maxHeight: "2.6em"
                                                  }}
                                                >
                                                  <span className="a-truncate-cut overflow-hidden">
                                                    {data.product_name}
                                                  </span>
                                                </span>
                                              </Link>
                                            </span>
                                          </span>
                                        </div>

                                        <div style={{ margin: "5px 0" }}>
                                          <Paragraph ellipsis={{
                                            rows: 3, expandable: false, symbol: 'more', onExpand: function () {
                                              history.push(`/product-details/${encodeURI(data.product_name).toLowerCase()}/${data?.product_id}`)
                                            }
                                          }} className="cart-short-description">{data?.short_description}</Paragraph>
                                          {/* <div style={{ position: "absolute", top: "40%" }}>
                                            <p>{data?.short_description}</p>
                                          </div> */}
                                          <div className="desc-stock">
                                            {
                                              data.productStock < data.product_quantity ? (
                                                <span className="a-size-small a-color-error sc-product-availability">
                                                  Out of stock
                                                </span>
                                              ) : (
                                                <span className="a-size-small a-color-success sc-product-availability">
                                                  In stock
                                                </span>
                                              )
                                            }
                                          </div>
                                          <div className="currencyINR-desc-area">
                                            <span className="newCurrency">
                                              {(Number(data.product_price) + ((Number(data.product_price) * data.tax) / 100)).toFixed(2)}&nbsp;
                                            </span>
                                            <span style={{ color: "gray" }}> (Inc. VAT-{data.tax}%)</span>
                                          </div>
                                        </div>

                                        <div className="desc-links">
                                          <Row justify="left">
                                            <Col>
                                              <Row justify="start">
                                                <Space
                                                  size={0}
                                                  split={
                                                    <Divider type="vertical" />
                                                  }
                                                  className="space-desc-links"
                                                >
                                                  <Col>
                                                    <span>Qty: </span>
                                                  </Col>
                                                  <Col xs={4}>
                                                    <InputNumber
                                                      disabled={updateUserCartItemLoading}
                                                      type="number"
                                                      min={1}
                                                      max={data.productStock}
                                                      value={data.product_quantity}
                                                      onPressEnter={(e) =>
                                                        onQuantityChange(data, e, data.productStock)
                                                      }
                                                      onStep={(e) =>
                                                        onQuantityChange(data, e, data.productStock)
                                                      }
                                                      className="cartInput"
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Popconfirm
                                                      title="Are you sure to delete this item?"
                                                      onConfirm={() =>
                                                        handleDeleteCartItem(data)
                                                      }
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <Button shape="circle" icon={<i className="far fa-trash-alt"></i>} size={"medium"} className="delete-button" style={{ color: "#d11a2a" }} />
                                                      {/* <a
                                                        href="#"
                                                        className="a-size-small sc-action-delete"
                                                        style={{color: "#d11a2a"}}
                                                      >
                                                        Delete
                                                      </a> */}
                                                    </Popconfirm>
                                                  </Col>
                                                </Space>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                      <div className="cart-price-area" style={{ textAlign: "right" }}>
                                        <Col>
                                          <p className="newPrice priceTitle detail-newPrice">{detailPriceDescription()}</p>
                                          {/* <p className="vat-title">Inc. VAT-{data.tax}%</p>
                                          <span className="currencyINR">
                                            {(Number(data.product_price) + ((Number(data.product_price) * data.tax) / 100)).toFixed(2)}
                                          </span> */}
                                        </Col>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Spin>
                        )
                      })}
                      {/* <div className="subtotal-inside-cart-items">
                        <span className="a-size-medium">
                          Total ({details.qty} items):{" "}
                        </span>
                        <span className="a-color-price sc-price-container a-text-bold">
                          <p style={{ display: "inline-flex" }}>
                            <span className="a-size-medium a-color-base sc-price sc-white-space-nowrap">
                              <span className="currencyINR">
                                {details?.priceAfterTax && (details?.priceAfterTax.toFixed(2))}
                              </span>
                            </span>
                          </p>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={22} sm={22} md={6} lg={6} xl={6}>
              <div className="col2 a-cardui">
                <div className="a-cardui-body">
                  <form>
                    <div style={{ marginBottom: "10px" }}>
                      <span className="a-size-medium">
                        Subtotal ({details.items} items):<span className="subtotalText">{detailPriceDescription()}</span>
                      </span>
                      {/* <span className="a-size-medium">
                        <span className="currencyINR">
                          {
                            details?.priceAfterTax &&
                            Number(details?.priceAfterTax)?.toFixed(2)
                          }
                        </span>
                      </span> */}
                    </div>
                    <button
                      type="button"
                      className="btn brandBtn detailBuy"
                      onClick={() => handleCheckout()}
                    >
                      Proceed to submit
                    </button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {prodErrorDisplay && Object.keys(productsError).length ? message.error(productsError?.message, 1) : <></>}
      </div>
      {
        activePage.activeStatus && (
          <PopupModal pageInfo={activePage} func={handleUpdate} />
        )
      }
    </div >
  );
};

NewCart.propTypes = {
  cartItems: PropTypes.array
};

export default NewCart;
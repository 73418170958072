import {
  GET_CATEGORY_BY_ID_REQ,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_ERROR,
  GET_ALL_CATEGORIES_REQ,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
  RESET_CATEGORY_SEARCH,
  RESET_CATEGORY_SEARCH_SUCCESS,
  RESET_CATEGORY_SEARCH_ERROR,
  SET_CURRENT_CATEGORY_REQ,
  SET_CURRENT_CATEGORY_SUCCESS,
  SET_CURRENT_CATEGORY_ERROR
} from "../../Constants/Category";

let initialState = {
  category: {
    name: "All",
    id: 0,
    description: ""
  },
  isLoading: false,

  allCategories: [],
  isAllCategLoading: false
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_BY_ID_REQ:
      return {
        ...state,
        isLoading: true
      };
    case GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        category: action.payload
      };
    case GET_CATEGORY_BY_ID_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case GET_ALL_CATEGORIES_REQ:
      return {
        ...state,
        isAllCategLoading: true
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        isAllCategLoading: false,
        allCategories: action.payload
      };
    case GET_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        isAllCategLoading: false
      };
    case RESET_CATEGORY_SEARCH:
      return {
        ...state
      };
    case RESET_CATEGORY_SEARCH_SUCCESS:
      return {
        ...state,
        category: {
          name: "All",
          id: 0,
          description: ""
        }
      };
    case RESET_CATEGORY_SEARCH_ERROR:
      return {
        ...state
      };
    case SET_CURRENT_CATEGORY_REQ:
      return {
        ...state,
        category: action.payload
      };
    case SET_CURRENT_CATEGORY_SUCCESS:
      return {
        ...state
      };
    case SET_CURRENT_CATEGORY_ERROR:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default categoryReducer;

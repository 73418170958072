import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import parse from "html-react-parser";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Divider,
  InputNumber,
  Modal,
  Breadcrumb,
  Popover,
  notification,
  Form,
  Spin,
  Skeleton,
} from "antd";
import "./productDetails.css";

// components
import Loading from "../../components/Loading";
import ImagePreview from "../../components/ImagePreview/ImagePreview";

// containers
import PopupModal from "../../container/PopupModal";

// actions

import {
  // getAllCategoriesReq,
  getCategoryByIdReq,
} from "../../Actions/Category/actions";
import {
  getProductByIdReq,
  comparePrice,
} from "../../Actions/Products/actions";
import {
  updateLocalCartItems,
  addMultipleProductReq,
} from "../../Actions/Cart/actions";

import { convertToPriceVal } from "../../utils";

const { Option } = Select;

const ProductDetail = () => {
  // states
  const [count, setCount] = useState(1);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [prodFilters, setProdFilters] = useState({});
  const [prodId, setProdId] = useState();
  const [filtersParsed, setFilterParsed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [activePage, setActivePage] = useState({
    name: "",
    activeStatus: false,
  });
  const [currentImageData, setCurrentImageData] = useState();
  const [inquiryLoading, setInquiryLoading] = useState(false);

  // redux-store
  const cart = useSelector((state) => state.cart);
  const { localCart, localCartLoading, addMultipleProductsLoading } = cart;
  const [breadCrumCategory, setBreadCrumCategory] = useState("");
  const [breadcrumId, setBreadcrumId] = useState("");
  // const categoryState = useSelector((state) => state.category);
  // const { category } = categoryState;
  const prodState = useSelector((state) => state.products);
  const { isProductByIdLoading, productDetail } = prodState;
  const userDetails = useSelector((state) => state.user.userDetails);
  const currentProductDetails = useSelector(
    (state) => state?.products?.productDetail?.product
  );

  const productFilters = useSelector(
    (state) => state.products.productDetail?.filters
  );
  const categoryState = useSelector((state) => state.category);
  const { category: currentCategory } = categoryState;
  const [filterColourData, setFilterColourData] = useState([]);
  const [uniqueColourData, setUniqueColourData] = useState([]);
  useEffect(() => {
    let colourFilteredData = productDetail?.filters?.filter((item) => {
      return (
        item?.filter_name.toLowerCase().includes("Colour".toLowerCase()) ||
        item?.filter_name.toLowerCase().includes("Color".toLowerCase())
      );
    });

    let colorData = [];
    if (colourFilteredData?.length > 0) {
      colourFilteredData?.map((item) => {
        item.filter_data.map((element) => {
          colorData.push(element);
        });
      });
      setFilterColourData(colorData);
    }
  }, [productDetail, productFilters]);

  useEffect(() => {
    let uniquePropertyValue = filterColourData?.filter(
      (a, i) =>
        filterColourData.findIndex(
          (s) => a.property_value === s.property_value
        ) === i
    );

    setUniqueColourData(uniquePropertyValue);
  }, [filterColourData]);

  // react-redux
  const dispatch = useDispatch();
  const history = useHistory();

  const key = "updatable";

  const [form] = Form.useForm();
  const [slug, setSlug] = useState("");
  useEffect(() => {
    const splittedPath = window.location.pathname.split("/");
    const slugName = splittedPath[splittedPath.length - 2];
    const productId = splittedPath[splittedPath.length - 1];
    setSlug(slugName);
    setProdId(productId);
    dispatch(getProductByIdReq(productId));
    setUniqueColourData([]);
    setFilterColourData([]);
    // dispatch(getAllCategoriesReq);
  }, [window.location.pathname]);

  useEffect(() => {
    if (currentProductDetails?.getImage) {
      let masterImage = currentProductDetails?.getImage?.filter(
        (mImage) => mImage.image_type == 1
      );

      setCurrentImageData(masterImage);
    }
  }, [currentProductDetails]);

  useEffect(() => {
    setCartUpdated(false);
  }, [cartUpdated]);

  useEffect(() => {
    if (productFilters) {
      let relatedFilters = {};
      productFilters.map((proFilter) => {
        relatedFilters[proFilter.filter_name] = proFilter.filter_data;
      });
      setProdFilters(relatedFilters);
      setFilterParsed(true);
    }
  }, [productFilters]);

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      }
    });
  });

  const callbackFunc = (type) => {
    notification[type]({
      key,
      message: type.toUpperCase(),
      description: `Item added ${type}.`,
      duration: 2,
      placement: "bottomRight",
    });
  };

  const handleAddToCart = (type) => {
    if (type === "buyNow" || type == "detailPriceDescription") {
      history.push("/cart");
    }

    if (parseInt(currentProductDetails.quantity) <= 0) {
      return notification["error"]({
        message: "Error",
        description: "Product is out of stock.",
      });
    }

    if (userDetails?.api_token) {
      let data = {
        product_id: parseInt(currentProductDetails.id),
        product_quantity: count,
        product_name: currentProductDetails.name,
        category_name: currentProductDetails.category_id,
        description: currentProductDetails.description,
        image_url: currentImageData[0].image_url,
      };
      dispatch(
        addMultipleProductReq([data], userDetails.api_token, callbackFunc)
      );
    } else {
      const itemData = [
        {
          product_id: parseInt(currentProductDetails.id),
          product_quantity: count,
          product_price: Number(currentProductDetails.price),
          product_name: currentProductDetails.name,
          category_name: currentProductDetails.category_id,
          description: currentProductDetails.description,
          image_url: currentImageData[0]?.image_url,
          tax: currentProductDetails.tax,
          short_description: currentProductDetails.short_description,
          productStock: parseInt(currentProductDetails.quantity),
        },
      ];

      if (localCart?.length) {
        const duplicate = localCart.find(
          (product) => product.product_id == currentProductDetails.id
        );

        const duplicateIndex = localCart.findIndex(
          (product) => product.product_id == currentProductDetails.id
        );
        if (duplicate) {
          if (
            duplicate.product_quantity + count >
            parseInt(currentProductDetails.quantity)
          ) {
            return notification["error"]({
              message: "Error",
              description: "No more products found",
            });
          } else {
            const newData = localCart;
            newData[duplicateIndex] = {
              ...duplicate,
              product_quantity: duplicate.product_quantity + count,
            };
            dispatch(updateLocalCartItems(newData, "undefined", callbackFunc));
          }
        } else {
          dispatch(
            updateLocalCartItems(
              [...localCart, itemData[0]],
              "undefined",
              callbackFunc
            )
          );
        }
      } else {
        dispatch(updateLocalCartItems(itemData, "undefined", callbackFunc));
      }
    }
  };

  function inquiryCallback(response) {
    setInquiryLoading(false);
    setShowPopup(false);
    Modal[response.title.toLowerCase()]({
      title: response.title,
      content: response.message,
    });
  }

  const onFinish = (values) => {
    form.resetFields();
    dispatch(comparePrice(values, inquiryCallback));
    setInquiryLoading(true);
  };

  const handleUpdate = (name, activeStatus) => {
    setActivePage({ name, activeStatus });
  };

  const handleImageHover = (imgData) => {
    setCurrentImageData([imgData]);
  };

  const detailPriceDescription = () => {
    if (currentProductDetails?.price_status === "1") {
      return currentProductDetails?.price_description;
    } else {
      return (
        <span className="newPrice detail-newPrice currencyINR">
          {convertToPriceVal(currentProductDetails?.price)}{" "}
          <span style={{ fontSize: "15px", color: "rgb(157, 144, 144)" }}>
            (ex. VAT)
          </span>
        </span>
      );
    }
  };
  const detailPriceDescriptionIncVta = () => {
    if (currentProductDetails?.price_status === "1") {
      return;
    } else {
      return (
        <span style={{ color: "#9d9090" }}>
          &#163;
          {convertToPriceVal(
            Number(currentProductDetails?.price) +
            (Number(currentProductDetails?.price) *
              currentProductDetails?.tax) /
            100
          )}{" "}
          (inc. VAT)
        </span>
      );
    }
  };

  const stockQuantity = () => {
    if (currentProductDetails?.quantity > "0") {
      return <span className="detailStockIn">In Stock</span>;
    } else {
      return <span className="detailStockOut">Out of Stock</span>;
    }
  };

  const getCategory = (catId) => {
    dispatch(getCategoryByIdReq(catId));
  };

  useEffect(() => {
    categoryState?.allCategories?.map((item) => {
      item?.child &&
        item?.child?.map((one) => {
          if (one.name == currentCategory.name) {
            setBreadCrumCategory(item.name);
            setBreadcrumId(item.id);
          }
        });
    });
  }, [categoryState, currentCategory]);
  const handleCopy = (e) => {
    e.preventDefault();

    e.nativeEvent.stopImmediatePropagation();
    setShowPopup(true);
  };
  window.getSelection().toString();
  const [colorFilterData, setColorFilterData] = useState();
  const handleColorFilter = (e) => {
    setColorFilterData(e.target.value);
  };
  useEffect(() => {
    if (colorFilterData && slug) {
      history.push(`/product-details/${encodeURI(slug)}/${colorFilterData}`);
    }
  }, [colorFilterData, slug]);

  return (
    <div>
      {isProductByIdLoading ? (
        <div className="container productDetailContainer">
          <div className=" breadCrumb-sticky pageHeader">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              {!breadCrumCategory ? (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${currentCategory.id}&page=1&search=&filters=`}
                  >
                    {currentCategory.name}
                  </Link>
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${breadcrumId}&page=1&search=&filters=`}
                  >
                    {breadCrumCategory}
                  </Link>
                </Breadcrumb.Item>
              )}

              <Breadcrumb.Item>
                <Link
                  to={`/product-list?category_id=${productDetail?.product?.getProductCategoryName?.[0]?.id}&page=1&search=&filters=`}
                  onClick={() =>
                    getCategory(
                      productDetail?.product?.getProductCategoryName?.[0]?.id
                    )
                  }
                >
                  {productDetail?.product?.getProductCategoryName?.[0]?.name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{productDetail?.product?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row align="top" className="details-page-content">
            <Col xs={2} sm={2} md={2} xl={1} lg={2} className="sticky">
              <div align="top" className="thumb-imgs">
                {[...Array(5)].map((d, index) => {
                  return (
                    <div
                      className="thumb-images productDetailThumb"
                      key={index}
                    >
                      <img
                        src="../../images/dummy-image-square.jpg"
                        alt="dummy-img"
                        className="productDetailThumbImg"
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col
              xs={22}
              sm={22}
              md={22}
              xl={9}
              lg={22}
              className="main-img-container sticky"
            >
              <div className="mainImageDiv">
                <div>
                  <img
                    src="../../images/dummy-image-square.jpg"
                    alt="dummy-img"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={14}>
              <Skeleton
                loading={true}
                active
                paragraph={{ rows: 20 }}
              ></Skeleton>
            </Col>
          </Row>
        </div>
      ) : productFilters && !filtersParsed ? (
        <div className="container productDetailContainer">
          <div className=" breadCrumb-sticky pageHeader">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              {!breadCrumCategory ? (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${currentCategory.id}&page=1&search=&filters=`}
                  >
                    {currentCategory.name}
                  </Link>
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${breadcrumId}&page=1&search=&filters=`}
                  >
                    {breadCrumCategory}
                  </Link>
                </Breadcrumb.Item>
              )}

              <Breadcrumb.Item>
                <Link
                  to={`/product-list?category_id=${productDetail?.product?.getProductCategoryName?.[0]?.id}&page=1&search=&filters=`}
                  onClick={() =>
                    getCategory(
                      productDetail?.product?.getProductCategoryName?.[0]?.id
                    )
                  }
                >
                  {productDetail?.product?.getProductCategoryName?.[0]?.name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{productDetail?.product?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Loading />
        </div>
      ) : currentProductDetails && currentProductDetails?.name ? (
        <div className="container productDetailContainer">
          <div className=" breadCrumb-sticky pageHeader">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              {!breadCrumCategory ? (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${currentCategory.id}&page=1&search=&filters=`}
                  >
                    {currentCategory.name}
                  </Link>
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${breadcrumId}&page=1&search=&filters=`}
                  >
                    {breadCrumCategory}
                  </Link>
                </Breadcrumb.Item>
              )}
              {currentCategory.name && breadCrumCategory ? (
                <Breadcrumb.Item>
                  <Link
                    to={`/product-list?category_id=${productDetail?.product?.getProductCategoryName?.[0]?.id}&page=1&search=&filters=`}
                    onClick={() =>
                      getCategory(
                        productDetail?.product?.getProductCategoryName?.[0]?.id
                      )
                    }
                  >
                    {productDetail?.product?.getProductCategoryName?.[0]?.name}
                  </Link>
                </Breadcrumb.Item>
              ) : (
                ""
              )}

              <Breadcrumb.Item>{productDetail?.product?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row align="top" className="details-page-content">
            <Col xs={2} sm={2} md={2} xl={1} lg={2} className="sticky">
              <div align="top" className="thumb-imgs">
                {currentProductDetails?.getImage &&
                  currentProductDetails.getImage.map((imageData, i) => {
                    return (
                      <div className="thumb-images productDetailThumb" key={i}>
                        <img
                          className="productDetailThumbImg"
                          src={imageData.image_url}
                          onMouseOver={() => handleImageHover(imageData)}
                        />
                      </div>
                    );
                  })}
              </div>
            </Col>
            <Col
              xs={22}
              sm={22}
              md={22}
              xl={9}
              lg={22}
              className="main-img-container sticky"
            >
              <div className="mainImageDiv">
                <div className="productImageDiv">
                  <ImagePreview
                    images={currentProductDetails?.getImage}
                    defaultImage={
                      currentImageData?.length > 0
                        ? currentImageData[0]?.image_url
                        : []
                    }
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={14}>
              <Popover
                content={
                  <Spin spinning={inquiryLoading}>
                    <Row gutter={[0, 6]}>
                      <Col span={24}>
                        <p style={{ margin: "0" }}>
                          Help us to compare price for this item from another
                          website or online store.
                        </p>
                      </Col>
                    </Row>
                    <Divider />
                    <Form layout="vertical" onFinish={onFinish} form={form}>
                      <Form.Item
                        name="name"
                        //currentImageData
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Postal Code",
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z ]+$/g.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          placeholder="input placeholder"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Email address",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid Email address",
                          },
                        ]}
                      >
                        <Input placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item label="Phone" name="phone">
                        <Input
                          placeholder="input placeholder"
                          onKeyPress={(e) => {
                            if (!/[0-9-+()/]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Site Link"
                        name="site_link"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Postal Code",
                          },
                        ]}
                      >
                        <Input placeholder="Please paste the link here" />
                      </Form.Item>
                      <Button type="primary" htmlType="submit" block>
                        Submit
                      </Button>
                    </Form>
                  </Spin>
                }
                title="You won’t get it cheaper. Full stop."
                trigger="click"
                visible={showPopup}
                placement="bottomLeft"
                onVisibleChange={() => setShowPopup(false)}
                overlayStyle={{ margin: "10px", maxWidth: "550px" }}
              >
                <div
                  className="detail-itemListName"
                  onCopy={(e) => handleCopy(e)}
                >
                  <p id="mail">{currentProductDetails.name.trim()}</p>
                </div>
              </Popover>
              <div className="itemListPrice rating-container">
                <div>
                  <span
                    className="newPrice priceTitle detail-newPrice"
                    loading={localCartLoading || addMultipleProductsLoading}
                    onClick={() => handleAddToCart("detailPriceDescription")}
                  >
                    {localCartLoading || addMultipleProductsLoading
                      ? detailPriceDescription()
                      : detailPriceDescription()}
                  </span>
                </div>
                <span>{stockQuantity()}</span>
              </div>
              <div>
                <span>{detailPriceDescriptionIncVta()}</span>
              </div>
              <div className="rating-container">
                <span>
                  <b>
                    Product Code:{" "}
                    <span> {currentProductDetails.product_code}</span>
                  </b>
                </span>
              </div>

              <div className="detail-itemListCaption primary">
                {parse(currentProductDetails.description)}
              </div>
              <Divider />
              <div className="qty-size-container">
                <div className="flex-half">
                  <div className="detailQuantityHeadin">Quantity</div>
                  <InputNumber
                    type="number"
                    min={1}
                    max={currentProductDetails.quantity}
                    defaultValue={1}
                    onChange={(value) => setCount(value)}
                  />
                </div>

                {uniqueColourData && uniqueColourData.length > 1 ? (
                  <div className="d-flex flex-wrap">
                    <div className="w-100 d-flex">Color</div>

                    <div className="d-flex">
                      <select
                        value={
                          currentProductDetails.id
                            ? currentProductDetails.id
                            : colorFilterData
                        }
                        onChange={handleColorFilter}
                        className="form-control form-control-sm form-select"
                      >
                        {uniqueColourData &&
                          uniqueColourData.map((item, index) => {
                            return (
                              <option key={index} value={item.product_id}>
                                {item.property_value}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {prodFilters?.size && (
                  <div className="flex-half">
                    <div className="detailQuantityHeadin">Size</div>
                    <Select defaultValue={6} style={{ width: 80 }}>
                      {prodFilters.size.map((pSize, i) => {
                        return (
                          <Option value={pSize.property_value} key={i}>
                            {pSize.property_value}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                )}
              </div>
              <Divider />
              <Row align="top" justify="space-between">
                <Col xs={24} sm={7} md={7} xl={7}>
                  <Button
                    // disabled={
                    //   currentProductDetails?.price_status === "1" ||
                    //   Number(currentProductDetails.price) === 0
                    // }
                    type="primary"
                    size={"large"}
                    loading={localCartLoading || addMultipleProductsLoading}
                    onClick={() => handleAddToCart("addToCart")}
                  >
                    {localCartLoading || addMultipleProductsLoading
                      ? "Adding to cart"
                      : "Add to Quote"}
                  </Button>
                </Col>
                <Col xs={24} sm={7} md={7} xl={7}>
                  <Button
                    className="ant-btn-secondary"
                    size={"large"}
                    onClick={() => handleUpdate("buybulk", true)}
                  >
                    Buy Bulk
                  </Button>
                </Col>
                <Col xs={24} sm={7} md={7} xl={7}></Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <Loading />
      )}
      {activePage.activeStatus && (
        <PopupModal
          pageInfo={activePage}
          func={handleUpdate}
          productId={prodId}
          productCode={currentProductDetails.product_code}
          productName={currentProductDetails.name}
        />
      )}
    </div>
  );
};

export default ProductDetail;

import React, { useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import { Row, Col, Typography, Divider } from "antd";
import { useHistory, Link } from "react-router-dom";
import './profile.css';

//Logos
import yourOrders from './your-orders.png';
import manageProfile from './edit-profile.png';
import payment from './payment-options.png';
import addresses from './address.png';
import changePasswordLogo from './change-password.png';

const { Title, Text } = Typography;

const YourAccount = () => {
    const [addressId, setAddressId] = useState(0);
    const history = useHistory();

    const cartState = useSelector(state => state.cart);
    const addressState = useSelector(state => state.address);

    useEffect(() => {
        if(addressState.existingAddressesList.length) {
            const primaryAddress = addressState.existingAddressesList.filter((add) => add.is_primary == 1);
            setAddressId(parseInt(primaryAddress[0].id));
        }
    }, [])

    return (
        <div className="container">
            <Row>
                <Title level={3}>Your Account</Title>
            </Row>
            <Divider />
            <Row justify="space-between" gutter={[24, 24]}>
                <Col span={8} xs={24} md={12} lg={8} xl={8} xxl={8}>
                    <Row justify="space-between" onClick={() => history.push('/your-account/order-history')} className="info-container">
                        <Col span={6}>
                            <img src={yourOrders} alt="your-orders" />
                        </Col>
                        <Col span={17}>
                            <Title level={4}>Your Orders</Title>
                            <Text>Track your previous orders</Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8} xxl={8}>
                    <Link
                        to={"/manage-profile"}
                    >
                        <Row justify="space-between"
                            //  onClick={() => history.push('/edit-profile')}
                            className="info-container"
                        >
                            <Col span={6}>
                                <img src={manageProfile} alt="manage-profile" />
                            </Col>
                            <Col span={17}>
                                <Title level={4}>Manage Profile</Title>
                                <Text>Edit or update your profile</Text>
                            </Col>
                        </Row>
                    </Link>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8} xxl={8}>
                    <Row justify="space-between" onClick={() => {
                        cartState.items?.cart?.length ? 
                        history.push(`/payment?addressId=${addressId}`) :
                        history.push('/cart')
                    }} className="info-container">
                        <Col span={6}>
                            <img src={payment} alt="payments" />
                        </Col>
                        <Col span={17}>
                            <Title level={4}>Payments</Title>
                            <Text>Manage your payments</Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8} xxl={8}>
                    <Row justify="space-between" onClick={() => history.push('/address')} className="info-container">
                        <Col span={6}>
                            <img src={addresses} alt="addresses" />
                        </Col>
                        <Col span={17}>
                            <Title level={4}>Your addresses</Title>
                            <Text>Edit or create address for orders</Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8} xxl={8}>
                    <Link
                        to={'change-password'}
                    >
                        <Row justify="space-between" className="info-container">
                            <Col span={6}>
                                <img src={changePasswordLogo} alt="change-password-logo" />
                            </Col>
                            <Col span={17}>
                                <Title level={4}>Change password</Title>
                                <Text>Edit or change your password</Text>
                            </Col>
                        </Row>
                    </Link>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8} xxl={8}></Col>
            </Row>
        </div>
    )
}

export default YourAccount;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Row, Divider, Typography, Pagination, Col } from "antd";
import { allReviews } from "../../Actions/AllReviews";
import "./allReviews.css"

const { Title } = Typography;

const AllReviews = () => {
    const allReviewState = useSelector((state) => state.allReviewReducer);
    const { allReviewResponse } = allReviewState;

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
          dispatch(allReviews());
      }, []);

    const onChange = (page) => {
        history.push(`/all-reviews?page=${page}`);
        dispatch(allReviews(page))
    }

    return (
        <div className="container allReviewsContainer">
            <Card className="allReviewsCard">
                <Row justify="space-around">
                    <Title level={3}>People Feedback</Title>
                </Row>
                <Divider />
                {allReviewResponse.data?.map((data) => {
                    return (
                        <Card style={{ marginBottom: "15px" }}>
                            <p><b>Name</b>: {data.name}</p>
                            <p><b>Rate</b>: {data.rate}/5</p>
                            <p><b>Review</b>: {data.comment}</p>
                        </Card>
                    )
                })}
                {allReviewResponse.data && (
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Pagination
                                defaultCurrent={allReviewResponse.pageNo}
                                pageSize={20}
                                total={allReviewResponse.allRecord}
                                showSizeChanger={false}
                                onChange={onChange}
                                responsive
                            />
                        </Col>
                    </Row>
                )}
            </Card>
        </div>
    );
};

export default AllReviews;

import {
    GET_USER_HISTORY_REQ,
    GET_USER_HISTORY_SUCCESS,
    GET_USER_HISTORY_ERROR
} from "../../Constants/OrderHistory"
import axios from "axios";
import * as api from "../../apis/common";

export {
    getUserOrderHistoryReq
}

function getUserOrderHistoryReq(token) {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_HISTORY_REQ });
            const result = await axios.post(
                api.GRAPHQL_API,
                {
                    query: `{
                        orderHistory{
                            success
                            statusCode
                            message
                            data{
                              order_id
                              status
                              products{
                                 user_id
                                 order_id
                                 product_id
                                 quantity
                                 product_name
                                 price
                                 created_at
                                 total_price
                                 vat
                              }
                            }
                         }
                      }`
                },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }
            );

            if (result?.data?.error) {
                dispatch({ type: GET_USER_HISTORY_ERROR, payload: result.data.error })
            } else {
                dispatch({ type: GET_USER_HISTORY_SUCCESS, payload: result?.data?.data?.orderHistory?.data })
            }
        }
        catch (error) {
            dispatch({ type: GET_USER_HISTORY_ERROR, payload: error });
        }
    }
}


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Divider,
  Menu,
  Checkbox,
  Button,
  Radio,
  Spin
} from "antd";
import "./addressPage.css";

// components
import ExistingAddresses from "../../components/ExistingAddresses";
import Loading from "../../components/Loading";

// actions
import {
  getAddressList,
  createAddress,
  updateAddress
} from "../../Actions/Address";
// import * as data from "./countries.json";

const { Option } = Select;
const { Title } = Typography;

const AddressPage = () => {
  // states
  // const [shippingStatesLoading, setShippingStatesLoading] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [editDefaultAddress, setEditDefaultAddress] = useState(false);
  const [showShippingAddress, setShowShippingAddress] = useState(true);
  const [billingForm, setBillingForm] = useState({
    location_type: "home",
    address_type: "b",
    landmark: "",
    id: "",
    is_primary: "0"
  });
  const [shippingForm, setShippingForm] = useState({
    location_type: "home",
    address_type: "s",
    landmark: "",
    is_primary: "0",
    id: ""
  });
  const [addressToEdit, setAddressToEdit] = useState({});
  const [isPrimary, setIsPrimary] = useState("0");
  const formName = addressToEdit?.address_type === "b" ? "billing" : "shipping";
  // const [activeCountryStates, setActiveCountryStates] = useState([]);

  // redux-store
  const userDetails = useSelector((state) => state.user.userDetails);
  const addressState = useSelector((state) => state.address);
  const {
    getListLoading,
    existingAddressesList,
    createAddressLoading,
    deleteAddressLoading,
    addressCreatedMessage,
    updateSuccess,
    updateAddressLoading
  } = addressState;
  const [currentPageName, setCurrentPageName] = useState("new");

  const history = useHistory();
  const [bForm] = Form.useForm();
  const [eForm] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails?.api_token) {
      dispatch(getAddressList(userDetails.api_token));
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (existingAddressesList?.length) {
      setCurrentPageName("existing");
    }
    bForm.resetFields();
    eForm.resetFields();
    setAddressToEdit({});
    setEditDefaultAddress(false)
  }, [addressCreatedMessage, updateSuccess, existingAddressesList]);

  const onFinish = (values) => {
    dispatch(
      updateAddress(
        {
          ...addressToEdit,
          ...values,
          is_primary: editDefaultAddress
            ? "1"
            : addressToEdit?.is_primary.toString()
        },
        userDetails?.api_token
      )
    );
  };

  const onCreateAddress = () => {
    if (showShippingAddress) {
      dispatch(
        createAddress(
          [{ ...billingForm, is_primary: isPrimary, address_type: "a" }],
          userDetails.api_token
        )
      );
    } else {
      dispatch(
        createAddress([billingForm, shippingForm], userDetails.api_token)
      );
    }
  };

  const handleMenuChange = (value) => {
    if (value.key === "new") {
      bForm.resetFields();
      eForm.resetFields();
      setShowShippingAddress(true);
      setIsPrimary("0");

      setShippingForm({
        location_type: "home",
        address_type: "s",
        landmark: "",
        is_primary: "0",
        id: ""
      });

      setBillingForm({
        location_type: "home",
        address_type: "b",
        landmark: "",
        id: "",
        is_primary: "0"
      });
    }
    setCurrentPageName(value.key);
  };

  const handleEdit = (addr) => {
    eForm.resetFields();

    if (addr?.user_id) {
      delete addr.user_id;
    }

    if (addr.address_type === "b") {
      setBillingForm(addr);
    } else {
      setShippingForm(addr);
    }

    setAddressToEdit(addr);
    setCurrentPageName("edit");
  };

  const handleFieldValues = (e, formName) => {
    if (formName === "billing") {
      setBillingForm({ ...billingForm, [e.target.name]: e.target.value });
    } else {
      setShippingForm({ ...shippingForm, [e.target.name]: e.target.value });
    }
  };

  // const onCountryChange = (countryCode, nameOfForm) => {
  //   if (nameOfForm === "billing" || nameOfForm === "edit") {
  //     setActiveCountryStates(data.countries[countryCode]);
  //   } else if (nameOfForm === "shipping") {
  //     setActiveCountryStates(data.countries[countryCode]);
  //   }
  // }

  const addressTag = (value) => {
    switch (value) {
      case "s":
      case "a":
        return "Shipping Address"
      case "b":
        return "Billing Address"
      default:
        return ""
    }
  }

  return (
    <div className="selectAddress">
      <div className="selectAddressContainer">
        {getListLoading ? (
          <Loading />
        ) : (
          <Row justify="left" align="left">
            <Col span={24}>
              <Menu
                onClick={handleMenuChange}
                selectedKeys={[currentPageName]}
                mode="horizontal"
                className="address-menu"
              >
                {existingAddressesList?.length ? (
                  <Menu.Item key="existing">Existing Addresses</Menu.Item>
                ) : (
                  <></>
                )}
                <Menu.Item key="new">Add New</Menu.Item>
                {addressToEdit?.id && <Menu.Item key="edit">Edit</Menu.Item>}
              </Menu>
            </Col>
          </Row>
        )}

        {createAddressLoading || getListLoading ? (
          <>
            <Divider />
            <Loading size={"large"} />
          </>
        ) : currentPageName === "existing" && existingAddressesList?.length ? (
          <Spin spinning={deleteAddressLoading}>
            <ExistingAddresses
              addAddClick={setShowNewAddress}
              currentState={showNewAddress}
              edit={handleEdit}
              existingAddressesList={existingAddressesList}
            />
          </Spin>
        ) : currentPageName === "new" ? (
          <>
            <Form
              form={bForm}
              layout="vertical"
              onFinish={onCreateAddress}
              autoComplete="off"
            >
              <Title level={4}>Billing Address</Title>
              <Divider />
              <Row justify="space-between">
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Full Name - Billing"
                    label="Full Name"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="full_name"
                      value={billingForm?.full_name}
                      onChange={(e) => handleFieldValues(e, "billing")}
                      placeholder="Enter your full name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Phone - Billing"
                    label="Phone"
                    rules={[{ required: true }]}
                  >
                    <Input
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      type="text"
                      name="phone"
                      value={billingForm?.phone}
                      onChange={(e) => handleFieldValues(e, "billing")}
                      onKeyPress={(e) => {
                        if (!/[0-9-+()/]/.test(e.key)) { e.preventDefault() }
                      }}
                      placeholder="Enter your phone number"
                    // className="addressPhone"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Address1 - Billing"
                    label="Address Line 1"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="address"
                      value={billingForm?.address}
                      onChange={(e) => handleFieldValues(e, "billing")}
                      placeholder="Enter address line 1"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item name="Address2 - Billing" label="Address Line 2">
                    <Input
                      name="landmark"
                      value={billingForm?.landmark}
                      onChange={(e) => handleFieldValues(e, "billing")}
                      placeholder="Enter address line 2"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Country - Billing"
                    label="Country"
                    rules={[{ required: true }]}
                  >
                    <Select
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      name="country"
                      onChange={(value) => {
                        // bForm.resetFields(['State - Billing'])
                        // delete billingForm.state;
                        setBillingForm({ ...billingForm, country: value });
                        // onCountryChange(value, "billing");
                      }
                      }
                      placeholder="Select a country"
                      allowClear
                    >
                      <Option value="Europe">Europe</Option>
                      <Option value="United Kingdom">United Kingdom</Option>
                      <Option value="United States">United States</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >

                  <Form.Item
                    name="State - Billing"
                    label="State/Province/Region"
                    rules={[{ required: true }]}
                    initialValue={billingForm?.state}
                  >
                    <Input
                      placeholder="Enter State name"
                      onChange={(e) =>
                        setBillingForm({ ...billingForm, state: e.target.value })
                      }
                    />
                    {/* <Select
                      name="state"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      value={billingForm?.state}
                      onChange={(value) =>
                        setBillingForm({ ...billingForm, state: value })
                      }
                      placeholder="Select a state"
                      allowClear
                    >
                      {
                        (activeCountryStates || []).map((activeStates, i) => {
                          return <Option value={activeStates?.name} key={i}>{activeStates?.name}</Option>
                        })
                      }
                    </Select> */}
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Town/City - Billing"
                    label="Town/City"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="city"
                      value={billingForm?.city}
                      onChange={(e) => handleFieldValues(e, "billing")}
                      placeholder="Enter city name"
                      onKeyPress={(e) => {
                        if (!/[a-zA-z\s]/.test(e.key)) { e.preventDefault() }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Post Code - Billing"
                    label="Post/Zip Code"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="postal_code"
                      value={billingForm?.postal_code}
                      onChange={(e) => handleFieldValues(e, "billing")}
                      placeholder="Enter post code"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="Location Type - Billing"
                    label="Location Type"
                    initialValue={"home"}
                  >
                    <Radio.Group
                      name="location_type"
                      onChange={(e) =>
                        setBillingForm({
                          ...billingForm,
                          location_type: e.target.value
                        })
                      }
                    // defaultValue={"home"}
                    >
                      <Radio value={"home"}>Home</Radio>
                      <Radio value={"office"}>Office</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={24}>
                  <Checkbox
                    onChange={() =>
                      setShowShippingAddress(!showShippingAddress)
                    }
                    checked={showShippingAddress}
                  >
                    <b>Shipping address is same as Billing address</b>
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    onChange={(e) => {
                      setIsPrimary(e.target.checked ? "1" : "0");
                      setShippingForm({
                        ...shippingForm,
                        is_primary: e.target.checked ? "1" : "0"
                      });
                    }}
                  >
                    <b>Set as my default address</b>
                  </Checkbox>
                </Col>

                {!showShippingAddress && (
                  <>
                    <Divider />
                    <Title level={4} style={{ marginBottom: 0 }}>
                      Shipping Address
                    </Title>
                    <Divider />
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="Full Name - Shipping"
                        label="Full Name"
                        rules={[{ required: true }]}
                      >
                        <Input
                          name="full_name"
                          value={shippingForm?.full_name}
                          onChange={(e) => handleFieldValues(e, "shipping")}
                          placeholder="Enter your full name"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="Phone - Shipping"
                        label="Phone"
                        rules={[{ required: true }]}
                      >
                        <Input
                          onPaste={(e) => {
                            e.preventDefault()
                            return false;
                          }}
                          name="phone"
                          value={shippingForm?.phone}
                          onChange={(e) => handleFieldValues(e, "shipping")}
                          onKeyPress={(e) => {
                            if (!/[0-9-+()/]/.test(e.key)) { e.preventDefault() }
                          }}
                          placeholder="Enter your phone number"
                          type="text"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="Address1 - Shipping"
                        label="Address Line 1"
                        rules={[{ required: true }]}
                      >
                        <Input
                          name="address"
                          value={shippingForm?.address}
                          onChange={(e) => handleFieldValues(e, "shipping")}
                          placeholder="Enter address line 1"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item name="Adderss2 - Shipping" label="Address Line 2">
                        <Input
                          name="landmark"
                          value={shippingForm?.landmark}
                          onChange={(e) => handleFieldValues(e, "shipping")}
                          placeholder="Enter address line 2"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >

                      <Form.Item
                        name="Country - Shipping"
                        label="Country"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          name="country"
                          onChange={(value) => {
                            // bForm.resetFields(['state - Shipping'])
                            // delete shippingForm.state;
                            setShippingForm({ ...shippingForm, country: value });
                            // onCountryChange(value, "shipping");
                          }
                          }
                          placeholder="Select a country"
                          allowClear
                        >
                          <Option value="Europe">Europe</Option>
                          <Option value="United Kingdom">United Kingdom</Option>
                          <Option value="United States">United States</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="state - Shipping"
                        label="State/Province/Region"
                        rules={[{ required: true }]}
                        initialValue={shippingForm?.state}
                      >
                        <Input
                          placeholder="Enter State name"
                          onChange={(e) =>
                            setShippingForm({ ...shippingForm, state: e.target.value })
                          }
                        />
                        {/* <Select
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          name="state"
                          value={shippingForm?.state}
                          onChange={(value) =>
                            setShippingForm({ ...shippingForm, state: value })
                          }
                          placeholder="Select a state"
                          allowClear
                        >
                          {
                            (activeCountryStates || []).map((activeStates, i) => {
                              return <Option value={activeStates?.name} key={i}>{activeStates?.name}</Option>
                            })
                          }
                        </Select> */}
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="Town/City - Shipping"
                        label="Town/City"
                        rules={[{ required: true }]}
                      >
                        <Input
                          name="city"
                          value={shippingForm?.city}
                          onChange={(e) => handleFieldValues(e, "shipping")}
                          placeholder="Enter city name"
                          onKeyPress={(e) => {
                            if (!/[a-zA-z\s]/.test(e.key)) { e.preventDefault() }
                          }}
                        />
                      </Form.Item>

                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="Post Code - Shipping"
                        label="Post/Zip Code"
                        rules={[{ required: true }]}
                      >
                        <Input
                          name="postal_code"
                          value={shippingForm?.postal_code}
                          onChange={(e) => handleFieldValues(e, "shipping")}
                          placeholder="Enter post code"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={11}
                      xs={{ span: 24 }}
                      lg={{ span: 11 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 11 }}
                    >
                      <Form.Item
                        name="Location Type - Shipping"
                        label="Location Type"
                        initialValue={"home"}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            setShippingForm({
                              ...shippingForm,
                              location_type: e.target.value
                            })
                          }
                        >
                          <Radio value={"home"}>Home</Radio>
                          <Radio value={"office"}>Office</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Divider />
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add address
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        ) : currentPageName === "edit" && addressToEdit ? (
          <Spin spinning={updateAddressLoading}>
            <Title level={4}>{addressTag(addressToEdit.address_type)}</Title>
            <Form
              form={eForm}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Divider />
              <Row justify="space-between">
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="full_name"
                    label="Full Name"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.full_name}
                  >
                    <Input
                      name="full_name"
                      value={addressToEdit?.full_name}
                      onChange={(e) => handleFieldValues(e, formName)}
                      placeholder="Enter your full name"
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.phone}
                  >
                    <Input
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      name="phone"
                      value={addressToEdit?.phone}
                      // defaultValue={addressToEdit?.phone}
                      onChange={(e) => handleFieldValues(e, formName)}
                      onKeyPress={(e) => {
                        if (!/[0-9-+()/]/.test(e.key)) { e.preventDefault() }
                      }}
                      type="text"
                      placeholder="Enter your phone number"
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="address"
                    label="Address Line 1"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.address}
                  >
                    <Input
                      name="address"
                      value={addressToEdit?.address}
                      // defaultValue={addressToEdit?.address}
                      onChange={(e) => handleFieldValues(e, formName)}
                      placeholder="Enter address line 1"
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="landmark"
                    label="Address Line 2"
                    initialValue={addressToEdit?.landmark}
                  >
                    <Input
                      name="landmark"
                      value={addressToEdit?.landmark}
                      // defaultValue={addressToEdit?.landmark}
                      onChange={(e) => handleFieldValues(e, formName)}
                      placeholder="Enter address line 2"
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.country}
                  >
                    <Select
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      name="country"
                      value={addressToEdit?.country}
                      // defaultValue={addressToEdit?.country}
                      onChange={(value) => {
                        // eForm.resetFields(['state'])
                        if (formName == "billing") {
                          // delete billingForm.state;
                          setBillingForm({ ...billingForm, country: value });
                        } else {
                          // delete shippingForm.state;
                          setShippingForm({ ...shippingForm, country: value });
                        }
                        // onCountryChange(value, formName);
                      }}
                      placeholder="Select a country"
                      allowClear
                    >
                      <Option value="Europe">Europe</Option>
                      <Option value="United Kingdom">United Kingdom</Option>
                      <Option value="United States">United States</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="state"
                    label="State/Province/Region"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.state}

                  >
                    <Input
                      placeholder="Enter State name"
                      onChange={(e) => {
                        formName == "billing"
                          ? setBillingForm({ ...billingForm, state: e.target.value })
                          : setShippingForm({ ...shippingForm, state: e.target.value });
                      }}
                    />
                    {/* <Select
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      name="state"
                      // value={addressToEdit?.state}
                      defaultValue={addressToEdit?.state}
                      onChange={(value) => {
                        formName == "billing"
                          ? setBillingForm({ ...billingForm, state: value })
                          : setShippingForm({ ...shippingForm, state: value });
                      }}
                      placeholder="Select a state"
                      allowClear
                    >
                      {
                        (activeCountryStates || []).map((activeStates, i) => {
                          return <Option value={activeStates?.name} key={i}>{activeStates?.name}</Option>
                        })
                      }
                    </Select> */}
                  </Form.Item>
                </Col>

                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="city"
                    label="Town/City"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.city}
                  >
                    <Input
                      name="city"
                      value={addressToEdit?.city}
                      // defaultValue={addressToEdit?.city}
                      onChange={(e) => handleFieldValues(e, formName)}
                      placeholder="Enter city name"
                      onKeyPress={(e) => {
                        if (!/[a-zA-z\s]/.test(e.key)) { e.preventDefault() }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="postal_code"
                    label="Post/Zip Code"
                    rules={[{ required: true }]}
                    initialValue={addressToEdit?.postal_code}
                  >
                    <Input
                      name="postal_code"
                      value={addressToEdit?.postal_code}
                      // defaultValue={addressToEdit?.postal_code}
                      onChange={(e) => handleFieldValues(e, formName)}
                      placeholder="Enter post code"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={11}
                  xs={{ span: 24 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                >
                  <Form.Item
                    name="location_type"
                    label="Location Type"
                    initialValue={addressToEdit?.location_type}
                  >
                    <Radio.Group
                      name="location_type"
                      onChange={(e) => {
                        formName == "billing"
                          ? setBillingForm({
                            ...billingForm,
                            location_type: e.target.value
                          })
                          : setShippingForm({
                            ...shippingForm,
                            location_type: e.target.value
                          });
                      }}
                    // defaultValue={addressToEdit.location_type}
                    >
                      <Radio value={"home"}>Home</Radio>
                      <Radio value={"office"}>Office</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {addressToEdit?.is_primary == 0 && (
                  <>
                    <Divider />
                    <Col span={24}>
                      <Form.Item>
                        <Checkbox
                          onChange={(e) =>
                            setEditDefaultAddress(e.target.checked)
                          }
                        >
                          <b>Set as my default address</b>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Divider />
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update address
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        ) : (
          <></>
        )}
      </div>
    </div >
  );
};

export default AddressPage;

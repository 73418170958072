import {
  GET_PRODUCT_REVIEWS_REQ,
  GET_PRODUCT_REVIEWS_SUCCESS,
  GET_PRODUCT_REVIEWS_ERROR,
  CREATE_REVIEW_REQ,
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_ERROR
} from "../../Constants/Reviews";
import axios from "axios";
import * as api from "../../apis/common";

export {
  getProductReviewsReq,
  createReview
}

function getProductReviewsReq() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCT_REVIEWS_REQ });

      const result = await axios.post(api.GRAPHQL_API, {
        query: api.GET_PRODUCT_REVIEWS
      });

      if (result?.data) {
        dispatch({ type: GET_PRODUCT_REVIEWS_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_REVIEWS_ERROR });
      }
    } catch (e) {
      dispatch({ type: GET_PRODUCT_REVIEWS_ERROR, payload: e });
    }
  };
}

function createReview(rating, review, productId) {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_REVIEW_REQ });

      const result = await axios.post(api.GRAPHQL_API, {
        query: `mutation{
          createCustomerReview(review: ${review}, 
            rating:${rating},product_id: ${productId}){
            success
            statusCode
            message
          }
        }`
      });

      if (result?.data?.data) {
        dispatch({ type: CREATE_REVIEW_SUCCESS });
      } else {
        dispatch({ type: CREATE_REVIEW_ERROR });
      }
    } catch (e) {
      dispatch({ type: CREATE_REVIEW_ERROR });
    }
  };
}

import {
    SET_MANAGE_PROFILE_REQ,
    SET_MANAGE_PROFILE_SUCCESS,
    SET_MANAGE_PROFILE_ERROR,
    UPDATE_MANAGE_PROFILE_REQ,
    UPDATE_MANAGE_PROFILE_SUCCESS,
    UPDATE_MANAGE_PROFILE_ERROR,
    RESET_UPDATE_PROFILE
} from "../../Constants/ManageProfile";
import {
    SET_UPDATED_USER_DETAILS,
} from "../../Constants/User";
import axios from "axios";
import * as api from "../../apis/common";

export {
    manageProfileReq,
    updateManageProfileReq
}

function manageProfileReq(token) {
    return async (dispatch) => {
        try {
            dispatch({ type: SET_MANAGE_PROFILE_REQ });
            const result = await axios.post(api.GRAPHQL_API, {
                query: `{
                    managerProfile{
                       success
                       statusCode
                       message
                       data{
                         id    
                        name
                        last_name
                        phone
                        email
                        postal_code
                      }
                    }
                  }`
            },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });
            if (result?.data?.data?.managerProfile) {
                dispatch({
                    type: SET_MANAGE_PROFILE_SUCCESS,
                    payload: result?.data?.data?.managerProfile
                });
            }
            else {
                dispatch({
                    type: SET_MANAGE_PROFILE_ERROR,
                    payload: result.data
                });
            }
        }
        catch (error) {
            dispatch({
                type: SET_MANAGE_PROFILE_ERROR,
                payload: error
            });
        }
    };
}


function updateManageProfileReq(obj, token) {
    const { first_name, last_name, phone, postal_code } = obj;
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_MANAGE_PROFILE_REQ });
            const result = await axios.post(api.GRAPHQL_API, {
                query: `mutation{
                    updateProfile(
                        first_name: "${first_name}",
                        last_name: "${last_name}",
                        phone: "${phone}",
                        postal_code: "${postal_code}"){
                       success
                       statusCode
                       message
                       data{
                          id    
                          name
                          last_name
                          phone
                          email
                          postal_code
                      }
                    }
                  }`
            },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });

            if (result?.data?.data) {
                dispatch({
                    type: SET_UPDATED_USER_DETAILS,
                    payload: result?.data?.data?.updateProfile?.data
                });
                dispatch({
                    type: UPDATE_MANAGE_PROFILE_SUCCESS,
                    payload: result.data.data.updateProfile
                })
            }
            else {
                dispatch({
                    type: UPDATE_MANAGE_PROFILE_ERROR,
                    payload: result.data.data,
                })
            }
        }
        catch (error) {
            dispatch({
                type: UPDATE_MANAGE_PROFILE_ERROR,
                payload: error
            })
        }
    };
}

export function resetUpdateProfile() {
    return async (dispatch) => {
        dispatch({ type: RESET_UPDATE_PROFILE })
    }
}
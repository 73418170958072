import React from "react";
import './Error.css'

const Error = () => {
  return (
    <div className="errorPage">
      <div className='container errorPageContainer'>
        <div className="errorPageLogo">
          <a href="/">
            <img alt="" className="" src="images/new-eagle-logo-black-font.svg" style={{ width: "150px", height: "150px" }} />
          </a>
        </div>
        <div className="errorPageInfo row">
          <div className="col-lg-6 col-md-6 col-12">
            <h3 className='errorPageOops'>Oops!</h3>
            <p className='errorPageCantFind'>We can not seem to find the page you&apos;re looking for.</p>
            <h5 className='errorPageError'>Error code: 404</h5>
            <p className='errorPageGoLink'>Go to Eagle work solutions&apos;s<a href='/' className='errorPageHome'> Home </a> Page </p>
          </div>
          <div className="col-lg-6 col-md-6 col-12 errorPageImg">
            <img alt="" className="" src="images/error-page.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Typography,
  Spin,
  Modal,
  Divider,
} from "antd";

import { resetInitialState } from "../../Actions/User/actions";

// actions
import { createUserReq } from "../../Actions/User/actions";
const { Title, Text } = Typography;

const Register = (props) => {
  const user = useSelector((state) => state.user);
  const { createUserLoading, createUserResponse } = user;
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onModalOk = () => {
    dispatch(resetInitialState());
    onReset();
    if (
      createUserResponse.message ===
      "You have already created an account, Please login"
    ) {
      props.func("login", true);
    }
  };

  const handleSubmit = (values) => {
    dispatch(createUserReq(values));
  };

  function success() {
    if (
      createUserResponse.message ===
      "You have already created an account, Please login"
    ) {
      form.setFields([
        {
          name: "email",
          errors: [createUserResponse.message],
        },
      ]);
      return;
    }
    props.func("", false);
    Modal.success({
      title: "Success",
      content: createUserResponse?.message,
      onOk() {
        onModalOk();
      },
      okText:
        createUserResponse.message ===
        "You have already created an account, Please login"
          ? "Login"
          : "Ok",
    });
  }

  function error() {
    Modal.error({
      title: "Error",
      content: createUserResponse?.message,
      onOk() {
        onModalOk();
      },
    });
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Row justify="space-around ">
        <Title level={3}>Create an account</Title>
      </Row>
      <Divider />
      <Spin spinning={createUserLoading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(e) => {
            handleSubmit(e);
          }}
        >
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please enter your First Name" },
                ]}
              >
                <Input
                  size="middle"
                  placeholder="First Name"
                  onChange={() => {}}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please enter your Last Name" },
                ]}
              >
                <Input
                  size="middle"
                  type="text"
                  placeholder="Last Name"
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Mobile Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Mobile number",
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Value should contain just number",
                  },
                  {
                    max: 13,
                    message: "Value should be less than 13 character",
                  },
                  {
                    min: 10,
                    message: "Value should be more than 10 character",
                  },
                ]}
              >
                <Input
                  size="middle"
                  type="text"
                  placeholder="Mobile Number"
                  onChange={() => {}}
                  onKeyPress={(e) => {
                    if (!/[0-9-+()/]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Postal Code"
                name="postal"
                rules={[
                  { required: true, message: "Please enter your Postal Code" },
                  {
                    max: 10,
                    message: "Value should be less than 10 character",
                  },
                ]}
              >
                <Input
                  size="middle"
                  placeholder="Postal Code"
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your Email" },
                  { type: "email", message: "Please input a valid Email" },
                ]}
              >
                <Input
                  size="middle"
                  type="email"
                  placeholder="Email"
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your Password" },
                ]}
              >
                <Input.Password
                  minLength={8}
                  size="middle"
                  placeholder="Password"
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="space-around">
            <Text>We will send you an email to verify your details</Text>
          </Row>
          <br />
          <Row justify="space-around" span={24}>
            <Button type="primary" block htmlType="submit">
              continue
            </Button>
          </Row>
          <br />
        </Form>
      </Spin>
      <Row justify="space-around">
        <Text>Already have an account ?</Text>
      </Row>
      <Row justify="space-around">
        <Text
          className="create-acc-link"
          onClick={() => {
            props.func("login", true);
          }}
        >
          Sign in
        </Text>
      </Row>

      {!createUserLoading && createUserResponse?.success === "1" && success()}
      {!createUserLoading && createUserResponse?.success === "" && error()}
    </div>
  );
};

Register.propTypes = {
  func: PropTypes.func,
};

export default Register;

import {
    FEEDBACK_REQ,
    FEEDBACK_SUCCESS,
    FEEDBACK_ERROR,
    RESET_TO_INITIAL_STATE
} from "../../Constants/Feedback";

const initialState = {
    feedBackLoading: false,
    feedBackResponse: {},
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK_REQ:
            return {
                ...state,
                feedBackLoading: true,
                feedBackResponse: {}
            }
        case FEEDBACK_SUCCESS:
            return {
                ...state,
                feedBackLoading: false,
                feedBackResponse: action.payload
            }
        case FEEDBACK_ERROR:
            return {
                ...state,
                feedBackLoading: false,
                feedBackResponse: action.payload
            }
        case RESET_TO_INITIAL_STATE:
            return {
                ...state,
                feedBackLoading: false,
                feedBackResponse: {}
            }
        default:
            return state;
    }

}

export default feedbackReducer;



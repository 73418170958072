import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { Route } from "react-router-dom";

// components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { verifyToken } from "../../Actions/User/actions"

const Main = ({ component: Component, ...rest }) => {
  const userState = useSelector(state => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userState?.userDetails?.api_token) {
      dispatch(verifyToken(userState?.userDetails?.api_token));
    }
  }, [userState?.userDetails?.api_token, dispatch])

  useEffect(() => {
    if (Object.keys(userState?.userDetails).length === 0) {
      localStorage.removeItem("userInfo");
    }
  }, [userState?.userDetails])

  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <>
            <main className="content">
              <Header userInfo={userState?.userDetails} resetData={true}/>
              <Component {...props}  userInfo={userState?.userDetails} />
              <Footer userInfo={userState?.userDetails} />
            </main>
          </>
        )}
      />
    </>
  );
};

Main.propTypes = {
  component: PropTypes.func,
};

export default Main;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Card, Typography, Divider, Tag } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";

import './order.css'
import { getUserOrderHistoryReq } from '../../Actions/OrderHistory/actions'

const { Title } = Typography;

const OrderHistory = (props) => {
    // states
    const [dataSource, setDataSource] = useState();

    // redux-store
    const orderState = useSelector(state => state.order);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (props.userInfo?.api_token) {
            dispatch(getUserOrderHistoryReq(props.userInfo?.api_token));
        } else {
            history.push("/")
        }
    }, []);

    useEffect(() => {
        if (orderState?.orderHistorySuccess) {
            let newData = [];
            (orderState?.orderHistorySuccess || []).map((orderData, index) => {
                newData.push({ ...orderData, key: index })
            })
            setDataSource(newData);
        }
    }, [orderState])

    const pickColor = {
        placed: "purple",
        shipped: "blue",
        delivered: "green",
        cancel: "red"
    }

    const columns = [
        {
            title: 'Order Id',
            dataIndex: 'order_id',
            key: 'order_id',
            className: "orderIdColumn",
            width: "40%",
            sorter: (a, b) => a.order_id - b.order_id,
            render: (_, record) => {
                return <p>EAGL-#{record.order_id}</p>
            }
        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'order_date',
            className: "orderIdColumn",
            render: (_, record) => {
                return <p><Moment format="MM/DD/YYYY">
                    {record.products[0].created_at}
                </Moment></p>

            }
        },
        {
            title: 'Order Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: "orderIdColumn",
            render: (_, record) => {
                return <b><p className='green-color'>&#163;{amountCalculator(record.products)}</p></b>

            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'order_date',
            className: "orderIdColumn",
            render: (_, record) => {
                return <Tag color={pickColor[record.status]}>
                    {(record.status).toUpperCase()}
                </Tag>
            }
        }
    ];

    const amountCalculator = (products) => {
        let amount = 0;
        for (let i = 0; i < products.length; i++) {
            amount += products[i].total_price;
        }
        return amount.toFixed(2);
    }

    const columnsDetails = [
        {
            title: 'Product Title',
            dataIndex: 'product_name',
            key: 'product_name',
            align: "left",
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
            render: (_, record) => {
                return <Link to={`/product-details/${encodeURI(record.product_name.toLowerCase())}/${record.product_id}`} style={{ color: "#1890FF" }} >{record.product_name}</Link>
            }
        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'order_date',
            align: "center",
            render: (_, record) => {
                return (
                    <p><Moment format="MM/DD/YYYY">
                        {record.created_at}
                    </Moment></p>
                )

            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: "center",
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            align: "center",
            render: (_, record) => {
                return <>&#163;{record.price}</>

            }
        },
        {
            title: 'Vat',
            dataIndex: 'vat',
            key: 'vat',
            align: "center",
            render: (_, record) => {
                return <>{record.vat}%</>

            }
        },
        {
            title: 'Price (Inc. vat)',
            dataIndex: 'price',
            key: 'price',
            align: "center",
            render: (_, record) => {
                return <>&#163;{parseFloat(record.price) + ((parseFloat(record.price) * parseFloat(record.vat)) / 100)}</>

            }
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            key: 'total_price',
            align: "right",
            render: (_, record) => {
                return <>&#163;{record.total_price}</>

            }
        }
    ];

    return (
        <div className="orderHistoryContainer">
            <Card className="item-container">
                <Title level={3}>Order History</Title>
                <Divider />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size='small'
                    loading={orderState?.orderHistoryLoading}
                    scroll={{ x: 200 }}
                    expandable={{
                        expandedRowRender: (record, index) => (
                            <Table
                                dataSource={record.products}
                                columns={columnsDetails}
                                size='small'
                                key={index}
                            />
                        ),
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                            ) : (
                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                            )
                    }}
                />
            </Card>
        </div>
    );
}

OrderHistory.propTypes = {
    userInfo: PropTypes.object
};

export default OrderHistory;
import {
    VERIFY_PAYMENT_REQ,
    VERIFY_PAYMENT_SUCCESS,
    VERIFY_PAYMENT_ERROR
} from "../../Constants/Result";

let initialState = {
    paymentVerificationLoading: false,
    paymentVerificationResponse: {}
};

const resultReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_PAYMENT_REQ:
            return {
                ...state,
                paymentVerificationLoading: true,
                paymentVerificationResponse: {}
            }
        case VERIFY_PAYMENT_SUCCESS:
        case VERIFY_PAYMENT_ERROR:
            return {
                ...state,
                paymentVerificationLoading: false,
                paymentVerificationResponse: action.payload
            }
        default:
            return state;
    }
}

export default resultReducer;
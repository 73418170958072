import React from "react";
import { Typography, Row, Divider, Card, Col } from "antd";
import MediaBlock from "../../components/mediablock";
import "./aboutUs.css";

const { Title, Text } = Typography;

const AboutUs = () => {
  return (
    <div className="container aboutUsContainer">
      <Card className="aboutUsCard">
        <Row justify="space-around">
          <Title level={3}>About Eagle Work Solutions</Title>
        </Row>
        <Divider />
        <Row justify="space-around" style={{ textAlign: "center" }}>
          <Col span={22}>
            <Text className="pageInfo">
              Established in 2019, the Eagle Work Solutions remains privately
              held with its distributor arm, Eagle Work Solutions Ltd,
              distributing leading IT, Electrical, Power Tools, Health & Safety,
              PPE, Cleaning & Janitorial, Furniture products from many leading
              brands to a broad range of businesses, resellers, retailers and
              dealers in the UK and internationally.
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around" style={{ textAlign: "center" }}>
          <Col span={22}>
            <Text className="pageInfo">
              We are one stop solutions for all your business and personal work
              needs covering everything under one Umbrella. Our aim is to offer
              you stress free experience where you can rely on us for everything
              you need at workplace. As well as giving you best shopping
              experience, our aim is to take care of welfare of every
              stakeholder of Eagle Work Solutions.
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around" style={{ textAlign: "center" }}>
          <Col span={22}>
            <Text className="pageInfo">
              Since We started Eagle Work Solutions has grown to become leading
              UK distributor for many of our vendors and customers. Eagle Work
              Solutions operates from different locations in the world including
              United Kingdom, USA, Europe and Middle east.
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around" style={{ textAlign: "center" }}>
          <Col span={22}>
            <Text className="pageInfo">
              Thanks to the hard work and dedication of our staff, the Group is
              flourishing. Our revenues have seen a sharp increase in recent
              years reaching £1.5 million in 2021.
            </Text>
          </Col>
        </Row>
      </Card>
      <div>
        <h3 style={{ textAlign: "center", marginTop: "40px", fontWeight: 700 }}>
          Few of many brands we deal with
        </h3>
      </div>
      <MediaBlock />
    </div>
  );
};

export default AboutUs;

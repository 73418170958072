import {
    ALL_REVIEWS_REQ,
    ALL_REVIEWS_SUCCESS,
    ALL_REVIEWS_ERROR
} from "../../Constants/AllReviews";

const initialState = {
    allReviewLoading: false,
    allReviewResponse: [],
};

const allReviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_REVIEWS_REQ:
            return {
                ...state,
                allReviewLoading: true,
                allReviewResponse: []
            }
        case ALL_REVIEWS_SUCCESS:
            return {
                ...state,
                allReviewLoading: false,
                allReviewResponse: action.payload
            }
        case ALL_REVIEWS_ERROR:
            return {
                ...state,
                allReviewLoading: false,
                allReviewResponse: action.payload
            }
        default:
            return state;
    }
}

export default allReviewReducer;
import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { createBrowserHistory } from "history";
const history = createBrowserHistory()
ReactDOM.render(
  <CookiesProvider>
    <Router history={history}>
      <ScrollToTop />
      <Route path="/" component={App} />
    </Router>
  </CookiesProvider>,
  document.getElementById("root")
);
import axios from "axios";
import {
    CONTACT_US_REQ,
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR,
    RESET_TO_INITIAL_STATE
} from "../../Constants/Contact"
import * as api from "../../apis/common";

export {
    contactus
}

function contactus(formdata) {
    const { name, email, message, phone } = formdata;
    return async (dispatch) => {
        try {
            dispatch({ type: CONTACT_US_REQ });
            const result = await axios.post(
                api.GRAPHQL_API, {
                query:
                    `mutation{
                        createContact(name:"${name}", email:"${email}",phone:"${phone}",message:"${message}"){
                           success
                           statusCode
                           message
                        }
                      }`
            }
            )

            if (result?.data?.createContact?.success === "1") {
                dispatch({ type: CONTACT_US_SUCCESS, payload: result.data.createContact });
            } else {
                dispatch({ type: CONTACT_US_ERROR, payload: result?.data?.data?.createContact })
            }
        } catch (e) {
            dispatch({ type: CONTACT_US_ERROR, payload: e.errors[0] })
        }
    }
}

export function resetInitialState() {
    return async (dispatch) => {
        dispatch({ type: RESET_TO_INITIAL_STATE })
    }
}

import axios from "axios";
import {
    FEEDBACK_REQ,
    FEEDBACK_SUCCESS,
    FEEDBACK_ERROR,
    RESET_TO_INITIAL_STATE
} from "../../Constants/Feedback"
import * as api from "../../apis/common";

export { feedback }

function feedback(starRating, formdata, token) {
    const { product_code, comment } = formdata;
    return async (dispatch) => {
        try {
            dispatch({ type: FEEDBACK_REQ });
            const result = await axios.post(
                api.GRAPHQL_API, {
                query:
                    `mutation{
                            storeFeedback(product_code: "${product_code}",comment: "${comment}",rating: ${starRating}){
                                success
                                statusCode
                                message
                            }
                        }`
            },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }
            )
            if (result?.data?.data?.storeFeedback?.success === "1") {
                dispatch({ type: FEEDBACK_SUCCESS, payload: result.data.data.storeFeedback });
            } else {
                dispatch({ type: FEEDBACK_ERROR, payload: result?.data?.data?.storeFeedback })
            }
        } catch (e) {
            dispatch({ type: FEEDBACK_ERROR, payload: e.error[0] })
        }
    }
}

export function resetInitialState() {
    return async (dispatch) => {
        dispatch({ type: RESET_TO_INITIAL_STATE })
    }
}

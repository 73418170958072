import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Typography, notification } from "antd";
import "./productListBlock.css";

import Loading from "../Loading";

import {
  addMultipleProductReq,
  updateLocalCartItems,
} from "../../Actions/Cart/actions";

// containers
import PopupModal from "../../container/PopupModal";

import { convertToPriceVal } from "../../utils";

const { Paragraph } = Typography;

const ProductListBlock = (props) => {
  const [featuredImage, setFeaturedImage] = useState();
  const [heroImage, setHeroImage] = useState({});
  const { product } = props;
  const {
    getImage,
    name,
    price,
    price_status,
    price_description,
    id,
    category_id,
    description,
    quantity,
    short_description,
    slug,
    tax,
    product_code,
  } = product;

  const userDetails = useSelector((state) => state.user.userDetails);
  const cartState = useSelector((state) => state.cart);
  const {
    addMultipleProductsLoading,
    localCart,
    itemToBeAddedToCart,
    localCartLoading,
    updatingItemId,
  } = cartState;

  // const [prodId, setProdId] = useState();
  const [activePage, setActivePage] = useState({
    name: "",
    activeStatus: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const pDescription = () => {
    if (price_status === "1") {
      return price_description;
    } else {
      return (
        <span className="newPrice currencyINR">{convertToPriceVal(price)}</span>
      );
    }
  };

  useEffect(() => {
    const mainImage = getImage.filter((image) => {
      return image?.image_type == "1";
    });
    setHeroImage(mainImage[0]);
  }, [getImage]);

  useEffect(() => {
    const fImage = getImage.filter((img) => img.image_type === "1");
    setFeaturedImage(fImage);
  }, []);

  const callbackFunc = (type, cbMessage) => {
    notification[type]({
      message: type.toUpperCase(),
      description: cbMessage,
      placement: "bottomRight",
    });
  };

  const handleAddToCart = (type) => {
    if (type == "pDescription") {
      history.push("/cart");
    }

    if (parseInt(product.quantity) <= 0) {
      return notification["error"]({
        message: "Error",
        description: "Product is out of stock.",
      });
    }

    if (userDetails?.api_token) {
      let data = {
        product_id: parseInt(id),
        product_quantity: 1,
        product_name: name,
        category_name: category_id,
        description: description,
        image_url: heroImage?.image_url,
      };
      dispatch(
        addMultipleProductReq([data], userDetails.api_token, callbackFunc)
      );
    } else {
      const itemData = [
        {
          product_id: parseInt(id),
          product_quantity: 1,
          product_price: price,
          product_name: name,
          category_name: category_id,
          description: description,
          image_url: heroImage?.image_url,
          productStock: quantity,
          tax: tax,
          short_description: short_description,
        },
      ];

      if (localCart?.length) {
        const duplicate = localCart.find((product) => product.product_id == id);

        const duplicateIndex = localCart.findIndex(
          (product) => product.product_id == id
        );
        if (duplicate) {
          const newData = localCart;
          newData[duplicateIndex] = {
            ...duplicate,
            product_quantity: duplicate.product_quantity + 1,
          };
          dispatch(updateLocalCartItems(newData, id, callbackFunc));
        } else {
          dispatch(
            updateLocalCartItems([...localCart, itemData[0]], id, callbackFunc)
          );
        }
      } else {
        dispatch(updateLocalCartItems(itemData, id, callbackFunc));
      }
    }
  };

  const handleUpdate = (name, activeStatus) => {
    setActivePage({ name, activeStatus });
  };

  return (
    <div style={{ width: "100%" }}>
      <li className="itemListBlock">
        <div className="itemListContainer">
          <Row justify="space-around">
            <Col
              xs={24}
              md={24}
              lg={8}
              xl={8}
              xxl={6}
              style={{ textAlign: "center" }}
            >
              {featuredImage ? (
                <Link to={`/product-details/${encodeURI(slug)}/${id}`}>
                  <img
                    src={heroImage?.image_url}
                    alt=""
                    className="itemListImg"
                  />
                </Link>
              ) : (
                <div className="itemListImg">
                  <Loading />
                </div>
              )}
            </Col>
            <Col
              xs={24}
              md={24}
              lg={15}
              xl={15}
              xxl={17}
              className="prod-block-info-section"
            >
              <Row>
                <Link
                  className="itemListName"
                  to={`/product-details/${encodeURI(slug)}/${id}`}
                >
                  {name}
                </Link>
              </Row>
              <Row>
                <Col style={{ padding: "5px 0" }}>
                  Product code: {product_code}
                </Col>
              </Row>
              <Row>
                <div className="itemListPrice">
                  <span
                    className="newPrice priceTitle"
                    onClick={() => handleAddToCart("pDescription")}
                  >
                    {pDescription()}
                  </span>
                </div>
              </Row>
              <Row align="top" gutter={[8, 0]}>
                <Col span={24} style={{ marginBottom: "15px" }}>
                  <Paragraph
                    strong
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: "more",
                      onExpand: function () {
                        history.push(
                          `/product-details/${encodeURI(
                            name.toLowerCase()
                          )}/${id}`
                        );
                      },
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    {short_description}
                  </Paragraph>
                </Col>
                <Col xs={24} sm={7} md={5} xl={6} xxl={4} lg={8}>
                  <Button
                    // disabled={price_status === "1" || Number(price) === 0}
                    className="addToCartBtn"
                    type="primary"
                    size={"large"}
                    onClick={() => handleAddToCart()}
                    loading={
                      (addMultipleProductsLoading &&
                        itemToBeAddedToCart.product_id == id) ||
                      (localCartLoading && updatingItemId == id)
                    }
                  >
                    {(addMultipleProductsLoading &&
                      itemToBeAddedToCart.product_id == id) ||
                    (localCartLoading && updatingItemId == id)
                      ? "Adding"
                      : "Add to quote"}
                  </Button>
                </Col>
                <Col xs={24} sm={7} md={5} xl={6} xxl={4} lg={8}>
                  <Button
                    className="ant-btn-secondary"
                    size={"large"}
                    onClick={() => handleUpdate("buybulk", true)}
                  >
                    Buy Bulk
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </li>
      {activePage.activeStatus && (
        <PopupModal
          pageInfo={activePage}
          func={handleUpdate}
          productId={parseInt(id)}
          productCode={product_code}
          productName={name}
        />
      )}
    </div>
  );
};

ProductListBlock.propTypes = {
  category_id: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.string,
  short_description: PropTypes.string,
  tax: PropTypes.string,
  product_code: PropTypes.string,
  id: PropTypes.string,
  getImage: PropTypes.array,
  name: PropTypes.string,
  price: PropTypes.string,
  product: PropTypes.object,
};

export default ProductListBlock;

import React from "react";
import { Card } from "antd";

//const { Title, Text } = Typography;

const ThankYou = () => {
    return (
        <div className="container thankYouContainer" style={{ textAlign: "center" }}>
            <Card className="thankYouCard">
                <h2>Thank You for choosing Eagle work solutions</h2>
                <p>Your Quote has been succesfully delivered to the Eagle Work Solutions team. </p>
            </Card>

        </div>
    );
};

export default ThankYou;

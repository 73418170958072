import React from 'react';

// components

const Gifts = () => {
    return (
        <div>
            <b><p style={{ textDecoration: "underline" }}>No gift cards available currently</p></b>
        </div>
    )
}

export default Gifts

import React from 'react';
import { useSelector } from "react-redux";

import Loading from "../Loading";
import SliderCarousel from "../SliderCarousel";

const FeaturedProductsSlider = () => {
    const productsState = useSelector((state) => state.products);
    const { featuredProducts, featuredProductsLoading } = productsState;
  
    return <div>
        {
            featuredProductsLoading ? (
                <Loading />
            ) : (
                !featuredProductsLoading && featuredProducts?.length ? (
                    <section className="sectionFeatured">
                        <div className="col-md-12 col-sm-12 text-center">
                            <span className="featured-products-text">Featured Products</span>
                        </div>
                        <SliderCarousel products={featuredProducts} />
                    </section>
                ) : (
                    <></>
                )
            )
        }
    </div>;
};

export default FeaturedProductsSlider;

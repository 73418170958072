export const GET_MERCHANT_SESSION_KEYS_REQ = "GET_MERCHANT_SESSION_KEYS_REQ";
export const GET_MERCHANT_SESSION_KEYS_SUCCESS = "GET_MERCHANT_SESSION_KEYS_SUCCESS";
export const GET_MERCHANT_SESSION_KEYS_ERROR = "GET_MERCHANT_SESSION_KEYS_ERROR";

export const CARD_IDENTIFIER_REQ = "CARD_IDENTIFIER_REQ";
export const CARD_IDENTIFIER_SUCCESS = "CARD_IDENTIFIER_SUCCESS";
export const CARD_IDENTIFIER_ERROR = "CARD_IDENTIFIER_ERROR";

export const TRANSACTION_REQUEST = "TRANSACTION_REQUEST";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";

export const SAVE_PAYMENT_DETAILS = "SAVE_PAYMENT_DETAILS";
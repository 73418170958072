import axios from "axios";
import {
    SEND_PRODUCT_EMAIL_REQ,
    SEND_PRODUCT_EMAIL_SUCCESS,
    SEND_PRODUCT_EMAIL_ERROR
} from "../../Constants/SendProductEmail";
import * as api from "../../apis/common";

export {
    sendProductEmailReq
}

function sendProductEmailReq(shippingAddressId, token) {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_PRODUCT_EMAIL_REQ });
            const result = await axios.post(
                api.GRAPHQL_API, {
                query:
                    `mutation{
                        createUserOrderHistory(shipping_address_id:${shippingAddressId}){
                            success
                            statusCode
                            message
                        }
                    }`
            },
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }
            )
            if (result?.data?.data?.createUserOrderHistory?.success === "1") {
                dispatch({ type: SEND_PRODUCT_EMAIL_SUCCESS, payload: result?.data?.data?.createUserOrderHistory });
            } else {
                dispatch({ type: SEND_PRODUCT_EMAIL_ERROR, payload: result?.data?.data?.createUserOrderHistory })
            }
        } catch (e) {
            dispatch({ type: SEND_PRODUCT_EMAIL_ERROR, payload: e.errors[0] })
        }
    }
}
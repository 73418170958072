import React from "react";
import { Row, Card, Typography, Divider, Col } from 'antd';
import "./terms.css"

const { Paragraph, Title } = Typography;

const Terms = () => {
    return (
        <div className="container">
            <Card className="policyContainer">
                <Row justify="space-around">
                    <Title level={3} >Terms & Conditions</Title>
                </Row>
                <Divider />
                <Row justify="space-around">
                    <Col span={24}>
                    <Title level={4} className="para-title">CONSUMER INFORMATION</Title>
                        <Paragraph >THIS INFORMATION IS RELEVANT ONLY IF YOU ARE CONTRACTING AS A CONSUMER. IF YOU ARE CONTRACTING AS A
                            BUSINESS, THE RELEVANT TERMS AND CONDITIONS ARE &quot;STANDARD TERMS AND CONDITIONS&quot; SET OUT BELOW.
                            This page tells you the terms and conditions on which we supply any of the Product (Products) listed on our
                            website <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> (our site) to you. Please read these terms and conditions carefully before ordering any
                            Product from our site. You should understand that by ordering any of our Products, you agree to be bound by these terms and
                            conditions.</Paragraph>
                        <Paragraph >Please check the box marked &quot;Accept Terms &amp; Conditions&quot; at the end of the New User Registration Step 1 if you accept them.
                            Please understand that if you refuse to accept these terms and conditions, you will not be able to order any Product from our site.</Paragraph>
                    </Col>
                </Row>
                <Divider />
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">INFORMATION ABOUT US</Title>
                        <Paragraph ><a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> is a site operated by Eagle Work Solutions (we). We are registered in England and Wales
                            under company number 12377307 and with our registered office at <b>Suite 4a, 2nd Floor, Regency House, George Street, Luton,
                                England, LU1 2AT.</b> Our main trading address is 1 Hillview Crescent, Luton, LU2 7AA. Our VAT number is 341 502 834.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">1.  HOW THE CONTRACT IS FORMED BETWEEN YOU AND US</Title>
                        <Paragraph >1.1 After placing an order, you will receive an e-mail from us acknowledging that we have received your order. Please note that this
                            does not mean that your order has been accepted. Your order constitutes an offer to us to buy a Product. All orders are subject to
                            acceptance by us, and we will confirm such acceptance to you by sending you an e-mail that confirms that the Product has been
                            dispatched (the Dispatch Confirmation). The contract between us (the Contract) will only be formed when we send you the
                            Dispatch Confirmation.</Paragraph>
                        <Paragraph >1.2 The Contract will relate only to those Products whose dispatch we have confirmed in the Dispatch Confirmation. We will not be
                            obliged to supply any other Product which may have been part of your order until the dispatch of such Product has been confirmed
                            in a separate Dispatch Confirmation.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">2.  OUR STATUS</Title>
                        <Paragraph >We may provide links on our site to the websites of other companies, whether affiliated with us or not. We cannot give any
                            undertaking, that Products you purchase from third party sellers through our site, or from companies to whose website we have
                            provided a link on our site, will be of satisfactory quality, and any such warranties are DISCLAIMED by us absolutely. This
                            DISCLAIMER does not affect your statutory rights against the third-party seller. We will notify you when a third party is involved in a
                            transaction, and we may disclose your customer information related to that transaction to the third-party seller.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">3.  IMPORT DUTY</Title>
                        <Paragraph >Applicable laws require that some of the information or communications we send to you should be in writing. When using our site,
                            you accept that communication with us will be mainly electronic. We will contact you by e-mail or provide you with information by
                            posting notices on our website. For contractual purposes, you agree to this electronic means of communication and you
                            acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with
                            any legal requirement that such communications be in writing. This condition does not affect your statutory rights.</Paragraph>
                        <Paragraph >CONSUMER TERMS AND CONDITIONS<br />
                            THESE TERMS AND CONDITIONS APPLY IF YOU ARE CONTRACTING AS A CONSUMER</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">4.  WRITTEN COMMUNICATIONS</Title>
                        <Paragraph >3.1 If you order Products from our site for delivery outside the UK, they may be subject to import duties and taxes which are levied
                            when the delivery reaches the specified destination. You will be responsible for payment of any such import duties and taxes.
                            Please note that we have no control over these charges and cannot predict their amount. Please contact your local customs office
                            for further information before placing your order.</Paragraph>
                        <Paragraph >3.2 Please also note that you must comply with all applicable laws and regulations of the country for which the Products are
                            destined. We will not be liable for any breach by you of any such laws.</Paragraph>
                    </Col>
                </Row>
                <Divider />
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">1.  CONSUMER RIGHTS</Title>
                        <Paragraph >1.1 You may cancel a Contract at any time within seven working days, beginning on the day after you received the Products. In this
                            case, you will receive a full refund of the price paid for the Products in accordance with our refunds policy (set out in clause 3
                            below).</Paragraph>
                        <Paragraph >1.2 To cancel a Contract, you must inform us in writing. You must also return the Product to us immediately, in the same condition
                            in which you received them, and at your own cost and risk. You have a legal obligation to take reasonable care of the Product while
                            they are in your possession. If you fail to comply with this obligation, we may have a right of action against you for compensation.</Paragraph>
                        <Paragraph >1.3 Details of this statutory right, and an explanation of how to exercise it, are provided in the Dispatch Confirmation. This provision
                            does not affect your statutory rights.</Paragraph>
                        <Paragraph >1.4 Your order will be fulfilled by the delivery date set out in the Dispatch Confirmation or, if no delivery date is specified, then within
                            30 days of the date of the Dispatch Confirmation, unless there are exceptional circumstances.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">2.  PRICES ON WEBSITE</Title>
                        <Paragraph >2.1 Our site contains a large number of Products, and it is always possible that, despite our best efforts, some of the Products
                            listed on our site may be incorrectly priced. We will normally verify prices as part of our dispatch procedures so that, where a
                            Product&#39;s correct price is less than our stated price, we will charge the lower amount when dispatching the Product to you. If a
                            Products correct price is higher than the price stated on our site, we will normally, at our discretion, either contact you for
                            instructions before dispatching the Products, or reject your order and notify you of such rejection.</Paragraph>
                        <Paragraph >2.2 We are under no obligation to provide the Products to you at the incorrect (lower) price, even after we have sent you a Dispatch
                            Confirmation, if the pricing error is obvious and unmistakeable and could have reasonably been recognised by you as a mispricing.</Paragraph>
                        <Paragraph >2.3 Payment for all Products can be made by credit or debit card. We accept payment with MasterCard, Maestro, Visa, Visa
                            Electron and Visa Debit/Delta. We will not charge your credit or debit card until we despatch your order. You can also opt to use
                            Paypal or Bacs for payment</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">3.  OUR REFUNDS POLICY</Title>
                        <Paragraph >3.1 When you return a Product to us:</Paragraph>
                        <Paragraph >3.1.1 because you have cancelled the Contract between us within the seven-day cooling-off period (see clause 1 above), we will
                            process the refund due to you as soon as possible and, in any case, within 30 days of the day you have given notice of your
                            cancellation. In this case, we will refund the price of the Product in full providing it is returned in the original packaging and in a
                            resaleable condition, excluding the cost of sending the item to you. You will be responsible for the cost of returning the item to us.</Paragraph>
                        <Paragraph >3.1.2 for any other reason (for instance, because have notified us in accordance with paragraph 11 that you do not agree to any
                            change in these terms and conditions or in any of our policies, or because you claim that the Product is defective), we will examine
                            the returned Product and will notify you of your refund via e-mail within a reasonable period of time. We will usually process the
                            refund due to you as soon as possible and, in any case, within 30 days of the day we confirmed to you via e-mail that you were

                            entitled to a refund for the defective Product. Products returned by you because of a defect will be refunded in full, including a
                            refund of the delivery charges for sending the item to you and the cost incurred by you in returning the item to us.</Paragraph>
                        <Paragraph >3.1.3 We will usually refund any money received from you using the same method originally used by you to pay for your purchase.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">4.  OUR LIABILITY</Title>
                        <Paragraph >4.1 We warrant to you that any Product purchased from us through our site is of satisfactory quality and reasonably fit for all the
                            purposes for which Product of the kind are commonly supplied.</Paragraph>
                        <Paragraph >4.2 Our liability for losses you suffer as a result of us breaking this agreement is strictly limited to the purchase price of the
                            Products you purchased.</Paragraph>
                        <Paragraph >4.3 This does not include or limit in any way our liability:</Paragraph>
                        <Paragraph >4.3.1 For death or personal injury caused by our negligence.</Paragraph>
                        <Paragraph >4.3.2 Under section 2(3) of the Consumer Protection Act 1987.</Paragraph>
                        <Paragraph >4.3.3 For fraud or fraudulent misrepresentation; or</Paragraph>
                        <Paragraph >4.3.4 For any matter for which it would be illegal for us to exclude, or attempt to exclude, our liability.</Paragraph>
                        <Paragraph >4.4 We are not responsible for indirect losses which happen as a side effect of the main loss or damage however arising and
                            whether caused by tort (including negligence), breach of contract or otherwise.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">5.  NOTICES</Title>
                        <Paragraph >All notices given by you to us must be given to <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> Ltd to the Company Director, 1 Hillview Crescent,
                            Luton, LU2 7AA or <a className="headerEmai" href="mailto:info@eagleworksolutions.com">info@eagleworksolutions.com</a> We may give notice to you at either the e-mail or postal address you provide to
                            us when placing an order. Notice will be deemed received and properly served 24 hours after an e-mail is sent, or three days after
                            the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such
                            letter was properly addressed, stamped and placed in the post and, in the case of an e-mail, that such e-mail was sent to the
                            specified e-mail address of the addressee.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">6.  TRANSFER OF RIGHTS AND OBLIGATIONS</Title>
                        <Paragraph >6.1 The contract between you and us is binding on you and us and on our respective successors and assigns.</Paragraph>
                        <Paragraph >6.2 You may not transfer, assign, charge or otherwise dispose of a Contract, or any of your rights or obligations arising under it,
                            without our prior written consent.</Paragraph>
                        <Paragraph >6.3 We may transfer, assign, charge, sub-contract or otherwise dispose of a Contract, or any of our rights or obligations arising
                            under it, at any time during the term of the Contract.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">7.  EVENTS OUTSIDE OUR CONTROL</Title>
                        <Paragraph >7.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract
                            that is caused by events outside our reasonable control (Force Majeure Event).</Paragraph>
                        <Paragraph >7.2 A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control and
                            includes in particular (without limitation) the following:</Paragraph>
                        <Paragraph >7.2.1 Strikes, lockouts or other industrial action.</Paragraph>
                        <Paragraph >7.2.2 Civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or
                            preparation for war.</Paragraph>
                        <Paragraph >7.2.3 Fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster.</Paragraph>
                        <Paragraph >7.2.4 Impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport.</Paragraph>
                        <Paragraph >7.2.5 Impossibility of the use of public or private telecommunications networks.</Paragraph>
                        <Paragraph >7.2.6 The acts, decrees, legislation, regulations or restrictions of any government.</Paragraph>
                        <Paragraph >7.3 Our performance under any Contract is deemed to be suspended for the period that the Force Majeure Event continues, and we
                            will have an extension of time for performance for the duration of that period. We will use our reasonable endeavours to bring the
                            Force Majeure Event to a close or to find a solution by which our obligations under the Contract may be performed despite the
                            Force Majeure Event.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">8.  WAIVER</Title>
                        <Paragraph >8.1 If we fail, at any time during the term of a Contract, to insist upon strict performance of any of your obligations under the
                            Contract or any of these terms and conditions, or if we fail to exercise any of the rights or remedies to which we are entitled under
                            the Contract, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such
                            obligations.</Paragraph>
                        <Paragraph >8.2 A waiver by us of any default shall not constitute a waiver of any subsequent default.</Paragraph>
                        <Paragraph >8.3 No waiver by us of any of these terms and conditions shall be effective unless it is expressly stated to be a waiver and is
                            communicated to you in writing in accordance with clause 4 above.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">9.  SEVERABILITY</Title>
                        <Paragraph >If any of these terms and Conditions or any provisions of a Contract are determined by any competent authority to be invalid,
                            unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms,
                            conditions and provisions which will continue to be valid to the fullest extent permitted by law.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">10.  ENTIRE AGREEMENT</Title>
                        <Paragraph >10.1 These terms and conditions and any document expressly referred to in them represent the entire agreement between us in
                            relation to the subject matter of any Contract and supersede any prior agreement, understanding or arrangement between us,
                            whether oral or in writing.</Paragraph>
                        <Paragraph >10.2 We each acknowledge that, in entering a Contract, neither of us has relied on any representation, undertaking or promise given
                            by the other or be implied from anything said or written in negotiations between us prior to such Contract except as expressly
                            stated in these terms and conditions.</Paragraph>
                        <Paragraph >10.3 Neither of us shall have any remedy in respect of any untrue statement made by the other, whether orally or in writing, prior to
                            the date of any Contract (unless such untrue statement was made fraudulently) and the other parties only remedy shall be for
                            breach of contract as provided in these terms and conditions.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">11.  OUR RIGHT TO VARY THESE TERMS AND CONDITIONS</Title>
                        <Paragraph >11.1 We have the right to revise and amend these terms and conditions from time to time to reflect changes in market conditions
                            affecting our business, changes in technology, changes in payment methods, changes in relevant laws and regulatory requirements
                            and changes in our system&#39;s capabilities.</Paragraph>
                        <Paragraph >11.2  You will be subject to the policies and terms and conditions in force at the time that you order Products from us, unless any
                            change to those policies or these terms and conditions is required to be made by law or governmental authority (in which case it
                            will apply to orders previously placed by you), or if we notify you of the change to those policies or these terms and conditions
                            before we send you the Dispatch Confirmation (in which case we have the right to assume that you have accepted the change to
                            the terms and conditions, unless you notify us to the contrary within seven working days of receipt by you of the Products).</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">12.  LAW AND JURISDICTION</Title>
                        <Paragraph >Contracts for the purchase of Products through our site will be governed by English law. Any dispute arising from, or related to,
                            such Contracts shall be subject to the non-exclusive jurisdiction of the courts of England and Wales.</Paragraph>
                        <Paragraph >STANDARD TERMS AND CONDITIONS<br />
                            THESE TERMS AND CONDITIONS APPLY IF YOU ARE AN ACCOUNT CUSTOMER OR CONTRACTING OTHERWISE THAN AS A
                            CONSUMER</Paragraph>
                    </Col>
                </Row>
                <Divider />
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">1.  General</Title>
                        <Paragraph >1.1 The following definitions apply: Business Day: a day (other than a Saturday, Sunday or public holiday) when banks in London are
                            open for business. Conditions: these terms and conditions of sale. Contract: the contract for the sale of the Goods by <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> to the Customer incorporating these Conditions. Customer: the person with whom <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> contracts for the sale of the Goods upon the terms of these Conditions. Delivery: the meaning given
                            in clause 4. Goods: the products sold by <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> to the Customer pursuant to the Contract including any part
                            or parts of them. Force Majeure Event: the meaning given in clause 11. <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a>. Eagle Work Solutions Ltd.
                            Price: the price to be paid by the Customer for the Goods. Specification: any specification for the Goods contained in any related
                            plans and drawings that are agreed in writing by the Customer and <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a>.</Paragraph>
                        <Paragraph >1.2 In these Conditions, the following rules apply: (a) a person includes a natural person, corporate or unincorporated body
                            (whether or not having separate legal personality). (b) headings are for ease of reference only and do not affect the construction of
                            these Conditions. (c) any phrase introduced by the terms including, include, in particular or any similar expression are illustrative
                            and do not limit the sense of the words preceding those terms.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">2.  Basis of Contract</Title>
                        <Paragraph >2.1 All orders are accepted, and all contracts entered into by <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> on these Conditions which override and
                            exclude any other terms stipulated or referred to by the Customer or which might be implied by trade, custom, practice or course of
                            dealing.</Paragraph>
                        <Paragraph >2.2 An order from the Customer constitutes an offer by the Customer to purchase the Goods in accordance with these Conditions.
                            The Customer is responsible for ensuring that the terms of the order and any applicable Specification are complete and accurate.</Paragraph>
                        <Paragraph >2.3 An order from the Customer will only be deemed to be accepted when <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> issues a written
                            acceptance of the order, at which point the Contract will come into existence.</Paragraph>
                        <Paragraph >2.4 The Contract constitutes the entire agreement between the parties. The Customer acknowledges that it has not relied on any
                            statement, promise, representation, assurance or warranty made or given by or on behalf of <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> which is
                            not set out in the Contract. The Customer has no claim for innocent or negligent misrepresentation based upon any statement in
                            the Contract.</Paragraph>
                        <Paragraph >2.5 Any samples, drawings, descriptive matter, or advertising produced by the <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> and any descriptions
                            or illustrations contained in <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> catalogues or brochures are produced for the sole purpose of giving an
                            approximate idea of the Goods described in them. They do not form part of the Contract or have any contractual force.</Paragraph>
                        <Paragraph >2.6 Any estimate or quotation given by or on behalf of <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> whether orally or in writing constitutes an
                            invitation to the Customer to place an order and thereby make an offer open to acceptance by <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> on
                            these Conditions.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">3.  Price and Payment</Title>
                        <Paragraph >3.1 The Price is the price stated at the time of the Contract. If no price is stated, the Price is the price set out in <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> published price list in force as at Delivery.</Paragraph>
                        <Paragraph >3.2  <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> may, by giving notice to the Customer at any time before Delivery, increase the Price to reflect
                            any increase in the cost of the Goods that is due to: (a) any factor beyond <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> control (including foreign
                            exchange fluctuations, increases in taxes and duties, and increases in labour, materials and other manufacturing costs); (b) any
                            request by the Customer to change the delivery date(s), quantities or types of Goods ordered, or the Specification; or (c) any delay
                            caused by any instructions of the Customer or failure of the Customer to give <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> adequate or accurate
                            information or instructions.</Paragraph>
                        <Paragraph >3.3 The Price is exclusive of the costs and charges of packaging, insurance and transport of the Goods, which will be invoiced to
                            the Customer.</Paragraph>
                        <Paragraph >3.4 The Price is exclusive of amounts in respect of value added tax (VAT). The Customer must, on receipt of a valid VAT invoice
                            from<a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a>, pay to <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> such additional amounts in respect of VAT as are
                            chargeable on the supply of the Goods.</Paragraph>
                        <Paragraph >3.5 <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> may invoice the Customer for the Goods on or at any time after Delivery.</Paragraph>
                        <Paragraph >3.6 The Customer must pay the invoice in full and in cleared funds by the end of the month following the month the invoice was
                            dated to the bank account nominated in writing by <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a>. Time of payment is of the essence.</Paragraph>
                        <Paragraph >3.7 If the Customer fails to make any payment due to the <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> under the Contract by the due date for
                            payment, then the Customer must pay interest on the overdue amount at the rate of 4% per annum. Such interest will accrue daily
                            basis from the due date until actual payment of the overdue amount, whether before or after judgment. The Customer must pay the
                            interest together with the overdue amount.</Paragraph>
                        <Paragraph >3.8 The Customer must pay all amounts due under the Contract in full without any set-off, counterclaim, deduction or withholding
                            (except for any deduction or withholding required by law). <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> may at any time, without limiting any other
                            rights or remedies it may have, set off any amount owing to it by the Customer against any amount payable by <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> to the Customer.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">4.  Delivery</Title>
                        <Paragraph >4.1 Unless the parties have agreed in writing that the Customer is to collect the Goods, <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> shall deliver

                            the Goods to the location set out in the Customer&#39;s order or such other location as the parties may agree in writing (Delivery
                            Location).</Paragraph>
                        <Paragraph >4.2 Delivery (Delivery) takes place when the Goods leave the premises of <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> for delivery to the Delivery
                            Location or when the Goods are collected by the Customer.</Paragraph>
                        <Paragraph >4.3  If in the opinion of <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> it is not practicable for <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> to deliver the Goods
                            when the Goods are ready for delivery due to any act or omission on the part of or on behalf of the Customer (including but not
                            limited to: the Customer failing upon request to provide a UK delivery address, appropriate instructions, necessary documentation,
                            licences or authorisations; the Customer refusing to accept receipt of the Goods; or for any other reason whatsoever), then the
                            following applies: (a) Eagle Work Solutions may place the Goods into storage either at its own premises or elsewhere; (b) Delivery
                            will be deemed to take place when the Goods are placed into such storage and the Price will become immediately payable
                            irrespective of any arrangement to the contrary; (c) The Customer will be liable for all related costs and expenses including without
                            limitation storage and insurance; (d) Eagle Work Solutions may at its discretion continue to store the Goods and may at any time
                            sell the Goods to another party or parties; (e) The exercise of the discretion to store or sell the Goods or both is without prejudice to
                            any other rights or remedies Eagle Work Solutions may have against the Customer. If EAGLE WORK SOLUTIONS sells the Goods at
                            a price lower than the Price, the Customer will be liable for the shortfall in addition to any other remedy available at law to
                            WWW.EAGLEWORKSOLUTIONS.COM.</Paragraph>
                        <Paragraph >4.4 All times and dates of delivery are approximate only and any time or date of delivery is not of the essence.</Paragraph>
                        <Paragraph >4.5 <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> is not liable for any delay in delivery of the Goods that is caused by a Force Majeure Event or the
                            Customer&#39;s failure to provide EAGLE WORK SOLUTIONS with necessary information including adequate delivery instructions or any
                            other instructions that are relevant to the supply of the Goods.</Paragraph>
                        <Paragraph >4.6 If <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> fails to deliver the Goods, its liability is limited to the costs and expenses incurred by the
                            Customer in obtaining replacement goods of similar description and quality in the cheapest market available, less the Price.</Paragraph>
                        <Paragraph >4.7 The Customer may not reject the Goods by reason only of short delivery and the Customer must pay for such Goods delivered
                            to the Customer at the pro rata contract price.</Paragraph>
                        <Paragraph >4.8 <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> may deliver the Goods by instalments, which will be invoiced and must be paid for separately.
                            Each instalment will constitute a separate Contract. Any delay in delivery or defect in an instalment does not entitle the Customer
                            to cancel any other instalment.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">5.  Inspection</Title>
                        <Paragraph >5.1 The Customer must inspect the Goods as soon as practicable after receipt. The Customer must notify EAGLE WORK
                            SOLUTIONS in writing within 5 Business Days within receipt of the Goods of any discrepancy (including short delivery and failure to
                            comply with description), defect or damage (which discrepancy, defect or damage would be apparent on reasonable examination.
                            The Customer must notify EAGLE WORK SOLUTIONS in writing of any other discrepancy, defect or damage as soon as the
                            discrepancy, defect or damage is or ought reasonably to have been discovered, whichever the sooner. In the case of non-receipt of
                            the Goods, the Customer must notify EAGLE WORK SOLUTIONS within 5 Business Days of the receipt of EAGLE WORK SOLUTIONS
                            invoice for the non-received Goods.</Paragraph>
                        <Paragraph >5.2 If the Customer fails to give notice as required by clause 5.1 the Goods will be deemed to have been received by the Customer
                            and to be in all respects in accordance with the Contract and free from any discrepancies, defect or damage and the Customer will
                            be deemed to have accepted the Goods accordingly.</Paragraph>
                        <Paragraph >5.3 As regards this clause 5, time is of the essence.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">6.  Passing of title and risk</Title>
                        <Paragraph >6.1  The property in the Goods will not pass to the Customer until: (a) all sums (including sums pursuant to any contract or
                            otherwise) due or owing to EAGLE WORK SOLUTIONS have been paid in full without any deduction or deferment on account of any
                            disputes or counterclaims whatsoever, or (b) the Goods are delivered to a third party following a sale by the Customer in the normal
                            course of its business in which case property in the Goods will be deemed to have passed to the Customer immediately prior to
                            such a delivery.</Paragraph>
                        <Paragraph >6.2  Until property in the Goods passes to the Customer the following applies: (a) the Customer must hold the Goods as bailee and
                            trustee owing fiduciary duties to EAGLE WORK SOLUTIONS; (b) the Customer must safely store the Goods at no cost to EAGLE
                            WORK SOLUTIONS and must ensure that the Goods are clearly identified as belonging to EAGLE WORK SOLUTIONS; (c) The
                            Customer must permit employees or agents of EAGLE WORK SOLUTIONS to examine the Goods in storage at any time during the
                            normal working hours of the Customer upon request by EAGLE WORK SOLUTIONS; (d) the Customer must fully insure the Goods
                            against any loss or damage whatsoever and the Customer must hold any monies received from such insurance on trust for EAGLE

                            WORK SOLUTIONS; (d) if clause 7.1 applies or if any sum due to EAGLE WORK SOLUTIONS from the Customer pursuant to any
                            contract or otherwise is not paid in full after the due date for payment: (i) EAGLE WORK SOLUTIONS may without prior notice to the
                            Customer repossess and resell the Goods and (ii) agents and employees of EAGLE WORK SOLUTIONS may enter together with any
                            vehicle which EAGLE WORK SOLUTIONS considers necessary for the removal of the Goods upon the premises of the Customer or
                            any other premises or locations where the Goods may be located for the purpose of exercising
                            WWW.EAGLEWORKSOLUTIONS.COM&#39; rights under this clause 6, and (iii) the Customer&#39;s right to resell the Goods or use them in the
                            ordinary course of its business ceases immediately.</Paragraph>
                        <Paragraph >6.3 If the Customer sells the Goods in the normal course of its business, the Customer must hold the proceeds of such sale on
                            trust for EAGLE WORK SOLUTIONS, whenever any sum due from the Customer to EAGLE WORK SOLUTIONS is outstanding
                            pursuant to any contract or otherwise.</Paragraph>
                        <Paragraph >6.4 EAGLE WORK SOLUTIONS can maintain an action against the Customer for the Price notwithstanding that property in the
                            Goods has not passed to the Customer.</Paragraph>
                        <Paragraph >6.5 The rights and remedies conferred on <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> by this clause 6 are in addition to and do not in any way
                            prejudice or limit any other rights of EAGLE WORK SOLUTIONS.
                        </Paragraph>
                        <Paragraph >6.6 Risk in the Goods passes to the Customer upon Delivery.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">7.  Insolvency by the Customer and breach of contract by the Customer</Title>
                        <Paragraph >7.1  This clause applies if any of the following events occur or in the opinion of <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> is likely to occur: (a)
                            the Customer commits any breach of the Contract; (b) the Customer makes any voluntary arrangement with its creditors or
                            becomes subject to an administration order or (being an individual or firm) becomes bankrupt or (being a company) goes into
                            liquidation; (c) an encumbrancer takes possession or a receiver is appointed to any of the property or assets of the Customer; (d)
                            the Customer ceases or threatens to cease to carry on business or any part of its business.</Paragraph>
                        <Paragraph >7.2  If this clause 7 applies, without prejudice to any other rights or remedies available to EAGLE WORK SOLUTIONS,
                            EAGLE WORK SOLUTIONS may cancel the Contract without any liability to the Customer and if the Goods have been delivered but
                            not paid for in full the Price is immediately due and payable notwithstanding any prior arrangements to the contrary.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">8.  Limitation of liability</Title>
                        <Paragraph >8.1 Nothing in these Conditions limits or excludes EAGLE WORK SOLUTIONS liability for: (a) death or personal injury caused by
                            negligence; (b) fraud or fraudulent misrepresentation; (c) breach of the terms implied by section 12 of the Sale of Goods Act 1979;
                            (d) defective products under the Consumer Protection Act 1987; or (e) any matter in respect of which it would be unlawful for
                            EAGLE WORK SOLUTIONS to exclude or restrict liability.</Paragraph>
                        <Paragraph >8.2  Subject to clause 8.1, (a) EAGLE WORK SOLUTIONS is under no circumstances whatever liable to the Customer, whether in
                            contract (including wilful breach), tort (including negligence) or restitution, or for breach of statutory duty, or misrepresentation, or
                            otherwise, for any loss of profit, loss of goodwill, loss of business, loss of business opportunity or any indirect or consequential
                            loss arising under or in connection with the Contract; and (b) EAGLE WORK SOLUTIONS total liability to the Customer in respect of
                            all other losses whatsoever arising under or in connection with the Contract, whether in contract (including wilful breach), tort
                            (including negligence), or restitution, or for breach of statutory duty or misrepresentation, or otherwise, under no circumstances
                            exceeds 100% of the Price.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">9.  Quality</Title>
                        <Paragraph >9.1 EAGLE WORK SOLUTIONS warrants that on delivery the Goods will (a) conform in all material respects with their description
                            and any applicable Specification; and, (b) be free from material defects in design, material and workmanship.</Paragraph>
                        <Paragraph >9.2 The terms implied by sections 13 to 15 of the Sale of Goods Act 1979 are, to the fullest extent permitted by law, excluded from
                            the Contract.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">10.  Third Party Rights.</Title>
                        <Paragraph >A person who is not a party to the Contract shall not have any rights to enforce its terms.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">11.  Force Majeure</Title>
                        <Paragraph >11.1 EAGLE WORK SOLUTIONS is not liable for any failure or delay in performing its obligations under the Contract to the extent
                            that such failure or delay is caused by a Force Majeure Event. A Force Majeure Event means any event beyond EAGLE WORK
                            SOLUTIONS reasonable control, which by its nature could not have been foreseen, or, if it could have been foreseen, was
                            unavoidable, including strikes, lock-outs or other industrial disputes (whether involving its own workforce or a third party&#39;s), failure
                            of energy sources or transport network, acts of God, war, terrorism, riot, civil commotion, interference by civil or military authorities,
                            national or international calamity, armed conflict, malicious damage, breakdown of plant or machinery, nuclear, chemical or
                            biological contamination, sonic boom, explosions, collapse of building structures, fires, floods, storms, earthquakes, loss at sea,
                            epidemics or similar events, natural disasters or extreme adverse weather conditions, or default of suppliers or subcontractors.</Paragraph>
                        <Paragraph >11.2 In the event of a Force Majeure Event, EAGLE WORK SOLUTIONS may elect at its absolute discretion (a) to terminate the
                            Contract; or (b) to perform the Contract within a reasonable time after the termination of the Force Majeure Event.</Paragraph>
                        <Paragraph >11.3  If EAGLE WORK SOLUTIONS makes an election under clause 11.2(b) the Customer must accept and pay for the Goods or
                            such part of them as are delivered notwithstanding any delay.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">12.  Cancellation</Title>
                        <Paragraph >EAGLE WORK SOLUTIONS may cancel this Contract at any time before Delivery by giving notice in writing to the Customer. On
                            giving such notice <a href="https://eagleworksolutions.co.uk/">https://eagleworksolutions.co.uk/</a> must repay to the Customer any sums paid in respect of the Price. EAGLE WORK
                            SOLUTIONS will not be liable for any loss or damage whatsoever arising from such cancellation.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">13.  Variation</Title>
                        <Paragraph >No variation of the Contract, including the introduction of any additional terms and conditions, will be effective unless it is in writing
                            and signed by a director of EAGLE WORK SOLUTIONS.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">14.  Waiver.</Title>
                        <Paragraph >A waiver of any right or remedy under the Contract or law is only effective if given in writing and will not be deemed a waiver of any
                            subsequent breach or default. No failure or delay by a party to exercise any right or remedy provided under the Contract or by law
                            will constitute a waiver of that or any other right or remedy, nor will it prevent or restrict the further exercise of that or any other
                            right or remedy. No single or partial exercise of such right or remedy will prevent or restrict the further exercise of that or any other
                            right or remedy.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">15.  Assignment</Title>
                        <Paragraph >Rights under this Contract are not assignable by the Customer but may be freely assigned by EAGLE WORK SOLUTIONS.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">16.  Governing law.</Title>
                        <Paragraph >The Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-
                            contractual disputes or claims), are governed by, and construed in accordance with the law of England and Wales.</Paragraph>
                    </Col>
                </Row>
                <Row span={24}>
                    <Col span={24}>
                        <Title level={4} className="para-title">17.  Jurisdiction.</Title>
                        <Paragraph >Each party irrevocably agrees that the courts of England and Wales have exclusive jurisdiction to settle any dispute or claim arising
                            out of or in connection with the Contract or its subject matter or formation (including non-contractual disputes or claims).</Paragraph>
                    </Col>
                </Row>
                <Divider />
            </Card>
        </div>
    )
}

export default Terms;


import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Typography, Skeleton } from "antd";
// import { Link } from "react-router-dom";
import "./homeAds.css";
import { convertToPriceVal } from "../../utils";
import { useHistory } from "react-router-dom";
import { dealData } from "../../Actions/DealOfTheWeek";
import { setCurrentCategory } from "../../Actions/Category/actions";

const { Title } = Typography;

const HomeAds = () => {
  // states
  const [remainingTime, setRemainingTime] = useState({
    total: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const categoriesFromStore = useSelector((state) => state.category);
  const dealProductState = useSelector((state) => state.dealProduct);
  const { allCategories } = categoriesFromStore;
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setCategories(allCategories);
  }, [allCategories]);

  useEffect(() => {
    dispatch(dealData);
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimeRemaining();
    }, 1000);

    return () => clearInterval(intervalId);
  });

  const updateTimeRemaining = () => {
    getTimeRemaining();
  };

  const getTimeRemaining = () => {
    if (dealProductState?.dealResp.length) {
      const total =
        new Date(dealProductState?.dealResp[0]?.to_date).getTime() - Date.now();
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      setRemainingTime({
        total: Math.floor(total / (60 * 60 * 24 * 1000)),
        hours,
        minutes,
        seconds,
      });
    }
  };

  // if (categ.name === 'All' && categ.id === 0 && searchText === '') {
  //   history.push('/all-products')
  // } else {
  //   dispatch(setCurrentCategory({ name: categ.name, id: categ.id, description: categ.description }));
  //   history.push(
  //     `/product-list?category_id=${categ.id}&page=1&search=${encodeURI(searchText.trim().toLowerCase())}&filters=`
  //   );
  // }

  const handleShopBtn = (e, categories) => {
    let searchText = "";
    if (
      categories?.name === "All" &&
      categories.id === 0 &&
      searchText === ""
    ) {
      history.push("/all-products");
    } else {
      dispatch(
        setCurrentCategory({
          name: categories.name,
          id: categories.id,
          description: categories.description,
        })
      );

      if (e.ctrlKey) {
        window.open(
          `/product-list?category_id=${categories.id}&search=${encodeURI(
            searchText.trim().toLowerCase()
          )}&page=1&filters=`,
          "_blank"
        );
      } else {
        history.push(
          `/product-list?category_id=${categories.id}&search=${encodeURI(
            searchText.trim().toLowerCase()
          )}&page=1&filters=`
        );
      }
    }
  };

  return (
    <div className="container homeAdsContainer">
      <section className="pageContent">
        <div className="sectionAds">
          <Row gutter={[24, 24]}>
            <Row gutter={[24, 24]}>
              <Col xxl={12} xl={12} xs={24}>
                <div className="homeCategoryContainer">
                  <Row align="middle" justify="space-around" gutter={[24, 10]}>
                    {categories?.[0]?.description}
                    <Col
                      xl={{ span: 12, order: 0 }}
                      xxl={{ span: 12, order: 0 }}
                      lg={{ span: 12, order: 0 }}
                      sm={{ span: 12, order: 0 }}
                      md={{ order: 0 }}
                      xs={{ order: 1 }}
                    >
                      {categories?.length && categories[0] ? (
                        <>
                          <h4 className="adsHeading">
                            {categories[0].name.trim()}
                          </h4>
                          <p className="featured-categ-desc">
                          </p>
                          {/* <Link
                              type="button"
                              className="btn brandBtn"
                              to={`/product-list?category_id=${categories[0].id}&page=1&search=&filters=`}
                            >
                            
                              Shop Now
                            </Link> */}
                          <button
                            className="btn brandBtn"
                            onClick={(e) => handleShopBtn(e, categories[0])}
                          >
                            Shop Now
                          </button>
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </Col>
                    <Col xl={10} xxl={10} lg={12} sm={12} xs={18}>
                      {categories?.length && categories[0]?.image ? (
                        <img
                          src={categories[0].image}
                          alt=""
                          className="adsImage"
                        />
                      ) : (
                        <img
                          src="images/dummy-image-square.jpg"
                          alt="dummy-img"
                          className="adsImage"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xxl={12} xl={12} xs={24}>
                <div className="homeCategoryContainer">
                  <Row align="middle" justify="space-around" gutter={[24, 10]}>
                    <Col
                      xl={{ span: 12, order: 0 }}
                      xxl={{ span: 12, order: 0 }}
                      lg={{ span: 12, order: 0 }}
                      sm={{ span: 12, order: 0 }}
                      md={{ order: 0 }}
                      xs={{ order: 1 }}
                    >
                      {categories?.length && categories[1] ? (
                        <>
                          <h4 className="adsHeading">
                            {categories[1].name.trim()}
                          </h4>
                          <p className="featured-categ-desc">
                            {categories[1].description}
                          </p>
                          {/* <Link
                            type="button"
                            className="btn brandBtn"
                            to={`/product-list?category_id=${categories[1].id}&page=1&search=&filters=`}
                          >
                            Shop Now
                          </Link> */}
                          <button
                            className="btn brandBtn"
                            onClick={(e) => handleShopBtn(e, categories[1])}
                          >
                            Shop Now
                          </button>
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </Col>
                    <Col xl={10} xxl={10} lg={12} sm={12} xs={18}>
                      {categories?.length && categories[1].image ? (
                        <img
                          src={categories[1].image}
                          alt=""
                          className="adsImage"
                        />
                      ) : (
                        <img
                          src="images/dummy-image-square.jpg"
                          alt="dummy-img"
                          className="adsImage"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xxl={8} xl={8} xs={24}>
                <div className="homeCategoryContainer">
                  <Row align="middle" justify="space-around" gutter={[24, 10]}>
                    <Col
                      xl={{ span: 12, order: 0 }}
                      xxl={{ span: 12, order: 0 }}
                      lg={{ span: 12, order: 0 }}
                      sm={{ span: 12, order: 0 }}
                      md={{ order: 0 }}
                      xs={{ order: 1 }}
                    >
                      {categories?.length && categories[2] ? (
                        <>
                          <h4 className="adsHeading">
                            {categories[2]?.name.trim()}
                          </h4>
                          <p className="featured-categ-desc">
                            {categories[2]?.description}
                          </p>
                          {/* <Link
                            type="button"
                            className="btn brandBtn"
                            to={`/product-list?category_id=${categories[2].id}&page=1&search=&filters=`}
                          >
                            Shop Now
                          </Link> */}
                          <button
                            className="btn brandBtn"
                            onClick={(e) => handleShopBtn(e, categories[2])}
                          >
                            Shop Now
                          </button>
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </Col>
                    <Col xl={10} xxl={10} lg={12} sm={12} xs={18}>
                      {categories?.length && categories[2]?.image ? (
                        <img
                          src={categories[2]?.image}
                          alt=""
                          className="adsImage"
                        />
                      ) : (
                        <img
                          src="images/dummy-image-square.jpg"
                          alt="dummy-img"
                          className="adsImage"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xxl={8} xl={8} xs={24}>
                <div className="homeCategoryContainer">
                  <Row align="middle" justify="space-around" gutter={[24, 10]}>
                    <Col
                      xl={{ span: 12, order: 0 }}
                      xxl={{ span: 12, order: 0 }}
                      lg={{ span: 12, order: 0 }}
                      sm={{ span: 12, order: 0 }}
                      md={{ order: 0 }}
                      xs={{ order: 1 }}
                    >
                      {categories?.length && categories[4] ? (
                        <>
                          <h4 className="adsHeading">
                            {categories[4].name.trim()}
                          </h4>
                          <p className="featured-categ-desc">
                            {categories[4].description}
                          </p>
                          {/* <Link
                            type="button"
                            className="btn brandBtn"
                            to={`/product-list?category_id=${categories[4].id}&page=1&search=&filters=`}
                          >
                            Shop Now
                          </Link> */}
                          <button
                            className="btn brandBtn"
                            onClick={(e) => handleShopBtn(e, categories[4])}
                          >
                            Shop Now
                          </button>
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </Col>
                    <Col xl={10} xxl={10} lg={12} sm={12} xs={18}>
                      {categories?.length && categories[4]?.image ? (
                        <img
                          src={categories[4]?.image}
                          alt=""
                          className="adsImage"
                        />
                      ) : (
                        <img
                          src="images/dummy-image-square.jpg"
                          alt="dummy-img"
                          className="adsImage"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xxl={8} xl={8} xs={24}>
                <div className="homeCategoryContainer">
                  <Row align="middle" justify="space-around" gutter={[24, 10]}>
                    <Col
                      xl={{ span: 12, order: 0 }}
                      xxl={{ span: 12, order: 0 }}
                      lg={{ span: 12, order: 0 }}
                      sm={{ span: 12, order: 0 }}
                      md={{ order: 0 }}
                      xs={{ order: 1 }}
                    >
                      {categories?.length && categories[5] ? (
                        <>
                          <h4 className="adsHeading">
                            {categories[5]?.name?.trim()}
                          </h4>
                          <p className="featured-categ-desc">
                            {categories[5]?.description}
                          </p>
                          {/* <Link
                            type="button"
                            className="btn brandBtn"
                            to={`/product-list?category_id=${categories[5].id}&page=1&search=&filters=`}
                          >
                            Shop Now
                          </Link> */}
                          <button
                            className="btn brandBtn"
                            onClick={(e) => handleShopBtn(e, categories[5])}
                          >
                            Shop Now
                          </button>
                        </>
                      ) : (
                        <Skeleton active />
                      )}
                    </Col>
                    <Col xl={10} xxl={10} lg={12} sm={12} xs={18}>
                      {categories?.length && categories[5]?.image ? (
                        <img
                          src={categories[5].image}
                          alt=""
                          className="adsImage"
                        />
                      ) : (
                        <img
                          src="images/dummy-image-square.jpg"
                          alt="dummy-img"
                          className="adsImage"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row
              className="deal-of-the-day grid-item-deal"
              style={{
                display:
                  remainingTime.seconds <= 0 && remainingTime.minutes <= 0
                    ? "none"
                    : "",
              }}
            >
              <Col xs={24} sm={24} xl={12}>
                <Row>
                  <Col span={24}>
                    <Title level={3}>Deal of the week</Title>
                  </Col>
                  <Col span={24} className="dealInfo">
                    <Row className="dealPrice" justify="space-between">
                      <Col span={24}>
                        <Title level={4}>
                          {dealProductState?.dealResp[0]?.title}
                        </Title>
                      </Col>
                      <Col xs={24} className="oldPrice">
                        &#163;
                        {convertToPriceVal(
                          dealProductState?.dealResp[0]?.actual_price
                        )}
                      </Col>
                      <Col xs={24} className="newPrice">
                        &#163;
                        {convertToPriceVal(
                          dealProductState?.dealResp[0]?.discounted_price
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="deal-description">
                        {dealProductState?.dealResp[0]?.description}
                      </Col>
                    </Row>
                    <Row>
                      <a
                        type="button"
                        className="btn brandBtn"
                        href={`${dealProductState?.dealResp[0]?.product_page_url}`}
                      >
                        Shop Now
                      </a>
                    </Row>
                    <Row className="dealRow">
                      <Col span={24}>
                        <Row className="dealTiming" gutter={[3, 3]}>
                          <Col xs={11} sm={4} lg={4} className="timeBlock">
                            <Row>
                              <Col span={24}>
                                <span className="timingHead">
                                  {remainingTime.total}
                                </span>
                              </Col>
                              <Col span={24}>
                                <span>Days</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={{ span: 11, offset: 1 }}
                            sm={{ span: 4, offset: 1 }}
                            lg={{ span: 4, offset: 1 }}
                            className="timeBlock"
                          >
                            <Row>
                              <Col span={24}>
                                <span className="timingHead">
                                  {remainingTime.hours}
                                </span>
                              </Col>
                              <Col span={24}>
                                <span>Hours</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={11}
                            sm={{ span: 4, offset: 1 }}
                            lg={{ span: 4, offset: 1 }}
                            className="timeBlock"
                          >
                            <Row>
                              <Col span={24}>
                                <span className="timingHead">
                                  {remainingTime.minutes}
                                </span>
                              </Col>
                              <Col span={24}>
                                <span>Minutes</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={{ span: 11, offset: 1 }}
                            sm={{ span: 4, offset: 1 }}
                            lg={{ span: 4, offset: 1 }}
                            className="timeBlock"
                          >
                            <Row>
                              <Col span={24}>
                                <span className="timingHead">
                                  {remainingTime.seconds}
                                </span>
                              </Col>
                              <Col span={24}>
                                <span>Second</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} xl={12} style={{ margin: "auto" }}>
                <Row justify="center" align="middle">
                  <div>
                    <img
                      src={dealProductState?.dealResp[0]?.product_image_url}
                      alt=""
                      className="adsImage"
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default HomeAds;

import React from "react";
import PropTypes from 'prop-types';
import { Row, Col, Typography, Button, Space, Divider } from "antd";
import "./emptyCart.css";

const { Title } = Typography;

const EmptyCart = ({ userLoggedIn, func }) => {
  return (
    <div className="empty-cart-container container">
      <div className="card ptb-10">
        <Row justify="space-around" align="middle">
          <Col xs={18} sm={14} md={12} lg={10} xl={6} xxl={6}>
            <img
              src="/images/cart-animation-02.png"
              alt="cart-animation"
              style={{ transform: "scaleX(-1)", padding: "20px" }}
            />
          </Col>
          <Col xxl={18}>
            <Row className="main-row">
              <Col span={24}>
                <Title level={3} className="hero-text">Your Eagle Work Solutions Basket is empty</Title>
              </Col>
              {!userLoggedIn && (
                <Col>
                  <Space split={<Divider type="vertical" />}>
                    <Button type="primary" onClick={() => func("login", true)}>
                      Sign in
                    </Button>
                    <Button
                      className="ant-btn-secondary"
                      onClick={() => func("register", true)}
                    >
                      Sign up
                    </Button>
                  </Space>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

EmptyCart.propTypes = {
  func: PropTypes.func,
  userLoggedIn: PropTypes.bool
};

export default EmptyCart;

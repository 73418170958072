export const SET_USER_DETAILS_REQ = "SET_USER_DETAILS_REQ";
export const SET_USER_DETAILS_SUCCESS = "SET_USER_DETAILS_SUCCESS";
export const SET_USER_DETAILS_ERROR = "SET_USER_DETAILS_ERROR";

export const CREATE_USER_REQ = "CREATE_USER_REQ";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const RESET_TO_INTIAL_STATE = "RESET_TO_INTIAL_STATE";

export const SET_UPDATED_USER_DETAILS = "SET_UPDATED_USER_DETAILS";

export const LOGOUT_REQ = "LOGOUT_REQ";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const VERIFY_TOKEN_REQ = "VERIFY_TOKEN_REQ";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";
import {
    SET_MANAGE_PROFILE_REQ,
    SET_MANAGE_PROFILE_SUCCESS,
    SET_MANAGE_PROFILE_ERROR,
    UPDATE_MANAGE_PROFILE_REQ,
    UPDATE_MANAGE_PROFILE_SUCCESS,
    UPDATE_MANAGE_PROFILE_ERROR,
    RESET_UPDATE_PROFILE
} from "../../Constants/ManageProfile";

const initialState = {
    manageProfileData: {},
    manageProfileLoading: false,
    updateProfileResp: {}
}

const manageProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MANAGE_PROFILE_REQ:
            return {
                ...state,
                manageProfileLoading: true,
                manageProfileData: {},
            };
        case SET_MANAGE_PROFILE_SUCCESS:
            return {
                ...state,
                manageProfileData: action.payload,
                manageProfileLoading: false,
            };
        case SET_MANAGE_PROFILE_ERROR:
            return {
                ...state,
                manageProfileLoading: false,
                manageProfileData: {},
            };
        case UPDATE_MANAGE_PROFILE_REQ:
            return {
                ...state,
                manageProfileData: {},
                manageProfileLoading: true,
                updateProfileResp: {}
            };
        case UPDATE_MANAGE_PROFILE_SUCCESS:
            return {
                ...state,
                manageProfileData: {},
                manageProfileLoading: false,
                updateProfileResp: action.payload
            };
        case UPDATE_MANAGE_PROFILE_ERROR:
        case RESET_UPDATE_PROFILE:
            return {
                ...state,
                manageProfileData: state.updateProfileResp,
                manageProfileLoading: false,
                updateProfileResp: {}
            }
        default:
            return state;
    }
};

export default manageProfileReducer;
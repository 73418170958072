import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { BsX } from "react-icons/bs";
import { Modal } from "react-bootstrap";

// pages
import Login from "../../pages/Login/Login";
import Register from "../../pages/Register/Register";
import Password from "../../pages/Password/Password";
import ForgetPassword from "../../pages/ForgetPassword";
import ManageProfile from "../../pages/ManageProfile/ManageProfile";

//container
import BuyBulk from "../../container/BuyBulk";
import ShippingModal from "../../container/ShippingModal";
import WriteReview from "../../container/WriteReview";
import Gifts from "../../container/Gifts";

const PopupModal = (props) => {
  const { func, pageInfo, productId, productName } = props;
  const { name, activeStatus } = pageInfo;

  const [page, setPage] = useState({
    name: name,
    active: activeStatus
  });

  const handleUpdate = (name, active) => {
    setPage({ name, active });
    func(name, active);
  };

  const modalData = () => {
    const popupData = {
      "login": <Login func={handleUpdate} />,
      "register": <Register func={handleUpdate} />,
      "password": <Password func={handleUpdate} pageName={name} />,
      "buybulk": <BuyBulk func={handleUpdate} prodId={productId} productCode={props.productCode} productName={productName} />,
      "shipping": <ShippingModal />,
      "writeReview": <WriteReview productId={props.productId} />,
      "forgetPassword": <ForgetPassword func={handleUpdate} />,
      "manageProfile": <ManageProfile />,
      "gifts": <Gifts />
    };

    return popupData[name];
  };

  return (
    <Modal
      show={page.active}
      onHide={() => {
        handleUpdate("", false);
      }}
      centered
    >
      {
        <section id="sign-in">
          <div>
            <div style={{ textAlign: "right", padding: "10px 10px 0 0" }}>
              <BsX
                size={18}
                onClick={() => handleUpdate("", false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              className="card form-card"
              style={{
                textAlign: "center",
                padding: "0 30px 30px 30px",
                border: "none"
              }}
            >
              <div className="card-body">
                <Link to="/">
                  {/* <img
                    src="/images/Logo_Color.png"
                    alt=""
                    className="img-fluid"
                  /> */}
                  <img src="/images/new-eagle-logo-black-font.svg" alt="logo-eagle-popup" className="img-fluid" style={{maxWidth: "35%"}} />
                </Link>
              </div>
              {modalData()}
            </div>
          </div>
        </section>
      }
    </Modal>
  );
};

PopupModal.propTypes = {
  pageInfo: PropTypes.object,
  func: PropTypes.func,
  productId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  productName: PropTypes.string,
  productCode: PropTypes.string,
};

export default PopupModal;